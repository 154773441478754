import React from "react";
import { Helmet } from 'react-helmet-async';
import { Link } from "react-router-dom";
import Footer from './Footer'
import Header from './Header'
import CookieConsent from "react-cookie-consent";

//import { useSpring, animated } from 'react-spring'

function Chisiamo(){
  


  

  return (
    <div className=''>
        <Helmet>
            <html lang="it" />
            <title>Studio Ballon - Chi Siamo</title>
            <meta name="description" content="Studio Ballon - Studio di architettura, Gestioni Immobiliari" />
            <meta name="theme-color" content="#E6E6FA" />
            <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1'/>
            <meta property="og:locale" content="it_IT"/>
            <meta property="og:title" content="Studio Ballon - Chi Siamo"/>
            <meta property="og:description" content="Studio Ballon - Montebelluna (TV) - Studio di architettura, Gestioni Immobiliari"/>
            <meta property="og:url" content="https://studioballon.it/chisiamo/"/>
            <meta property="og:site_name" content="studioballon.it"/>
            <meta property="article:modified_time" content="2022-03-21T10:32:09+00:00"/>
            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:label1" content="Tempo di lettura stimato"/>
            <meta name="twitter:data1" content="3 minuti"/>
        </Helmet>
        
        <Header />
        <div className='md:py-48 py-20  bg-cover'  style={{ backgroundImage: `url("interno2.jpg")` }}>
            <h1 className='md:text-8xl text-5xl  text-white ObjectiveBold text-center'>CHI SIAMO</h1>
        </div>
        

        <div className="py-20 ObjectiveRegular text-justify ">
            <div className=" shadow-xl  bg-white rounded-2xl  flex ml-8 md:ml-20">
                <div className="md:w-3/5 p-5 text-lg">
                    <h2 className="ObjectiveBold text-3xl p-5 uppercase text-center">Arch. Marco Ballon <br/><span className="text-2xl">Titolare dello studio ballon</span> </h2>
                    <p className="md:px-5  py-2 ObjectiveBold">Abbiamo sempre creduto in una cultura rigorosa del lavoro e dell'impegno</p>
                    <p className="md:px-5  py-2">Lo Studio Ballon, sotto l’appassionata guida dell’arch. Marco Ballon, si occupa da oltre 40 anni dell'amministrazione di condomini e progettazione di edifici destinati a qualsiasi uso, dal residenziale al commerciale.</p>                   
                    <p className="md:px-5  py-2">E, grazie all’esperienza pluridecennale del suo titolare ti saprà consigliare e seguire in tutte le tue esigenze di amministrazione immobiliare e progettazione architettonica per un <span className='ObjectiveBold'>servizio a 360°.</span></p>
                    <p className="md:px-5  py-2">Questo, unitamente ad un aggiornamento professionale continuo, ci ha permesso nel corso degli anni di divenire<span className='ObjectiveBold'> punto di riferimento</span> nell'amministrazione immobiliare e nei servizi di progettazione architettonica a Montebelluna e provincia di Treviso.</p>
                    <p className="md:px-5  py-2">Nell'ottica di fornire ai nostri clienti un servizio sempre trasparente e performante, lo Studio aderisce all'Albo dell'<span className='ObjectiveBold'>ANACI</span>, Associazione Nazionale Amministratori Condominiali e Immobiliari e, nella persona dell'Arch. Marco Ballon, <span className='ObjectiveBold'>all'Ordine degli Architetti di Treviso.</span></p>
                    <p className="md:px-5  py-2">L’esperienza maturata in questi anni ci ha fatto inoltre capire che le persone hanno bisogno di essere seguite, comprese e rassicurate in merito ad una cosa così importante qual è la casa di proprietà e i beni immobiliari in generale.</p>
                    <p className="md:px-5  py-2">Ecco che quindi lo Studio Ballon fornisce un <span className='ObjectiveBold'> servizio di reperibilità multicanale costante, una presenza territoriale radicata, una piattaforma online con tutte le informazioni tue e del tuo condominio ma soprattutto capacità di ascolto ed umanità da parte di tutto lo staff.</span></p>
                </div>
                <div className="w-2/5 bg-cover border-8 border-white hidden md:block"   style={{ backgroundImage: `url("meeting.jpg")` }}></div>
            </div>
            
        </div>
        

        
        <Footer />
        <CookieConsent
            acceptOnScroll={true}
            acceptOnScrollPercentage={50}
            onAccept={(byScroll) => {
            }}
            location="bottom"
            buttonText="OK"
            cookieName="myAwesomeCookieName2"
            style={{ background: "#2B373B" }}
            buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
            expires={150}
            >
            <span style={{ fontSize: "12px" }}>Questo sito utilizza cookie tecnici, analitici e di profilazione, anche di terze parti, necessari rispettivamente, a consentire alcune funzionalità fondamentali per il sito, ad effettuare misurazioni delle performances del sito stesso o a fornirti indicazioni promozionali in linea con i tuoi interessi.

Per avere maggiori informazioni e scoprire come negare in tutto o in parte i tuoi consensi, consulta la <Link to='/PrivacyCookie' className='underline'>cookie policy</Link>.</span>
        </CookieConsent>
        
        
    </div>
  );
}

export default Chisiamo;