import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
//import { Helmet } from "react-helmet";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import "tailwindcss/tailwind.css";
import './App.css';
import Login from "./Login";
import NotFound from './NotFound';
//import getFirebase from "./firebase";
//import Dashboard from "./Dashboard";
import Reset from "./Reset";
import Home from './Home'
import Contatti from './Contatti'
import Servizi from './Servizi'
import Chisiamo from './Chisiamo'
import Preventivi from './Preventivi'
//import Register from './clienti/Register'
import Cliente from "./clienti/Cliente";
import HomeAmministratore from "./amministratori/Home";
//import Mail from "./amministratori/Mail";
import Condominio from "./amministratori/Condominio";
import GestioneParticella from "./amministratori/GestioneParticella";
import GestioneCliente from "./amministratori/GestioneCliente";
import InvormazioniLegali from './InformazioniLegali'
import PrivacyCookie from './PrivacyCookie'

function App() {
  return (
    <HelmetProvider>
    <div className="app">
      <Helmet>
        <html lang="it" />
        <title>Studio Ballon</title>
        <meta name="description" content="Studio Ballon - Montebelluna (TV) - Studio di architettura ed amministrazione immobiliare" />
        <meta name="theme-color" content="#E6E6FA" />
        <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1'/>
        <meta property="og:locale" content="it_IT"/>
        <meta property="og:title" content="Studio Ballon"/>
        <meta property="og:description" content="Studio Ballon - Montebelluna (TV) - Studio di architettura ed amministrazione immobiliare"/>
        <meta property="og:url" content="https://studioballon.it/"/>
        <meta property="og:site_name" content="studioballon.it"/>
        <meta property="article:modified_time" content="2022-03-21T10:32:09+00:00"/>
        <meta name="twitter:card" content="summary_large_image"/>
        <meta name="twitter:label1" content="Tempo di lettura stimato"/>
        <meta name="twitter:data1" content="3 minuti"/>
      </Helmet>

      <Router>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/reset" component={Reset} />
          <Route exact path="/" component={Home} />
          <Route exact path="/contatti" component={Contatti} />
          <Route exact path="/servizi" component={Servizi} />
          <Route exact path="/chisiamo" component={Chisiamo} />
          <Route exact path="/preventivi" component={Preventivi} />
          <Route exact path="/clienti/cliente" component={Cliente} />
          <Route exact path="/informazionilegali" component={InvormazioniLegali} />
          <Route exact path="/privacycookie" component={PrivacyCookie} />
          {/* <Route exact path="/clienti/register" component={Register} /> */}
          <Route exact path="/amministratori/home" component={HomeAmministratore} />
          {/* <Route exact path="/amministratori/mail" component={Mail} /> */}
          <Route exact path="/amministratori/condominio" component={Condominio} />
          <Route exact path="/amministratori/gestioneparticella" component={GestioneParticella} />
          <Route exact path="/amministratori/gestionecliente" component={GestioneCliente} />
          {/* <Route exact path="/dashboard" component={Dashboard} /> */}
          <Route component={NotFound} />
        </Switch>
      </Router>
    </div>
    </HelmetProvider>
  );
}

export default App;
