//import { useState } from "react";
import getFirebase from "./firebase";
import { doc, getDocs, query, collection, where, updateDoc } from "firebase/firestore";
//import { getStorage  } from "firebase/storage";
import { getFunctions, httpsCallable } from "firebase/functions";

const firebase = getFirebase();
//const storage = getStorage();
const functions = getFunctions();

const CambiaEmail = (oldEmail, newEmail, urlId) => {

    const aggiornaFiles = async (newEmail, idFile) => {
        try {
            const docRef = doc(firebase.db, "filesClienti", idFile);
            await updateDoc(docRef, {
                email: newEmail
            });
        } catch(e) {
            console.log('a'+e)
        }
    }


    const cercaFiles = async (oldEmail, newEmail) => {
        try {
            const getFiles = collection(firebase.db, "filesClienti");
            let q = query(getFiles, where('email', '==', oldEmail))
            let querySnapshot = await getDocs(q);
            if(!querySnapshot.empty) {
                querySnapshot.forEach((doc) => {
                    aggiornaFiles(newEmail, doc.id)
                })
            } 
        } catch(e) {
            console.log('b'+e)
        }
    }

    const aggiornaClientiParticella = async (newEmail, id) => {
        try {
            const docRef = doc(firebase.db, "clientiParticella", id);
            await updateDoc(docRef, {
                email: newEmail
            });
        } catch(e) {
            console.log('a'+e)
        }
    }

    const cercaClientiParticella = async (oldEmail, newEmail) => {
        try {
            const getFiles = collection(firebase.db, "clientiParticella");
            let q = query(getFiles, where('email', '==', oldEmail))
            let querySnapshot = await getDocs(q);
            if(!querySnapshot.empty) {
                querySnapshot.forEach((doc) => {
                    aggiornaClientiParticella(newEmail, doc.id)
                })
            } 
            const updateUser = httpsCallable(functions, 'updateUser');
            updateUser({ 
                "oldEmail": oldEmail,
                "newEmail": newEmail
            })
                .then((result) => {
                console.log(result)
                })
                .catch((e) => {
                console.log('Errore email: '+e)
                });
        } catch(e) {
            console.log('b'+e)
        }
    }

    const cambiaEmail = async (newEmail, urlId) => {
        const docRef = doc(firebase.db, "clienti", urlId);
        await updateDoc(docRef, {
          email: newEmail
        });
      }

    if(oldEmail !== newEmail) {
        cercaFiles(oldEmail, newEmail)
        cambiaEmail(newEmail, urlId)
        cercaClientiParticella(oldEmail, newEmail)
    }

    /* const updateUser = httpsCallable(functions, 'updateUser');
    updateUser({ 
        "oldEmail": oldEmail,
        "newEmail": newEmail
       })
        .then((result) => {
          console.log(result)
        })
        .catch((e) => {
          console.log('Errore email: '+e)
        }); */

  return {
    
  };
};

export default CambiaEmail
