import React from "react";
import {  Link } from "react-router-dom";

const Footer = () => {
    
  

  return (
    <div id='footer' className='w-full border-t border-blueGray-800'>
        <footer className="footer bg-white relative pt-1 text-gray-700">
            <div className="container mx-auto px-6">

                <div className="sm:flex pt-5 ">
                    <span className="font-bold text-gray-700 uppercase ObjectiveBold mx-5 ">studio ballon</span>
                    <div className=" sm:w-full sm:px-8 flex flex-col md:flex-row ">                
                        <div className="flex flex-col mx-5 py-4 md:py-0">
                            <span className='ObjectiveRegular'> Via Augusto Serena, 37</span>
                            <span className='ObjectiveRegular'> 31044 Montebelluna (TV)</span>
                            <span className='ObjectiveRegular'> P.I. 01152820260</span>
                            <span className='ObjectiveRegular'> Tel <a className='underline hover:text-gray-900 hover:font-bold' href="tel:+390423300507">+39 0423 300507</a></span>
                            <span className='ObjectiveRegular'> Fax +39 0423 610699</span>
                            <span className='ObjectiveRegular'> Email <a className='underline hover:text-gray-900 hover:font-bold'  href="mailto:segreteria@studioballon.it ?soggetto=Richiesta%20di%20informazioni">segreteria@studioballon.it </a></span>
                        </div>
                        <div className="flex flex-col mx-5 ">
                            <span className=""><Link to="./informazionilegali" className="underline hover:text-gray-900 hover:font-bold">Informazioni legali</Link></span>
                            <span className=""><Link to="./privacycookie" className="underline hover:text-gray-900 hover:font-bold">Privacy Policy e<br/> Cookie Policy</Link></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mx-auto ">
                <div className="mt-8 border-t-2 border-gray-300 flex flex-col items-center">
                    <div className="sm:w-2/3 text-center py-6">
                    <div>
                        <div className='inline-block align-middle'>
                            <img className='w-10 h-10' src='/logoGradiente.png' alt='logo' />
                        </div>
                        <div className='inline-block ml-2 font-semibold font-mono text-xs align-middle'>
                            <div>make by <div className=" inline-block whitespace-nowrap">ne<div className="flip-horizontal inline-block">e</div>ve<div className="flip-horizontal inline-block ">e</div><div className=" inline-block">.studio</div></div></div>
                            <div className='text-center text-sky-500 underline'><a href='https://neevee.studio' className="underline">neevee.studio</a></div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </footer>
    </div>
  );
};

export default Footer;