import React, { useState, useEffect } from "react";
//import { Helmet } from "react-helmet";
import { Helmet } from 'react-helmet-async';
import SignOutButton from "../SignOutButton";
import EliminaCliente from './EliminaCliente'
import CambiaEmail from '../CambiaEmail'
import Header from '../Header'
import Footer from '../Footer'
//import AddClienteForm from "./AddClienteForm";
//import ListaFiles from "./ListaFiles";
import getFirebase from "../firebase";
//import { getAuth,  } from "firebase/auth";
import { doc, getDoc, getDocs, query, orderBy, addDoc, collection, where, deleteDoc, updateDoc } from "firebase/firestore";
import { useHistory } from "react-router-dom";
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject  } from "firebase/storage";
import { getFunctions, httpsCallable } from "firebase/functions";

function GestioneCliente() {
    let url = new URL(window.location.href);
    let urlId = url.searchParams.get("id");
    const urlIdC = url.searchParams.get("idC");
    const urlIdP = url.searchParams.get("idP");
    let urlAnno = url.searchParams.get("anno");
    let urlParticella = encodeURIComponent(url.searchParams.get("p"));
    let nomeCondominio = url.searchParams.get('nc');
    const d = new Date();
    //let anno = d.getFullYear();

    let modal = document.getElementById("modal");
    const [btnNome, setBtnNome] = useState('');
    const [btnId, setBtnId] = useState('');
    const [btnTitolo, setBtnTitolo] = useState('');
    const [btnTesto, setBtnTesto] = useState('');

    const [nome, setNome] = useState('');
    const [cognome, setCognome] = useState('');
    const [email, setEmail] = useState('');
    const [pec, setPec] = useState('');
    const [tel, setTel] = useState('')
    const [allPresenze, setAllPresenze] = useState([])
    const [allCondomini, setAllCondomini] = useState([])
    let allCondominiPre = []
    /* const [particella, setParticella] = useState('');
    const [tipo, setTipo] = useState('');
    const [tipoP, setTipoP] = useState('');
    const [tipoI, setTipoI] = useState('');
    const [condominio, setCondominio] = useState(''); */

    const history = useHistory();
    const firebase = getFirebase();
    const storage = getStorage();
    const utente = firebase.auth.currentUser;
    const functions = getFunctions();

    const [file, setFile] = useState(null);
    const [nessunRisultato, setNessunRisultato] = useState('');
    const [nessunRisultatoReverse, setNessunRisultatoReverse] = useState('');
    const [annoSuc, setAnnoSuc] = useState('');
    const [annoPrec, setAnnoPrec] = useState('');
    const [allFiles, setAllFiles] = useState([]);
    const [nomeFile, setnomeFile] = useState('Carica un file (max 5MB)');

    const [urlFile, setUrlFile] = useState('')

    const [uno, setUno] = useState('')
    const [due, setDue] = useState('')
    const [tre, setTre] = useState('')



    if(!utente) {
      //history.replace("/");
    } else {
      //setCurrentUser(utente.email)
    }
    const [indietroPC, setIndietoPC] = useState('')
    

   





    const getCliente = async () => {
        try {
          const docRef = doc(firebase.db, "clienti", urlId);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            setNome(docSnap.data().nome);
            setCognome(docSnap.data().cognome);
            setEmail(docSnap.data().email);
            setPec(docSnap.data().pec);
            setTel(docSnap.data().tel)
            listaPresenze(docSnap.data().email)
          }
          else {
            //alert("Nessuna corrispondenza");
            history.replace('/amministratori/home');
          }
        } catch (e) {
          //alert("Errore del database ", e);
          /* addDoc(collection(firebase.db, "errori"), {
            nome: 'Ricerca Cliente',
            timestamp: serverTimestamp(),
            dati: e
          }); */
        }
        //listaFiles(urlAnno);
        window.scrollTo(0, 0)
    }

    const eliminaCliente = () => {
      if(EliminaCliente(email)) {
        alert("Il cliente è stato eliminato!")
        history.goBack()
        //history.replace("/amministratori/condominio?id="+urlIdC+'&anno='+anno+'&r='+urlId)
      } else {
        alert("Impossibile eliminare il cliente! Contattare l'amministratore di sistema")
      }
    } 

    const listaPresenze = async (email) => {
      setAllPresenze([])
      setAllCondomini([])
      allCondominiPre = []
      try {
        const getPresenze = collection(firebase.db, "clientiParticella");
        const q = query(getPresenze, where('email', '==', email));
        let querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          if(allCondominiPre.includes(doc.data().nomeCon)) {
            setAllPresenze(oldArray => [...oldArray, {nomeCon: doc.data().nomeCon, nomePart: doc.data().nomePart, idC: doc.data().condominio, idP: doc.data().particella, tipo: doc.data().tipo, id: doc.id}]);
          } else {
            //setAllCondomini(oldArray => [...oldArray, doc.data().nomeCon])
            allCondominiPre.push(doc.data().nomeCon)
            setAllPresenze(oldArray => [...oldArray, {nomeCon: doc.data().nomeCon, nomePart: doc.data().nomePart, idC: doc.data().condominio, idP: doc.data().particella, tipo: doc.data().tipo, id: doc.id}]);
          }
        });
        allCondominiPre.forEach((doc) => {
          setAllCondomini(oldArray => [...oldArray, doc])
        })
      } catch(e) {
        /* addDoc(collection(firebase.db, "errori"), {
          nome: 'Lista Presenze',
          timestamp: serverTimestamp(),
          dati: e
        }); */
        //console.log(e)
        //alert("Errore del database: "+e);
      }
    }

    const Capitalize = (prop) => {
      let str = prop.toLowerCase();
      let arr = str.split(" ");
      for (var i = 0; i < arr.length; i++) {
          arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }
      return (arr.join(" ")).trim();
    }

    const cambiaTipo = async (id, e) => {
      e.preventDefault()
      if(e.target[1].value !== '') {
        const docRef = doc(firebase.db, "clientiParticella", id);
        await updateDoc(docRef, {
          tipo: Capitalize(e.target[1].value)
        });
      }
      
      listaPresenze(email)
      
    }

    const deleteClienteParticella = async (id) => {
      try {
          await deleteDoc(doc(firebase.db, "clientiParticella", id));
          //alert('Cliente eliminato')
          //return  true 
          if(allPresenze.length === 1) {
            const getPresenze = collection(firebase.db, "clientiParticella");
            const q = query(getPresenze, where('email', '==', email));
            let querySnapshot = await getDocs(q);
            if(querySnapshot.empty) {
              EliminaCliente(email)
              history.goBack()
            }
          }
      } catch (e) {
        /* addDoc(collection(firebase.db, "errori"), {
          nome: 'Eliminazione Cliente Particella',
          timestamp: serverTimestamp(),
          dati: e
        }); */
          //return false
      } 
      listaPresenze(email)
      modal.style.display = "none";
  }
    

    /* const getSelection = () => {
      if(tipo === 'Proprietario') {
        return(
          <select id='tipo' className="w-72 mx-auto h-8 px-6 text-sm text-indigo-100 transition-colors duration-150 bg-indigo-700 rounded-lg focus:shadow-outline hover:bg-indigo-800">
            <option id='proprietario' value='proprietario'>Proprietario</option>
            <option value={email}>Personale</option>
          </select>
        )
      }else if(tipo === 'Inquilino') {
        return(
          <select id='tipo' className="w-72 mx-auto h-8 px-6 text-sm text-indigo-100 transition-colors duration-150 bg-indigo-700 rounded-lg focus:shadow-outline hover:bg-indigo-800">
            <option id='inquilino' value='inquilino'>Inquilino</option>
            <option value={email}>Personale</option>
          </select>
        )
      } else {
        return(
          <select id='tipo' className="w-72 mx-auto h-8 px-6 text-sm text-indigo-100 transition-colors duration-150 bg-indigo-700 rounded-lg focus:shadow-outline hover:bg-indigo-800">
            <option id='proprietario' value='proprietario'>Proprietario</option>
            <option id='inquilino' value='inquilino'>Inquilino</option>
            <option value={email}>Personale</option>
          </select>
        )
      }
    } */


    const showModal = (tipo, nome, id) => {
      if(tipo === 'file') {
        document.getElementById("btnFile").style.display = "block";
        document.getElementById("btnCliente").style.display = "none";
        document.getElementById("btnParticella").style.display = "none";
        setBtnNome(nome);
        setBtnId(id);
        setBtnTitolo('Eliminazione file');
        setBtnTesto('Sei sicuro di eliminare il file? Una volta eliminato il file non potrà essere recuperato!')
      } else if(tipo === 'cliente') {
        document.getElementById("btnFile").style.display = "none";
        document.getElementById("btnCliente").style.display = "block";
        document.getElementById("btnParticella").style.display = "none";
        setBtnTitolo('Eliminazione cliente');
        setBtnTesto('Sei sicuro di eliminare il cliente? Una volta eliminato, tutti i dati e i file del cliente non potranno più essere recuperati!')
      } else if(tipo === 'particella') {
        document.getElementById("btnFile").style.display = "none";
        document.getElementById("btnParticella").style.display = "block";
        document.getElementById("btnCliente").style.display = "none";
        setBtnId(id);
        setBtnTitolo('Eliminazione cliente dalla particella');
        setBtnTesto('Sei sicuro di eliminare il cliente dalla particella? Una volta eliminato, tutti i dati e i file del cliente per questa particella non potranno più essere recuperati!')
      }
      modal.style.display = "block";
    }




    const listaFiles = async (prop) => {
      try {
        if(prop >= (new Date().getFullYear())) {
          setAnnoSuc('hidden');
          setAnnoPrec('');
        }
        if(prop !== urlAnno) {
          history.replace('/amministratori/gestionecliente?id='+urlId+'&anno='+prop+'&nc='+nomeCondominio+'&idc='+urlIdC+'&p='+urlParticella);
        }
        setAllFiles([]);
        
       
        let inizioAnno = new Date( prop, 1 - 1, 1);
        let fineAnno = new Date( prop, 12 - 1, 31);
        const getFiles = collection(firebase.db, "filesClienti");
        //alert(email)
        let q = query(getFiles, where('email', '==', email), where('data', '>=', inizioAnno.getTime()), where('data', '<=', fineAnno.getTime()), orderBy("data", "desc"))
        
        let querySnapshot = await getDocs(q);
        
        if(!querySnapshot.empty) {
          setNessunRisultato('hidden');
          setNessunRisultatoReverse('')
        } else {
          setNessunRisultato('');
        } 
        if(querySnapshot.empty && prop === (new Date().getFullYear())) {
          setNessunRisultatoReverse('hidden')
        }
        if(querySnapshot.empty && prop < (new Date().getFullYear())) {
          setAnnoPrec('hidden');
          setAnnoSuc('');
        }
        if(!querySnapshot.empty && prop < (new Date().getFullYear())) {
          setAnnoSuc('');
          setAnnoPrec('');
        }
        querySnapshot.forEach((doc) => {
          if(doc.data().data === undefined) {alert('Nessun file')}
          
          let dat = new Date(doc.data().data);
          const options = { year: 'numeric', month: 'long', day: 'numeric' };
          //alert(doc.data().idCartella)
          
          getDownloadURL(ref(storage, 'clienti/'+doc.data().idCartella+'/'+doc.data().file))
            .then((url) => {
              setUrlFile(url)
              setUrlFile((state) => {
                setAllFiles(oldArray => [...oldArray, {url: state, id: doc.id, nome: doc.data().file, data: dat.toLocaleDateString('it-IT', options)}]);
              });
            }).catch((e) => {
              /* addDoc(collection(firebase.db, "errori"), {
                nome: 'Lista Files',
                timestamp: serverTimestamp(),
                dati: e
              }); */
            });
          
          //setAllFiles(oldArray => [...oldArray, {id: doc.id, nome: doc.data().file, tipo: doc.data().tipo, data: dat.toLocaleDateString('it-IT', options)}]);
          
        });
      } catch (e) {
        //alert("Errore del database: "+e);
        /* addDoc(collection(firebase.db, "errori"), {
          nome: 'Lista Files',
          timestamp: serverTimestamp(),
          dati: e
        }); */
      }
    };
    
    
    /* function openFile(nome) {
      getDownloadURL(ref(storage, 'clienti/'+encodeURIComponent(urlIdC+particella)+'/'+nome))
        .then((url) => {
          
          const xhr = new XMLHttpRequest();
          xhr.responseType = 'blob';
          xhr.onload = (event) => {
            const blob = xhr.response;
          };
          xhr.open('GET', url);
          xhr.send();
          window.open(url);
        }) 
        .catch((error) => {
          alert('Impossibile aprire il file!')
        });     
    } */
    
    const eliminaFile = async (nome,id) => {
      try {
        const deleteFile = ref(storage, 'clienti/'+encodeURIComponent(email)+'/'+nome);
        await deleteDoc(doc(firebase.db, "filesClienti", id));
        deleteObject(deleteFile).then(() => {
          // File deleted successfully
        }).catch((e) => {
          //alert("Errore del database: "+e);
        });
      } catch (e) {
        //alert("Errore del database: "+e);
        /* addDoc(collection(firebase.db, "errori"), {
          nome: 'Elimina File',
          timestamp: serverTimestamp(),
          dati: e
        }); */
      }
      setAllFiles([]);
      listaFiles(urlAnno);
      //listaClienti();
      modal.style.display = "none";
    };

    const onFileChange = (e) => {
      const reader = new FileReader();
      let file = e.target.files[0]; 
      if (file && file.size < 5000000) {
        reader.onload = () => {
          if (reader.readyState === 2) {
            setFile(file);
            setnomeFile(file.name);
          }
        };
        reader.readAsDataURL(e.target.files[0]);
      
      } else if(file && file.size >= 5000000) {
        alert('Il file è troppo grande!');
        setFile(null);
        setnomeFile('Carica un file (max 5MB)');
      } else {
        setFile(null);
        setnomeFile('Carica un file (max 5MB)');
      }
    };

    const uploadToFirebase = () => {  
      if (file) {
        let nomed = String(Date.now())+file.name
        const fileRef = ref(storage, 'clienti/'+encodeURIComponent(email)+'/'+nomed);
        uploadBytes(fileRef, file).then((snapshot) => {
          const addLinkFile = async () => {
            const docRef = await addDoc(collection(firebase.db, "filesClienti"), {
              email: email,
              idCartella: encodeURIComponent(email),
              file: nomed,
              tipo: 'personale',
              data: Date.now()
            });
          };
          addLinkFile(); 
          alert('Il file è stato caricato!');
          setFile(null);
          setnomeFile('Carica un file (max 5MB)');
          setAllFiles([]);
          listaFiles(urlAnno);
          invioMail(email, nomed, nome, cognome)
          
        });
      } else {
        alert("Devi prima selezionare un file");
      }
    };

    const invioMail = (email, file, nome, cognome) => {
      const sendEmail = httpsCallable(functions, 'sendEmail');
      sendEmail({ 
        "email": email,
        "subject": "Nuovo documento personale",
        "message": "Buongiorno gentile "+nome+' '+cognome+', \n \n'+
        "La informiamo, che all'interno della sua area riservata, è stato caricato un nuovo file personale ("+file.slice(13)+')'+
        '\n \n Per prenderne visione clicchi il seguente link \n'+
        'https://studioballon.it'+
        '\n \n \n Grazie e cordiali saluti \n \n '+
        'Studio Ballon\n'+
        'Via Augusto Serena, 37 - 31044 Montebelluna (TV) - Italy\n'+
        'Tel. +39 0423 300507\n'+
        'Fax +39 0423 610699\n'+
        'E-mail segreteria@studioballon.it\n'+
        'studioballon.it\n'+
        'Ai sensi del D.Lgs. 196/2003 si avvisa che la presente comunicazione e gli eventuali documenti ad essa allegati contengono informazioni riservate e ad uso esclusivo del destinatario sopra indicato. Qualora non fossero a Lei destinati, La preghiamo di tenere presente che la divulgazione, distribuzione o riproduzione di qualunque informazione in essi contenuta sono vietate. Se ha ricevuto la presente comunicazione per errore, La preghiamo di volerci avvertire immediatamente e di distruggere quanto ricevuto. Grazie.\n'+
        'This message including any attachments contains confidential information intended for a specific individual and purpose, and is protected by law. If you are not the intended recipient, you are hereby notified that any disclosure, copying, or distribution of this message is strictly prohibited, and are kindly requested to return the e-mail to sender and delete this message from your computer. Many thanks for your kind co-operation.'
       })
        .then((result) => {
          //console.log(result)
        })
        .catch((e) => {
          //alert('Errore email: '+e)
          /* addDoc(collection(firebase.db, "errori"), {
            nome: 'Invio Email',
            timestamp: serverTimestamp(),
            dati: e
          }); */
        });
    }


    const cambioSelect = (e) => {
      switch (e.target.value) {
        case '1':
          setUno('bg-indigo-500 text-white')
          setDue('bg-gray-50 text-black')
          setTre('bg-gray-50 text-black')
          document.getElementById('base').style.display='none'
          document.getElementById('cambiaEmail').style.display='flex'
          document.getElementById('cambiaPec').style.display='none'
          document.getElementById('cambiaTel').style.display='none'
          break;
        case '2':
          setDue('bg-indigo-500 text-white')
          setUno('bg-gray-50 text-black')
          setTre('bg-gray-50 text-black')
          document.getElementById('base').style.display='none'
          document.getElementById('cambiaEmail').style.display='none'
          document.getElementById('cambiaPec').style.display='flex'
          document.getElementById('cambiaTel').style.display='none'
          break;
        case '3':
          setTre('bg-indigo-500 text-white')
          setDue('bg-gray-50 text-black')
          setUno('bg-gray-50 text-black')
          document.getElementById('base').style.display='none'
          document.getElementById('cambiaEmail').style.display='none'
          document.getElementById('cambiaPec').style.display='none'
          document.getElementById('cambiaTel').style.display='flex'
          break;
      }
    }

    const cambiaPec = async (e) => {
      e.preventDefault()
      const docRef = doc(firebase.db, "clienti", urlId);
      await updateDoc(docRef, {
        pec: e.target[0].value
      });
      document.getElementById("cambiaPec").reset();
      getCliente()
    }

    const cambiaTel = async (e) => {
      e.preventDefault()
      const docRef = doc(firebase.db, "clienti", urlId);
      await updateDoc(docRef, {
        tel: e.target[0].value
      });
      document.getElementById("cambiaTel").reset();
      getCliente()
    }

    const cambiaEmail = (e) => {
      e.preventDefault()
      if(e.target[0].value && e.target[0].value !== '') {
        CambiaEmail(email, e.target[0].value, urlId)
        document.getElementById("cambiaEmail").reset();
        history.goBack()
      }
      //setEmail(e.target[0].value)
      //listaFiles(email)
      //getCliente()
    }

    function indietro() {
      if(urlIdP) {
        history.replace({
          pathname: '/amministratori/gestioneparticella',
          search: '?idc='+urlIdC+'&id='+urlIdP+'&anno='+new Date().getFullYear()
        })
      } else if(urlIdC) {
        history.replace({
          pathname: '/amministratori/condominio',
          search: '?id='+urlIdC+'&anno='+new Date().getFullYear()
        })
      } else {
        history.replace('/amministratori/home')
      }
    }



    /* useEffect(() => {
        getCliente();
    }, []) */
    useEffect(() => {
      if (urlId) {
        getCliente();     
      } else {
        history.replace('/');
      }   
    }, []); 
    useEffect(() => {
      listaFiles(urlAnno);
    }, [email]) 
    useEffect(() => {
      if(urlIdP) {
        setIndietoPC('Gestione Particella')
      } else if(urlIdC) {
        setIndietoPC('Gestione Condominio')
      }
    }, [])
    
    


    return(
      <div>
        <Helmet>
          <title>Studio Ballon - Gestione Cliente</title>
        </Helmet>
        <Header />
        <div className='md:w-2/3 p-5 mx-auto text-indigo-500 font-semibold'>
          <span className='cursor-pointer underline' onClick={() => history.push("/amministratori/home")}>Home Amministrazione</span> / <span className='cursor-pointer underline' onClick={() => history.goBack()}>{indietroPC}</span> / Gestione Cliente: {nome} {cognome}
        </div>
        <div className=" p-5 w-full md:w-2/3 mx-auto mt-5 mb-14 shadow-2xl bg-white rounded-xl">
            <h2 className='font-bold mb-5 text-2xl'>Gestione cliente </h2>
            <div>

              {/* Intestazione cliente */}
              <div className='p-5 border-l-2 border-indigo-500 bg-gray-100'>
                <div className="md:flex md:justify-between items-center">
                  <div className='mb-3 md:mb-0'>
                    <h1 className='text-xl text-indigo-500'>{nome} {cognome}    </h1>
                    <p className='font-semibold'><a href={'mailto:'+email}>Email: {email}</a>  <span className='hidden md:inline'>-</span>  <span className='block md:inline'>PEC: {pec}</span></p>
                    <p className='font-semibold'>Tel: {tel} </p>
                  </div>
                  <button  onClick={(e) => {showModal('cliente', '', '')}} type='button'  className="mt-2 md:px-6 w-full justify-center rounded-md border border-transparent shadow-sm h-10 px-5 text-lg font-semibold bg-red-600  text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm block">Elimina Cliente</button>  
                </div>
              </div>

              
              <div className="tab w-full overflow-hidden mt-2">
                <input  className="absolute opacity-0" id="tab-single-one" type="checkbox" name="tabs2" />
                <label className="tabModifica block p-5 leading-normal cursor-pointer " htmlFor="tab-single-one">Modifica Cliente</label>
                <div className="tab-content overflow-hidden border-l-2 bg-gray-100 border-indigo-500 leading-normal">
                  
                  
                  <div className=' p-2 '>
                    <div className='md:grid md:grid-cols-3  font-semibold ' onChange={(e) => {cambioSelect(e); }}>
                      <label className={uno+"  align-middle items-center block px-5 py-3 border border-indigo-500"}>
                        <input type="radio" className="form-radio" name="radio" value="1"  />
                        <span className="ml-2">Email</span>
                      </label>
                      <label className={due+" align-middle items-center block px-5  py-3 md:border-b md:border-t border-b-0 border-t-0 border-l border-r md:border-l-0 md:border-r-0  border-indigo-500"}>
                        <input type="radio" className="form-radio" name="radio" value="2"  />
                        <span className="ml-2">PEC</span>
                      </label>
                      <label className={tre+" align-middle items-center block px-5 py-3  border border-indigo-500"}>
                        <input type="radio" className="form-radio" name="radio" value="3"  />
                        <span className="ml-2">Telefono</span>
                      </label>
                    </div>
                    <div className=' border-r border-l border-b border-indigo-500 '>
                      <div id='base' className=' py-6 text-center h-full font-semibold text-xl'><span>Seleziona un'opzione</span></div>
                      <form id='cambiaEmail' className='flex hidden pt-4 pb-6 ' onSubmit={(e) => {cambiaEmail(e); }}>
                        <div className='mx-auto'>
                          <input className='block md:inline-block h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline' type='email' defaultValue={email} />
                          <button className='block md:inline-block h-10 px-5 mx-auto md:ml-5 mt-4 text-indigo-100 transition-colors duration-150 bg-indigo-700 rounded-lg focus:shadow-outline hover:bg-indigo-800'>Aggiorna Email</button>
                        </div>
                      </form>
                      <form id='cambiaPec' className='flex  hidden pt-4 pb-6 ' onSubmit={(e) => {cambiaPec(e); }}>
                        <div className='mx-auto'>
                          <input className='block md:inline-block h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline' type='email' defaultValue={pec} />
                          <button className='block md:inline-block h-10 px-5 mx-auto md:ml-5 mt-4 text-indigo-100 transition-colors duration-150 bg-indigo-700 rounded-lg focus:shadow-outline hover:bg-indigo-800'>Aggiorna PEC</button>
                        </div>
                      </form>
                      <form id='cambiaTel' className='flex hidden pt-4 pb-6 ' onSubmit={(e) => {cambiaTel(e); }}>
                        <div className='mx-auto'>
                          <input className='block md:inline-block h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline' type='tel' defaultValue={tel} />
                          <button className='block md:inline-block h-10 px-5 mx-auto md:ml-5 mt-4 text-indigo-100 transition-colors duration-150 bg-indigo-700 rounded-lg focus:shadow-outline hover:bg-indigo-800'>Aggiorna Telefono</button>
                        </div>
                      </form>
                    </div>
                  </div>
                  
                </div>
              </div>


              <div className='p-5 border-indigo-500 border mt-5'>

                {/* Gestione Presenze */}
                <h1 className='text-lg font-semibold mb-5'>{nome} {cognome} è presente in:</h1>

                {/* Elenco Presenze */}
                <div>
                  {
                    allCondomini.map((doc, i) => {
                      return (
                        <div key={i} className='font-bold '>
                          Condominio: {doc}
                          {
                            allPresenze.map((item, i) => {
                              if(doc === item.nomeCon) {
                                return (
                                  <div key={i} className='md:flex justify-between px-5  m-1 border border-indigo-500  text-gray-900 font-semibold transition-colors duration-150 bg-gray-100 rounded-lg focus:shadow-outline hover:bg-indigo-100'>
                                    <div id={item.id}  onClick={() => history.push({
                                              pathname: '/amministratori/gestioneparticella',
                                              search: '?idc='+item.idC+'&id='+item.idP+'&anno='+new Date().getFullYear()
                                            })} className='  cursor-pointer my-auto'>
                                      <div className=' font-medium '><span className='font-semibold'>P.Catastale:</span> {item.nomePart}</div>
                                      <div className=' font-medium'><span className='font-semibold'>Tipo:</span> {item.tipo}</div>
                                    </div>
                                    <div className='my-auto'>
                                      <div className='p-2 cursor-pointer font-bold text-red-600' onClick={(e) => {showModal('particella', '', item.id)}}>Elimina dalla particella</div>
                                      <div className=' border-indigo-500 border-t p-2'>
                                        <form className='inline-block' onSubmit={(e) => {cambiaTipo(item.id, e)}}>                                 
                                            <button type='submit' className='my-auto cursor-pointer font-bold text-yellow-600 mr-2'>Modifica tipo</button>
                                            <select className={"   px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline"} >
                                                <option value=''>- seleziona -</option>
                                                <option value='proprietario'>Proprietario</option>
                                                <option value='inquilino'>Inquilino</option>
                                                <option value='entrambi'>Entrambi</option>
                                            </select>
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                )
                              }
                            })
                          }
                        </div>
                      )
                    })
                  }
                </div>
              </div>


              <div className='p-5 border-indigo-500 border mt-5'>

                {/* Gestione files condominio */}
                <h1 className='text-lg font-semibold '>Files riservati di {nome} {cognome}</h1>
                <h2 className={'text-semibold text-red-800 '+(nessunRisultato)}>Nessun File presente</h2>

                {/* Elenco files */}
                <div>
                  {/* <ListaFiles firebase={firebase} /> */}
                  {allFiles.map((item, i) => {
                     
                      return (
                        <div className='md:flex md:justify-between border border-indigo-500 my-2 py-2 px-5 bg-gray-50 rounded-lg focus:shadow-outline hover:bg-indigo-100 cursor-pointer' key={i}>
                          <a className='cursor-pointer font-semibold mb-3 md:mb-0' href={item.url}>{item.nome.slice(13)}  <span className='text-sm block'>{item.data}</span></a>
                          {/* <div className='cursor-pointer font-semibold mb-3 md:mb-0' onClick={(e) => {openFile(item.nome)}}>{item.nome.slice(13)}    <span className='text-sm block'>{item.data}</span> <span className='text-sm block'>File riservato a: {item.tipo}</span> </div> */}
                          {/* <div className='cursor-pointer font-bold text-red-600' onClick={(e) => {eliminaFile(item.nome, item.id)}}>Elimina</div> */}
                          <div className='my-auto cursor-pointer font-bold text-red-600' onClick={(e) => {showModal('file', item.nome, item.id)}}>Elimina</div>
                        </div>
                      );
                    
                  })}
                  <div className={'mt-8 flex justify-center '+(nessunRisultatoReverse)}>
                    <div className={'py-2 px-4 border border-indigo-500 rounded-full font-bold text-indigo-500 cursor-pointer '+(annoSuc)} onClick={(e) => {listaFiles(Number(urlAnno)+1)}}>&lt;</div>
                    <div className='mx-5 p-2 border border-indigo-500 rounded-full font-bold text-indigo-500'>{urlAnno}</div>
                    <div className={'py-2 px-4 border border-indigo-500 rounded-full font-bold text-indigo-500 cursor-pointer '+(annoPrec)} onClick={(e) => {listaFiles(Number(urlAnno)-1)}}>&gt;</div>
                  </div>
                </div>

                {/* Upload file */}
                <div>
                  <div className="flex w-full h-64 items-center justify-center bg-grey-lighter">
                      <label className="w-72 flex flex-col items-center px-4 py-6 bg-white text-indigo-500 rounded-lg shadow-lg tracking-wide uppercase border border-indigo-500 cursor-pointer hover:bg-indigo-500 hover:text-white">
                          <svg className="w-10 h-10" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                              <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                          </svg>
                          <span className="mt-2 text-sm leading-normal">{nomeFile}</span>
                          <input type='file' className="hidden" onChange={(e) => {onFileChange(e); }} />
                      </label>
                  </div>
                  {/* <div className='flex items-center justify-center -mt-14'>
                    {getSelection()}
                      
                  </div> */}
                  <div className='flex items-center justify-center -mt-16'>
                    <button className="w-72 mx-auto h-8 px-4 text-sm text-indigo-100 transition-colors duration-150 bg-indigo-700 rounded-lg focus:shadow-outline hover:bg-indigo-800" onClick={(e) => {uploadToFirebase(); }}>Invia file</button>
                  </div>
                </div>
              </div>






            </div>
            <div className="flex justify-between">
            <button className=' h-10 px-5 mx-4 my-6 text-indigo-100 transition-colors duration-150 bg-indigo-700 rounded-lg focus:shadow-outline hover:bg-indigo-800' onClick={() => indietro()}> Indietro</button>
              {/* <button className=' h-10 px-5 mx-4 my-6 text-indigo-100 transition-colors duration-150 bg-indigo-700 rounded-lg focus:shadow-outline hover:bg-indigo-800' onClick={() => history.push({
          pathname: '/amministratori/condominio',
          search: '?id='+urlIdC+'&anno='+new Date().getFullYear()
        })}> Indietro</button> */}
              <SignOutButton />    
            </div>






            {/* modal */}
            <div className="hidden fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true" id='modal'>
              <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        {/* <!-- Heroicon name: outline/exclamation --> */}
                        <svg className="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                        </svg>
                      </div>
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                          {btnTitolo}
                        </h3>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            {btnTesto}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                    <button id='btnFile' type="button" onClick={(e) => {{eliminaFile(btnNome, btnId)}}} className="hidden w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
                      Elimina
                    </button>
                    <button id='btnCliente' type="button" onClick={(e) => {{eliminaCliente()}}} className="hidden w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
                      Elimina
                    </button>
                    <button id='btnParticella' type="button" onClick={(e) => {{deleteClienteParticella(btnId)}}}  className="hidden w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
                      Elimina
                    </button>
                    <button type="button"  onClick={(e) => {modal.style.display = "none"}} className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                      Cancella
                    </button>
                  </div>
                </div>
              </div>
            </div>
        </div> 
        <Footer />
      </div>   
    );
}

export default GestioneCliente;