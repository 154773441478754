import React from "react";
import getFirebase from "./firebase";
import { useHistory } from "react-router-dom";

const SignOutButton = () => {
  const firebaseInstance = getFirebase();
  const history = useHistory();

  function signOut() {
    firebaseInstance.auth.signOut();
    history.push("/");
  };

  return (
    <div>
      <button className=' h-10 px-5 mx-4 my-6 text-indigo-100 transition-colors duration-150 bg-indigo-700 rounded-lg focus:shadow-outline hover:bg-indigo-800' onClick={() => signOut()}>Log Out</button>
    </div>
  );
};

export default SignOutButton;

