import React, { useEffect } from "react";
import { Helmet } from 'react-helmet-async';
import getFirebase from "./firebase";
import { Link, useHistory } from "react-router-dom";
import SignInForm from "./SignInForm";
import Footer from './Footer'
import Header from './Header'
import CookieConsent from "react-cookie-consent";
import { collection, getDocs, query, where } from "firebase/firestore";

function Login(){
  //const [currentUser, setCurrentUser] = useState(null);
  let history = useHistory();
  //let url = new URL(window.location.href);

  const ombra = {
    boxShadow: '0px -4px 12px 5px rgba(127,127,127,0.3)',
  }
  


  useEffect(() => {
    const firebase = getFirebase();


    const amministratore=async(authUser)=>{
      const q = query(collection(firebase.db, "amministratori"), where("email", "==", firebase.auth.currentUser.email));
      const querySnapshot = await getDocs(q);
      if(querySnapshot.empty) {
          history.push({
              pathname: '/clienti/cliente',
              search: '?id='+authUser.email
          });
      } else {
        querySnapshot.forEach((doc) => {
          if(doc.id) {
            history.push("/amministratori/home");
          } 
        });
      }
      /* querySnapshot.forEach((doc) => {
        if(doc.id) {
          history.push("/amministratori/home");
        } 
      }); */
    }
    //const firebase = getFirebase();
    //const auth = getAuth(firebase);

    if (firebase.instance) {
      //console.log(firebase.instance);
      firebase.auth.onAuthStateChanged((authUser) => {
        if (authUser) {
          //setCurrentUser(authUser.email);
          amministratore(authUser);
          /* history.push({
              pathname: '/clienti/cliente',
              search: '?id='+authUser.email
          }); */
        } else {
          //setCurrentUser(null);
        }
      });
    }
  }, [history]);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div id='container'>
      <Helmet>
        <html lang="it" />
        <title>Studio Ballon - Login Area Riservata</title>
        <meta name="description" content="Studio Ballon - Accesso Area Riservata" />
        <meta name="theme-color" content="#E6E6FA" />
      </Helmet>
      <Header />
      <div className="max-w-xl p-5 mx-auto mt-20  md:mb-44 mb-20 shadow-2xl bg-white rounded-xl">
        {/* <h2 className='font-bold mb-5 text-3xl'>Studio Ballon </h2> */}
        <h3 className='font-semibold mb-5 text-xl'>
           Fai Login per entrare nella tua area protetta
        </h3>
        <SignInForm />
        <div>
            <Link className='underline text-indigo-700' to="/reset">Sei al primo accesso o hai dimenticato la password?</Link>
        </div>
      </div>
      {/* <Footer /> */}
      <Footer/>
      <CookieConsent
            acceptOnScroll={true}
            acceptOnScrollPercentage={50}
            onAccept={(byScroll) => {
            }}
            location="bottom"
            buttonText="OK"
            cookieName="myAwesomeCookieName2"
            style={{ background: "#2B373B" }}
            buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
            expires={150}
            >
            <span style={{ fontSize: "12px" }}>Questo sito utilizza cookie tecnici, analitici e di profilazione, anche di terze parti, necessari rispettivamente, a consentire alcune funzionalità fondamentali per il sito, ad effettuare misurazioni delle performances del sito stesso o a fornirti indicazioni promozionali in linea con i tuoi interessi.

Per avere maggiori informazioni e scoprire come negare in tutto o in parte i tuoi consensi, consulta la <Link to='/PrivacyCookie' className='underline'>cookie policy</Link>.</span>
        </CookieConsent>
    </div>
  );
}

export default Login;