import React, { useEffect,useRef } from "react";
import { Helmet } from 'react-helmet-async';
import Number from './CountNumber'
import { Link } from "react-router-dom";
import Footer from './Footer'
import Header from './Header'
import { HiOutlineLockClosed } from 'react-icons/hi';
import { HiOutlinePencilAlt } from 'react-icons/hi';
import { BsGraphUp } from 'react-icons/bs'
import {FaRegHandshake} from 'react-icons/fa'
import {HiOutlineOfficeBuilding} from 'react-icons/hi'
import {AiOutlineCloudDownload} from 'react-icons/ai'
import {BsArrowRepeat} from 'react-icons/bs'
import {RiTeamLine} from 'react-icons/ri'
import 'animate.css';
import useIntersection from "./useIntersection";
import CookieConsent from "react-cookie-consent";

//import { useSpring, animated } from 'react-spring'

function Home(){
    //const [currentUser, setCurrentUser] = useState(null);
    
    //const [isOpen, setIsOpen] = useState(false);

    const ref = useRef();
    const inViewport = useIntersection(ref, "-50px");
    if (inViewport) {
        ref.current.classList.add('animate__fadeIn')
        ref.current.classList.add('animate__animated')
        ref.current.classList.add('animate__slower')
    }

    const ref1 = useRef();
    const inViewport1 = useIntersection(ref1, "-50px");
    if (inViewport1) {
        ref1.current.classList.add('animate__fadeIn')
        ref1.current.classList.add('animate__animated')
        ref1.current.classList.add('animate__slower')
    }

    const ref2 = useRef();
    const inViewport2 = useIntersection(ref2, "-50px");


    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    
    

  //const props = useSpring({ to: { opacity: 1 }, from: { opacity: 0 } })
  


  

  return (
    <div className=''>
        <Helmet>
            <html lang="it" />
            <title>Studio Ballon - Home Page</title>
            <meta name="description" content="Studio Ballon - Montebelluna (TV) - Studio di architettura ed amministrazione immobiliare" />
            <meta name="theme-color" content="#E6E6FA" />
            <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1'/>
            <meta property="og:locale" content="it_IT"/>
            <meta property="og:title" content="Studio Ballon"/>
            <meta property="og:description" content="Studio Ballon - Montebelluna (TV) - Studio di architettura ed amministrazione immobiliare"/>
            <meta property="og:url" content="https://studioballon.it/"/>
            <meta property="og:site_name" content="studioballon.it"/>
            <meta property="article:modified_time" content="2022-03-21T10:32:09+00:00"/>
            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:label1" content="Tempo di lettura stimato"/>
            <meta name="twitter:data1" content="3 minuti"/>
        </Helmet>
        
        <Header />
        

        <div className='border-b-8 border-yellow-500'>
            <div id='' className='bg-blueGray-800 overflow-hidden h-full md:pb-10'>        
                <div className="relative w-2/3 ml-auto h-auto">
                    <div className="absolute top-0 -left-48 sm:-left-40 md:-left-56 lg:-left-10  lg:-ml-0 rounded-full  w-full">
                        <img className="object-contain transform scale-200" src="shadow-blue-full2.png" alt="" />
                    </div>
                    {/* <div className="absolute  -top-8 -right-36 sm:-right-72 lg:-right-16 md:-right-36">
                        <div className="w-5/6 sm:w-4/6 lg:w-full md:w-5/6 ">
                            <img className=" " src='Montebelluna2.png' alt=""/>
                        </div>
                    </div> */}
                </div>

                <div className="relative container mx-auto px-4">
                    <div className="absolute md:-bottom-16 right-0 bottom-0 md:-right-36 lg:-right-36 w-3/6  md:w-3/5 lg:w-auto">
                        <div className="">
                            <img className=" " src='Montebelluna2.png' alt=""/>
                        </div>
                    </div>
                    <div className="   ">
                        <div className="w-full md:w-1/2 lg:mt-16">
                            <div className="max-w-sm md:max-w-none ">
                                <h1 className='flex pt-5  animate__fadeIn animate__animated animate__faster'>
                                    <img className='lg:h-56 md:h-44 sm:h-32 h-20' src='logo_ballon42.png' alt='Studio Ballon' />
                                    <div className='md:text-7xl text-5xl ml-2 font-sans  text-white ObjectiveMedium'><div>STUDIO</div> BALLON</div>
                                </h1>
                                <h2 className="text-white pt-10 text-xl pb-10 md:pb-0  md:text-3xl text-gray  font-heading ObjectiveBoldItalic w-2/3  animate__fadeIn animate__animated animate__faster">Da 40 anni ci prendiamo cura del vostro condominio a 360°</h2>
                                <div className=' hidden  lg:flex animate__fadeIn animate__animated animate__slower'>  
                                    <div className=' mt-10 w-2/5 mx-auto'>
                                        <div className='text-yellow-500 font-semibold lg:text-2xl w-40 mx-auto text-lg text-center '>ACCEDI AL TUO CONDOMINIO</div>
                                        <div className='border-4 border-yellow-500  lg:h-40 lg:w-40 w-36 h-36 p-1 content-center align-middler rounded-tl-3xl rounded-br-3xl rounded-bl-3xl bg-white mx-auto mt-4 transform hover:scale-105'>
                                            <Link to='/login' className='mx-auto'><HiOutlineLockClosed className='lg:h-36 lg:w-36 h-32 w-32 text-yellow-500 cursor-pointer'/></Link>
                                        </div>
                                    </div>
                                    <div className=' mt-10 w-2/5 mx-auto'>
                                        <div className='text-yellow-500 font-semibold lg:text-2xl w-40 mx-auto text-lg text-center '>RICHIEDI UN PREVENTIVO GRATUITO</div>
                                        <div className='border-4 border-yellow-500  lg:h-40 lg:w-40 w-36 h-36 p-1 content-center align-middler rounded-tl-3xl rounded-br-3xl rounded-bl-3xl bg-white mx-auto mt-4 transform hover:scale-105'>
                                        <Link to='/preventivi' className='mx-auto'><HiOutlinePencilAlt className='lg:h-36 lg:w-36 h-32 w-32 text-yellow-500 cursor-pointer'/></Link>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div ref={ref1} className=' lg:hidden  flex opacity-0'>  
            <div className=' my-5 w-2/5 mx-auto'>
                <div className='text-yellow-500 font-semibold lg:text-2xl w-28 mx-auto text-base text-center '>ENTRA NELLA TUA AREA RISERVATA</div>
                <div className='border-4 border-yellow-500  lg:h-40 lg:w-40 w-28 h-28 p-1 content-center align-middler rounded-tl-3xl rounded-br-3xl rounded-bl-3xl bg-white mx-auto mt-4 transform hover:scale-105'>
                    <Link to='/login' className='mx-auto'><HiOutlineLockClosed className='lg:h-36 lg:w-36 h-24 w-24 text-yellow-500 cursor-pointer'/></Link>
                </div>
            </div>
            <div className=' my-5 w-2/5 mx-auto'>
                <div className='text-yellow-500 font-semibold lg:text-2xl w-40 mx-auto text-base text-center '>RICHIEDI UN PREVENTIVO GRATUITO</div>
                <div className='border-4 border-yellow-500  lg:h-40 lg:w-40 w-28 h-28 p-1 content-center align-middler rounded-tl-3xl rounded-br-3xl rounded-bl-3xl bg-white mx-auto mt-4 transform hover:scale-105'>
                <Link to='/preventivi' className='mx-auto'><HiOutlinePencilAlt className='lg:h-36 lg:w-36 h-24 w-24 text-yellow-500 cursor-pointer'/></Link>
                </div>
            </div>
        </div>
        <div  className='border-b-8 border-t-8 lg:border-t-0 border-yellow-500 bg-gray-50'>
            <h2 className='text-center ObjectiveMedium text-3xl pb-5 pt-8 text-blueGray-800'>Specialisti in Amministrazione Immobiliare e Architettura</h2>
            <div className='w-1/3 mx-auto border border-yellow-500'></div>
            <p className='md:w-2/3 mx-auto text-center p-5'>Operiamo da oltre 40 in provincia di Treviso sia nell'amministrazione condominiale, che nella progettazione di edifici ed interni. Il nostro studio diretto dal Arch. Marco Ballon, iscritto all’A.N.A.C.I. e all’Ordine degli Architetti di Treviso, si occuperà di tutte le pratiche tecniche, gestionali e assicurative dei vostri immobili garantendovi professionalità, affidabilità e umanità.</p>
        
        
            <div className='lg:py-10 md:py-24 ObjectiveRegular'>
                <div className='lg:w-11/12 mx-auto md:flex '>
                    <div className='md:w-1/3'>
                        <div className="flip mx-auto h-72">
                            <div className='flip-content'>                                
                                <div className="flip-front">
                                    <div className='mx-2'>
                                        <div className='bg-gradient-to-r from-cyan-700 to-cyan-900  p-3  rounded-lg'>
                                            <div className='bg-white  rounded-lg p-5'>
                                                <div className='w-14 h-14 bg-cyan-900 mx-auto rounded-full p-2'>
                                                    <AiOutlineCloudDownload className='h-10 w-10 text-white ' />
                                                </div>
                                                <h3 className='text-center text-gray-800  lg:text-2xl text-xl py-3'>DIGITAL</h3>
                                                <p>Per un'amministrazione 2.0, tutte le informazioni tue e del tuo condominio sempre disponibili online. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flip-back">
                                <div className='mx-2'>
                                        <div className='bg-gradient-to-r from-yellow-500 to-yellow-700  p-3  rounded-lg'>
                                            <div className='bg-white  rounded-lg p-5'>
                                                <h3 className='text-center text-gray-800  lg:text-2xl text-xl '>INNOVAZIONE</h3>
                                                <p className="mb-3">Software appositamente studiato per la gestione immobiliare, sempre aggiornato per la miglior soddisfazione cliente. </p>
                                                <Link to='/preventivi' className=" text-yellow-500 hover:text-white border border-yellow-500 hover:bg-yellow-600 focus:ring-4 focus:ring-yellow-307000 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 mb-3 dark:border-yellow-500 dark:text-yellow-500 dark:hover:text-white dark:hover:bg-yellow-600 dark:focus:ring-yellow-700">Richiedi una consulenza</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div className='md:w-1/3'>
                        <div className="flip mx-auto h-72">
                            <div className='flip-content'>                                
                                <div className="flip-front">
                                    <div className='mx-2'>
                                        <div className='bg-gradient-to-r from-cyan-700 to-cyan-900  p-3  rounded-lg'>
                                            <div className='bg-white  rounded-lg p-5'>
                                                <div className='w-14 h-14 bg-cyan-900 mx-auto rounded-full p-2'>
                                                    <BsArrowRepeat className='h-10 w-10 text-white  ' />
                                                </div>
                                                <h3 className='text-center text-gray-800  lg:text-2xl text-xl py-3'>ORGANIZZAZIONE</h3>
                                                <p>Esperienza, competenza e professionalità nella gestione immobiliare.  Per essere sempre vicini ai nostri clienti.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flip-back">
                                <div className='mx-2'>
                                        <div className='bg-gradient-to-r from-yellow-500 to-yellow-700  p-3  rounded-lg'>
                                            <div className='bg-white  rounded-lg p-5'>
                                                <h3 className='text-center text-gray-800  lg:text-2xl text-xl '>COMPETENZE</h3>
                                                <p className="mb-3">I nostri collaboratori, ti offriranno affidabilità ed efficienza in tutti gli aspetti contabili, assicurativi, tecnici ed operativi. </p>
                                                <Link to='/preventivi' className="text-yellow-500 hover:text-white border border-yellow-500 hover:bg-yellow-600 focus:ring-4 focus:ring-yellow-307000 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 mb-3 dark:border-yellow-500 dark:text-yellow-500 dark:hover:text-white dark:hover:bg-yellow-600 dark:focus:ring-yellow-700">Richiedi una consulenza</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div className='md:w-1/3'>
                        <div className="flip mx-auto h-72">
                            <div className='flip-content'>                                
                                <div className="flip-front">
                                    <div className='mx-2'>
                                        <div className='bg-gradient-to-r from-cyan-700 to-cyan-900  p-3  rounded-lg'>
                                            <div className='bg-white  rounded-lg p-5'>
                                                <div className='w-14 h-14 bg-cyan-900 mx-auto rounded-full p-2'>
                                                    <RiTeamLine className='h-10 w-10 text-white  ' />
                                                </div>
                                                <h3 className='text-center text-gray-800  lg:text-2xl text-xl py-3'>PARTNER</h3>
                                                <p>Imprese ed artigiani accuratamente selezionati per tutte le esigenze di manutenzione, ristrutturazione e costruzione </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flip-back">
                                <div className='mx-2'>
                                        <div className='bg-gradient-to-r from-yellow-500 to-yellow-700  p-3  rounded-lg'>
                                            <div className='bg-white  rounded-lg p-5'>
                                                <h3 className='text-center text-gray-800  lg:text-2xl text-xl '>FORNITORI</h3>
                                                <p className="text-base mb-3">Imprese ed artigiani del territorio, da noi selezionati e sotto la nostra supervisione si occuperanno di tutte le esigenze di manutenzione del vostro condominio. </p>
                                                <Link to='/preventivi' className=" text-yellow-500 hover:text-white border border-yellow-500 hover:bg-yellow-600 focus:ring-4 focus:ring-yellow-307000 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 mb-3 dark:border-yellow-500 dark:text-yellow-500 dark:hover:text-white dark:hover:bg-yellow-600 dark:focus:ring-yellow-700">Richiedi una consulenza</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
        
        </div>
        <div ref={ref2} className='py-20 bg:cover md:bg-cover bg-center bg-no-repeat bg-blueGray-700 overflow-hidden ObjectiveBold border-b-8 border-yellow-500' style={{ backgroundImage: `url("esterno.jpg")` }}>
            <div className="relative w-2/3 ">
                    <div className="absolute md:-top-60 md:-left-32 lg:left-42  rounded-full  w-full">
                        <img className="object-contain transform scale-150" src="shadow-blue-full2.png" alt="" />
                    </div>
                    {/* <div className="absolute  -top-8 -right-36 sm:-right-72 lg:-right-16 md:-right-36">
                        <div className="w-5/6 sm:w-4/6 lg:w-full md:w-5/6 ">
                            <img className=" " src='Montebelluna2.png' alt=""/>
                        </div>
                    </div> */}
                </div>
            <div className=' lg:w-3/5 mx-auto md:flex p-5 bg-blueGray-700 rounded-2xl bg-opacity-50'>
                <div className='md:w-2/5 mx-auto'>
                    <div><BsGraphUp className='h-28 w-28 text-yellow-500 mx-auto' /></div>
                    {inViewport2 && <Number className='' numberEnd='40' />}
                    <div className='text-center text-4xl font-bold py-3 text-white'>anni di esperienza</div>
                </div>
                <div className='w-1/3 mx-auto hidden'>
                    <div><HiOutlineOfficeBuilding className='h-24 w-24 text-yellow-500 mx-auto' /></div>
                    {inViewport2 && <Number className='' numberEnd='200' />}
                    <div className='text-center text-3xl font-bold py-3 text-white'>condomini amministrati</div>
                </div>
                <div className='md:w-2/5 mx-auto'>
                    <div><FaRegHandshake className='h-28 w-28 text-yellow-500 mx-auto' /></div>
                    {inViewport2 && <Number className='' numberEnd='1000' />}
                    <div className='text-center text-4xl font-bold py-3 text-white'>clienti soddisfatti</div>
                </div>
            </div>
        </div>
        <Footer />
        <CookieConsent
            acceptOnScroll={true}
            acceptOnScrollPercentage={50}
            onAccept={(byScroll) => {
            }}
            location="bottom"
            buttonText="OK"
            cookieName="myAwesomeCookieName2"
            style={{ background: "#2B373B" }}
            buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
            expires={150}
            >
            <span style={{ fontSize: "12px" }}>Questo sito utilizza cookie tecnici, analitici e di profilazione, anche di terze parti, necessari rispettivamente, a consentire alcune funzionalità fondamentali per il sito, ad effettuare misurazioni delle performances del sito stesso o a fornirti indicazioni promozionali in linea con i tuoi interessi.

Per avere maggiori informazioni e scoprire come negare in tutto o in parte i tuoi consensi, consulta la <Link to='/PrivacyCookie' className='underline'>cookie policy</Link>.</span>
        </CookieConsent>
        
    </div>
  );
}

export default Home;