import getFirebase from "../firebase";
import {  getDocs, query, addDoc, collection, where, serverTimestamp } from "firebase/firestore";


const AddClientiParticella = async (condominio, nomeCon, particella, nomePart, email, identificativoCliente, tipo) => {

    const firebase = getFirebase();





    const getClientiParticella = collection(firebase.db, "clientiParticella");
    const q = query(getClientiParticella,  where('email', '==', email),  where('condominio', '==', condominio),  where('particella', '==', particella));
    let querySnapshot = await getDocs(q);
    if(querySnapshot.empty) {
        //console.log(condominio+' '+ nomeCon+' '+particella+' '+nomePart+' '+email+' '+identificativoCliente+' '+tipo)
        if(condominio && nomeCon && particella && nomePart && email && identificativoCliente && tipo){
            try {
                let tipoSingle = ''
                switch (tipo) {
                    case 'Entrambi':
                      tipoSingle = 'P'
                      break;
                    case 'Proprietario':
                      tipoSingle = 'P'
                      break;
                    case 'Inquilino':
                      tipoSingle = 'I'
                      break;
                  }
                const docRef = await addDoc(collection(firebase.db, "clientiParticella"), {
                    condominio: condominio,
                    nomeCon: nomeCon,
                    particella: particella,
                    nomePart: nomePart,
                    email: email,
                    tipo: tipo,
                    identificativoCliente: identificativoCliente,
                    tipoSingle: tipoSingle,
                    timestamp: serverTimestamp(),
                });
                return tipoSingle
                
            } catch (e) {
                /* addDoc(collection(firebase.db, "errori"), {
                    nome: 'Inserimento Cliente',
                    timestamp: serverTimestamp(),
                    dati: e
                }); */
            }
        } 
    } else {
        alert('Attenzione! La particella è già associata a questo cliente')
    }


}

export default AddClientiParticella