import React, { useState } from "react";
import { GoogleMap, useLoadScript, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
//import { Helmet } from "react-helmet";
import { Helmet } from 'react-helmet-async';
import '../App.css';
import Header from '../Header'
import Footer from '../Footer'
import getFirebase from "../firebase";
import { collection, query, where, getDocs, orderBy } from "firebase/firestore";
import { useHistory } from "react-router-dom";
import SignOutButton from "../SignOutButton";
//import AddCondominio from "./AddCondominio";
//import { collection, getDocs, query, where, doc, setDoc } from "firebase/firestore";
import AddCondominioForm from "./AddCondominioForm";
//import MapContainer from './Maps'
//import { render } from "@testing-library/react";
//import RicercaCondominiForm from './RicercaCondominiForm';
//import ListaCondomini from "./ListaCondomini";


function Amministratore() {
  //console.log(window.google)
    const history = useHistory();
    
    const firebase = getFirebase();
    const utente = firebase.auth.currentUser;

    const [lista, setLista] = useState([]);
    const [allCognome, setAllCognome] = useState([])

    const [mappa, setMappa] = useState('hidden')
    const [labelMappa, setlabelMappa] = useState('hidden')
    const [textLabelMappa, setTextLabelMappa] = useState('Visualizza i risultati sulla mappa')

    const [nomeMap, setNomeMap] = useState('')
    const [viaMap, setViaMap] = useState('')
    const [indirizzoMap, setIndirizzoMap] = useState('')
    const [civicoMap, setCivicoMap] = useState('')
    const [cittaMap, setCittaMap] = useState('')
    const [idMap, setIdMap] = useState('')
    const [iMap, setIMap] = useState(0)

    const d = new Date();
    let anno = d.getFullYear();

    
    if(!utente) {
      history.replace("/");
    } else {
      //setCurrentUser(utente.email)
    }

    
    const { isLoaded, loadError } = useLoadScript({
      googleMapsApiKey: "AIzaSyBjTG9p_f1DZ2lKsPFxo_iZ8FMztXgxuGw" // ,
      // ...otherOptions
    })
    const MapContainer = (props) => {
      const mapStyles = {        
        height: "70vh",
        width: "100%"};
    
      const defaultCenter = {
        lat: 45.77678911065497, lng: 12.043988869103384
      }
  
      //const [ selected, setSelected ] = useState({});
      /* const [lat, setLat] = useState([])
      const [lng, setLng] = useState([]) */
      let lat = [45.77678911065497]
      let latCenter = 0
      let lng = [12.043988869103384]
      let lngCenter = 0
      let center ={lat: 45.77678911065497, lng: 12.043988869103384}
      let zoom = 11
      props.lista.map((item) => {
          //alert(item.location.lat)
          lat.push(item.location.lat)
          lng.push(item.location.lng)
      })
      lat.sort(function(a, b){return a - b});
      lng.sort(function(a, b){return a - b});
      //console.log(lng)
      if(lat.length ===1) {
          latCenter = lat[0]
          zoom =12
      } else {
          latCenter = ((lat[0] + lat[lat.length-1]) / 2)
      }
      if(lng.length ===1) {
        lngCenter = lng[0]
      } else {
        lngCenter = ((lng[0] + lng[lng.length-1]) / 2)
      }
      center = {lat: latCenter, lng: lngCenter}
  
      if((lat[lat.length-1] -lat[0]) < 0.1 && (lng[lng.length-1] -lng[0]) < 0.11) {
          zoom = 12
      }
    
      const onMarkerClick = (nome, via, indirizzo, civico, citta, id, i) => {
        //e.preventDefault()
        //history.replaceState('/amministratori/condominio?id='+id+'&anno='+anno)
        document.getElementById('condominioMap').style.display='block'
        setNomeMap(nome)
        setViaMap(via)
        setIndirizzoMap(indirizzo)
        setCivicoMap(civico)
        setCittaMap(citta)
        setIdMap(id)
        setIMap(i+1)
      }

      const onLoad = React.useCallback(
        function onLoad (mapInstance) {
          // do something with map Instance
        }
      )

    
      return (
        
        <GoogleMap
        mapContainerStyle={mapStyles}
        center={center}
        zoom={zoom}
        onLoad={onLoad}>
            {
                props.lista.map((item, i) => {
                    return (
                    <Marker key={item.id} 
                    position={item.location}
                    label = {String(i+1)}
                    title = {item.nome}
                    /* onClick={onMarkerClick(item.nome)} */
                    onClick={ (e) => {
                        /* ReactDOM.unmountComponentAtNode(document.getElementById('mappa'));
                        window.location = '/amministratori/condominio?id='+item.id+'&anno='+anno; */
                        onMarkerClick(item.nome, item.via, item.indirizzo, item.civico, item.citta, item.id, i)
                      }}
                    />
                    )
                })
            }
            
        </GoogleMap>
   
      )
    }
    if (loadError) {
      return <div>Map cannot be loaded right now, sorry.</div>
    }
  
    /* const amministratore=async()=>{
      const q = query(collection(firebase.db, "amministratori"), where("email", "==", utente.email));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        
      });
    } */


    

    const Capitalize = (prop) => {
      let str = prop.toLowerCase();
      let arr = str.split(" ");
      for (var i = 0; i < arr.length; i++) {
          arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }
      return (arr.join(" ")).trim();
    }



    const RicercaCondominiNome = async (event) => {
      document.getElementById('condominioMap').style.display='none'
      setLista([]);
      event.preventDefault();  
      /* function Capitalize(prop) {
        let str = prop.toLowerCase();
        let arr = str.split(" ");
        for (var i = 0; i < arr.length; i++) {
          arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
        }
        return arr.join(" ");
      } */
      const nome = Capitalize(event.target[0].value);    
      try {
          const getCondomini = collection(firebase.db, "condomini");
          const q = query(getCondomini, where("nome", ">=", nome), orderBy("nome"));
          let querySnapshotNome = await getDocs(q);
          if(!querySnapshotNome.empty) {
            setlabelMappa('block')
          } else {
            setlabelMappa('hidden')
            setMappa('hidden')
            setTextLabelMappa('Visualizza i risultati sulla mappa')
          }
          querySnapshotNome.forEach((doc) => {
            setLista(oldArray => [...oldArray, {id: doc.id, nome: doc.data().nome, via: doc.data().via, indirizzo: doc.data().indirizzo, civico: doc.data().civico, citta: doc.data().citta, location: {lat: doc.data().lat, lng: doc.data().lng}, cap: doc.data().cap}]);
          });
          if(document.getElementById("formCondominiNome")) {
            document.getElementById("formCondominiNome").reset()
          }
      } catch (e) {
          history.replace('/')
          //console.log(e)
          alert("Errore nella ricerca del database");
          /* addDoc(collection(firebase.db, "errori"), {
            nome: 'Ricerca Condomini Nome',
            timestamp: serverTimestamp(),
            dati: e
          }); */
      }
    };
    const RicercaCondominiCitta = async (event) => {
      document.getElementById('condominioMap').style.display='none'
      setLista([]);
      event.preventDefault();  
      /* function Capitalize(prop) {
        let str = prop.toLowerCase();
        let arr = str.split(" ");
        for (var i = 0; i < arr.length; i++) {
          arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
        }
        return arr.join(" ");
      } */
      const citta = Capitalize(event.target[0].value);    
      try {
          const getCondomini = collection(firebase.db, "condomini");
          const q = query(getCondomini, where("citta", "==", citta), orderBy("nome", "desc"));
          let querySnapshotNome = await getDocs(q);
          if(!querySnapshotNome.empty) {
            setlabelMappa('block')
          } else {
            setlabelMappa('hidden')
            setMappa('hidden')
            setTextLabelMappa('Visualizza i risultati sulla mappa')
          }
          querySnapshotNome.forEach((doc) => {
            setLista(oldArray => [...oldArray, {id: doc.id, nome: doc.data().nome, via: doc.data().via, indirizzo: doc.data().indirizzo, civico: doc.data().civico, citta: doc.data().citta, location: {lat: doc.data().lat, lng: doc.data().lng}, cap: doc.data().cap}]);
          });
          document.getElementById("formCondominiCitta").reset();
      } catch (e) {
        alert("Errore nella ricerca del database");
        /* addDoc(collection(firebase.db, "errori"), {
          nome: 'Ricerca Condomini Città',
          timestamp: serverTimestamp(),
          dati: e
        }); */
      }
    };

    /* const RicercaCondominiTot = async (event) => {
      setLista([]);
      event.preventDefault();     
      try {
          const getCondomini = collection(firebase.db, "condomini");
          const q = query(getCondomini, orderBy("nome", "desc"));
          let querySnapshotNome = await getDocs(q);
          querySnapshotNome.forEach((doc) => {
            setLista(oldArray => [...oldArray, {id: doc.id, nome: doc.data().nome, via: doc.data().via, indirizzo: doc.data().indirizzo, civico: doc.data().civico, citta: doc.data().citta}]);
          });
      } catch (e) {
          console.log("Errore del database: "+e);
      }
    }; */
    
    const getClienteCognome = async (cog, event) => {
      setAllCognome([])
      let cognome = ''
      if(event) {
        event.preventDefault()
        cognome = Capitalize((event.target[0].value.toLowerCase().trim()));
      }
      if(cog) {
        cognome = cog
      }
      //if(cognome) {
        try {
          const getCliente = collection(firebase.db, "clienti");
          const q = query(getCliente,  where('cognome', '>=', cognome), orderBy("cognome"), orderBy("nome"));
          let querySnapshot = await getDocs(q);
          if(querySnapshot.empty) {
            alert('Nessun cliente trovato')
          } else {
            querySnapshot.forEach((doc) => {
              setAllCognome(oldArray => [...oldArray, {id: doc.id, nome: doc.data().nome, cognome: doc.data().cognome, email: doc.data().email}]);
            })
            document.getElementById("formClientiCognome").reset();
          }
        } catch (e) {
          alert("Errore nella ricerca del database");
          /* addDoc(collection(firebase.db, "errori"), {
            nome: 'Ricerca Cliente Cognome',
            timestamp: serverTimestamp(),
            dati: e
          }); */
        }
      //} else {
      //}
      document.getElementById("formClientiCognome").reset();
    }

    const getClienteEmail = async (email, event) => {
      event.preventDefault()
        try {
          const getCliente = collection(firebase.db, "clienti");
          const q = query(getCliente,  where('email', '==', event.target[0].value));
          let querySnapshot = await getDocs(q);
          if(querySnapshot.empty) {
            alert('Nessun cliente trovato')
          } else {
            querySnapshot.forEach((doc) => {
              document.getElementById("formClientiEmail").reset();
              history.push({
                pathname: '/amministratori/gestionecliente',
                search: '?id='+doc.id+'&anno='+anno
              })
            })
          }
        } catch (e) {
          alert("Errore nella ricerca del database");
          /* addDoc(collection(firebase.db, "errori"), {
            nome: 'Ricerca Cliente Email',
            timestamp: serverTimestamp(),
            dati: e
          }); */
        }
      //document.getElementById("formClientiEmail").reset();
    }
    const mostraMappa = () => {
      if(mappa === 'hidden') {
        setMappa('block')
        setTextLabelMappa('Nascondi mappa')
      } else {
        setMappa('hidden')
        setTextLabelMappa('Visualizza i risultati sulla mappa')
      }
    }

    

    
    /* function CaricaMap() {
      if(typeof window.google === 'undefined' || !window.google) {
        return <LoadScript
        googleMapsApiKey='AIzaSyBjTG9p_f1DZ2lKsPFxo_iZ8FMztXgxuGw'><MapContainer lista={lista}/></LoadScript>
      } else {
        return <MapContainer lista={lista}/>
      }
    } */
    



    
    


    return (
      <div>
        <Helmet>
          <title>Studio Ballon - Area Amministrazione</title>
        </Helmet>
        <Header />
        <div className=" p-5 w-full md:w-2/3 mx-auto my-20 shadow-2xl bg-white rounded-xl">
            <h2 className='font-bold mb-5 text-2xl'>Benvenuto Amministratore </h2>


            <div className="">
                <div className="tab w-full overflow-hidden border-t">
                  <input className="absolute opacity-0" id="tab-single-one" type="radio" name="tabs2" />
                  <label className="block p-5 leading-normal cursor-pointer font-semibold" htmlFor="tab-single-one">Aggiungi Condominio</label>
                  <div className="tab-content overflow-hidden border-l-2 bg-gray-100 border-indigo-500 leading-normal">
                    <AddCondominioForm  firebase={firebase}/>
                  </div>
                </div>
                <div className="tab w-full border-t">
                  <input className="absolute opacity-0" id="tab-single-two" type="radio" name="tabs2" />
                  <label className="block p-5 leading-normal cursor-pointer font-semibold" htmlFor="tab-single-two">Ricerca Condomini</label>
                  <div className="tab-content overflow-y-auto overflow-x-hidden  border-l-2 bg-gray-100 border-indigo-500 leading-normal">

                    {/* <form className='p-3' id='formCondomini' onSubmit={RicercaCondominiTot}>
                      <div className="flex flex-wrap -mx-2 my-4 space-y-4 md:space-y-0">
                        <div className="w-full px-4  ">
                          <button type="submit" className=' w-full  items-center px-4 h-10 font-bold text-indigo-100 bg-indigo-700 rounded-lg  hover:bg-indigo-800 focus:bg-indigo-700'>Tutti i condomini</button>
                        </div>
                      </div>
                    </form> */} 
                    
                    <div>
                      <form className='p-5' id='formCondominiNome' onSubmit={RicercaCondominiNome}>
                        <div className=" -mx-2 my-4 space-y-4 md:space-y-0">
                          <div className="w-full px-2  relative">
                            <input className="w-full h-10 pl-3 pr-8 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline" type="text" placeholder="Nome Condominio" id="formGridNome"/>
                            <button type="submit" className='md:w-auto w-full md:absolute md:inset-y-0 md:right-0 md:flex items-center px-4 h-10 font-bold text-indigo-100 bg-indigo-700 rounded-lg md:rounded-l-none hover:bg-indigo-800 focus:bg-indigo-700'>Ricerca per nome</button>
                          </div>
                          <div className='pl-3 text-sm font-medium text-yellow-600 pt-2'>Lasciare il campo vuoto e premere invio per l'elenco completo dei condomini registrati</div>
                        </div>
                      </form> 
                      <form className='p-3' id='formCondominiCitta' onSubmit={RicercaCondominiCitta}>
                        <div className="flex flex-wrap -mx-2 my-4 space-y-4 md:space-y-0">
                          <div className="w-full px-4  relative">
                            <input className="w-full h-10 pl-3 pr-8 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline" type="text" placeholder="Città" id="formGridCitta"/>
                            <button type="submit" className='md:w-auto w-full md:absolute md:inset-y-0 md:right-2 md:flex items-center px-4 h-10 font-bold text-indigo-100 bg-indigo-700 rounded-lg md:rounded-l-none hover:bg-indigo-800 focus:bg-indigo-700'>Ricerca per Città</button>
                          </div>
                        </div>
                      </form>
                      <div onClick={() => {mostraMappa()}} id='labelMappa' className={labelMappa+' text-indigo-700 underline cursor-pointer font-bold  pl-5 pb-5'}>{textLabelMappa}</div> 
                      <div id='mappa' className={mappa+' mx-5 border-2 border-indigo-600 relative'} >
                        <div id='condominioMap' className='md:w-1/3 hidden absolute z-50  px-5 py-2 m-1 border border-indigo-500 text-center text-gray-900 font-semibold transition-colors duration-150 bg-white rounded-lg focus:shadow-outline hover:bg-indigo-100 cursor-pointer' onClick={() => history.push('/amministratori/condominio?id='+idMap+'&anno='+anno)}>
                          <div className='text-lg'>{iMap} {nomeMap}</div>
                          <div className='font-normal text-base'>{viaMap} {indirizzoMap}, {civicoMap} - {cittaMap} </div>
                        </div>
                        {/* <CaricaMap /> */}
                        {isLoaded ? <MapContainer lista={lista}/> : <div className='text-red-600 text-xl font-semibold p-5'>Errore nel caricamento della mappa</div>}
                        {/* <MapContainer lista={lista}/> */}
                      </div>
                      <div className=' relative md:grid md:grid-cols-2 mx-0 p-5 pb-7 overflow-y-scroll'>
                        {lista.map((item, i) => {
                          return (
                            <div key={i} className='  px-5 py-2 m-1 border border-indigo-500 text-center text-gray-900 font-semibold transition-colors duration-150 bg-white rounded-lg focus:shadow-outline hover:bg-indigo-100 cursor-pointer' onClick={() => history.push('/amministratori/condominio?id='+item.id+'&anno='+anno)}>
                              <div className='text-lg'>{i+1}) {item.nome}</div>
                              <div className='font-normal text-base'>{item.via} {item.indirizzo}, {item.civico} - {item.citta} </div>
                            </div>
                            
                          );
                        })}
                      </div>
                    </div>
                      {/* <RicercaCondominiForm firebase={firebase} /> */}
                      {/* <ListaCondomini firebase={firebase}/> */}
                    
                      
                  </div>
                </div>
                <div className="tab w-full overflow-hidden border-t">
                  <input className="absolute opacity-0" id="tab-single-three" type="radio" name="tabs2" />
                  <label className="block p-5 leading-normal cursor-pointer font-semibold" htmlFor="tab-single-three">Ricerca Clienti</label>
                  <div className="tab-content overflow-y-auto overflow-x-hidden  border-l-2 bg-gray-100 border-indigo-500 leading-normal">

                    <form className='p-5' id='formClientiCognome' onSubmit={(e) => {getClienteCognome(false, e); }}>
                      <div className=" -mx-2 my-4 space-y-4 md:space-y-0">
                        <div className="w-full px-2  relative">
                          <input className="w-full h-10 pl-3 pr-8 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline" type="text" placeholder="Cognome Cliente" id="formGridCognome"/>
                          <button type="submit" className='md:w-auto w-full md:absolute md:inset-y-0 md:right-0 md:flex items-center px-4 h-10 font-bold text-indigo-100 bg-indigo-700 rounded-lg md:rounded-l-none hover:bg-indigo-800 focus:bg-indigo-700'>Ricerca per Cognome</button>
                        </div>
                        <div className='pl-3 text-sm font-medium text-yellow-600 pt-2'>Lasciare il campo vuoto e premere invio per l'elenco completo dei clienti registrati</div>
                      </div>
                    </form> 
                    <form className='p-3' id='formClientiEmail' onSubmit={(e) => {getClienteEmail(false, e); }}>
                      <div className="flex flex-wrap -mx-2 my-4 space-y-4 md:space-y-0">
                        <div className="w-full px-4  relative">
                          <input className="w-full h-10 pl-3 pr-8 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline" type="text" placeholder="Email Cliente" id="formGridEmail"/>
                          <button type="submit" className='md:w-auto w-full md:absolute md:inset-y-0 md:right-2 md:flex items-center px-4 h-10 font-bold text-indigo-100 bg-indigo-700 rounded-lg md:rounded-l-none hover:bg-indigo-800 focus:bg-indigo-700'>Ricerca per Email</button>
                        </div>
                      </div>
                    </form>
                    <div className='left-3 relative md:grid md:grid-cols-2 mx-0 p-2 pb-7 overflow-y-scroll'>
                      {allCognome.map((item, i) => {
                          return (
                            <div key={i} className=' px-5 py-2'>
                              <div className='border-indigo-500 border p-2 cursor-pointer rounded-lg font-semibold text-center' onClick={() => history.push({
          pathname: '/amministratori/gestionecliente',
          search: '?id='+item.id+'&anno='+anno
        })}>
                                <div>{item.nome} {item.cognome} - {item.email}</div>
                                <div>
                                  
                                </div>
                              </div>
                            </div>
                          );                      
                      })}
                    </div>
                  </div>
                </div>
            </div>


            <div>
                <SignOutButton />    
            </div>
        </div>
        {/* <Footer /> */}
        <Footer/>
      </div>
    );
}

export default Amministratore;






