//import { useState } from "react";
import getFirebase from "../firebase";
import { doc, getDocs, query, collection, where, deleteDoc } from "firebase/firestore";
//import { getStorage  } from "firebase/storage";
import EliminaCliente from './EliminaCliente'

const EliminaClienteParticella = async (email, urlId) => {
    const firebase = getFirebase();
    //const storage = getStorage();
    //const [risultato, setRisultato] = useState(false)

    
    

    const deleteClientiParticella = async (id) => {
        try {
            await deleteDoc(doc(firebase.db, "clientiParticella", id));
            //alert('Cliente eliminato')
            //return  true 
        } catch (e) {
            /* addDoc(collection(firebase.db, "errori"), {
                nome: 'Elimina Cliente Particella',
                timestamp: serverTimestamp(),
                dati: e
              }); */
            //return false
        } 
    }  

    const getClienteParticella = async (email, urlId) => {
        try {
            const getFiles = collection(firebase.db, "clientiParticella");
            const q = query(getFiles, where('particella', '==', urlId), where('email', '==', email));
            let querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                deleteClientiParticella(doc.id)
            });
            getCliente(email)
        } catch (e) {
            /* addDoc(collection(firebase.db, "errori"), {
                nome: 'Ricerca Cliente Particella',
                timestamp: serverTimestamp(),
                dati: e
              }); */
        }
    }

    const getCliente = async (email) => {
        try {
            const getFiles = collection(firebase.db, "clientiParticella");
            const q = query(getFiles, where('email', '==', email));
            let querySnapshot = await getDocs(q);
            if(querySnapshot.empty) {
                EliminaCliente(email)
            }
        } catch (e) {
            /* addDoc(collection(firebase.db, "errori"), {
                nome: 'Ricerca Cliente ',
                timestamp: serverTimestamp(),
                dati: e
              }); */
        }
    }

    

    getClienteParticella(email, urlId)


  return {
    
  };
};

export default EliminaClienteParticella
