//import { useState } from "react";
import getFirebase from "../firebase";
import { doc, getDocs, query, collection, where, deleteDoc  } from "firebase/firestore";
//import { getStorage  } from "firebase/storage";
import EliminaCliente from './EliminaCliente'

const EliminaClienteCondominio = async (email, urlId) => {
    const firebase = getFirebase();
    //const storage = getStorage();
    //const [risultato, setRisultato] = useState(false)

    
    

    const deleteClientiCondominio = async (id, urlId) => {
        try {
            await deleteDoc(doc(firebase.db, "clientiParticella", id));
            //alert('Cliente eliminato')
            //return  true 
            getClientiParticella(urlId)
        } catch (e) {
            /* addDoc(collection(firebase.db, "errori"), {
                nome: 'Elimina Cliente Condominio',
                timestamp: serverTimestamp(),
                dati: e
              }); */
            //return false
        } 
    } 
    
    /* const deleteParticella = async (id) => {
        try {
            await deleteDoc(doc(firebase.db, "particelle", id));
            //alert('Cliente eliminato')
            //return  true 
        } catch (e) {
            //console.log('c: '+e)
        } 
    } */ 

    const getClienteCondominio = async (email, urlId) => {
        try {
            const getData = collection(firebase.db, "clientiParticella");
            const q = query(getData, where('condominio', '==', urlId), where('email', '==', email));
            let querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                deleteClientiCondominio(doc.id, urlId)
            });
            getCliente(email)
        } catch (e) {
            /* addDoc(collection(firebase.db, "errori"), {
                nome: 'Ricerca Cliente Condominio',
                timestamp: serverTimestamp(),
                dati: e
              }); */
        }
    }

    /* const getParticella = async (urlId) => {
        try {
            let getData2 = collection(firebase.db, "particelle");
            let q2 = query(getData2, where('condominio', '==', urlId));
            let querySnapshot2 = await getDocs(q2);
            querySnapshot2.forEach((doc) => {
                deleteParticella(doc.id)
            });
        } catch (e) {
            //console.log('b: '+e)
        }
    } */


    const getClientiParticella = async (urlId) => {
        try {
            let getData = collection(firebase.db, "clientiParticella");
            let q = query(getData, where('condominio', '==', urlId));
            let querySnapshot = await getDocs(q);
            if(querySnapshot.empty) {
                // Per eliminare particelle vuote
                //getParticella(urlId)
            }
        } catch (e) {
            //console.log('a: '+e)
        }
    }

    const getCliente = async (email) => {
        try {
            const getFiles = collection(firebase.db, "clientiParticella");
            const q = query(getFiles, where('email', '==', email));
            let querySnapshot = await getDocs(q);
            if(querySnapshot.empty) {
                //
                // Elimina definitivamente il cliente
                //
                EliminaCliente(email)
            }
        } catch (e) {
            /* addDoc(collection(firebase.db, "errori"), {
                nome: 'Elimina Cliente ',
                timestamp: serverTimestamp(),
                dati: e
              }); */
        }
    }

    

    getClienteCondominio(email, urlId)


  
};

export default EliminaClienteCondominio
