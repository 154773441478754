//import { useState } from "react";
import getFirebase from "../firebase";
import { doc, getDocs, query, collection, where, deleteDoc } from "firebase/firestore";
import { getStorage, ref, deleteObject  } from "firebase/storage";
import EliminaCliente from './EliminaCliente'

const EliminaCondominio = async (urlId) => {
    const firebase = getFirebase();
    const storage = getStorage();
    //const [risultato, setRisultato] = useState(false)
    

    
    
    

    const deleteParticella = async (id) => {
        try {
            await deleteDoc(doc(firebase.db, "particelle", id));
            //alert('Cliente eliminato')
            //return  true 
        } catch (e) {
            /* addDoc(collection(firebase.db, "errori"), {
                nome: 'Elimina  Particella',
                timestamp: serverTimestamp(),
                dati: e
              }); */
            //return false
        } 
    }

    const deleteClientiParticella = async (id, email) => {
        try {
            await deleteDoc(doc(firebase.db, "clientiParticella", id));
            //alert('Cliente eliminato')
            //return  true 
        } catch (e) {
            /* addDoc(collection(firebase.db, "errori"), {
                nome: 'Elimina Cliente Particella',
                timestamp: serverTimestamp(),
                dati: e
              }); */
            //return false
        } 
        getCliente(email)
    }

    const deleteFileParticella = async (idCartella, id, nome) => {
        try {
            const deleteFile = ref(storage, idCartella+'/'+nome);
            await deleteDoc(doc(firebase.db, "filesClienti", id));
            deleteObject(deleteFile).then(() => {
              // File deleted successfully
            }).catch((e) => {
                /* addDoc(collection(firebase.db, "errori"), {
                    nome: 'Elimina File Particella',
                    timestamp: serverTimestamp(),
                    dati: e
                  }); */
            });
        } catch (e) {
            /* addDoc(collection(firebase.db, "errori"), {
                nome: 'Elimina File Particella',
                timestamp: serverTimestamp(),
                dati: e
              }); */
        } 
    }

    const deleteFileCondominio = async (idCartella, id, nome) => {
        try {
            const deleteFile = ref(storage, idCartella+'/'+nome);
            await deleteDoc(doc(firebase.db, "files", id));
            deleteObject(deleteFile).then(() => {
              // File deleted successfully
            }).catch((e) => {
                /* addDoc(collection(firebase.db, "errori"), {
                    nome: 'Elimina File Condominio',
                    timestamp: serverTimestamp(),
                    dati: e
                  }); */
            });
        } catch (e) {
            /* addDoc(collection(firebase.db, "errori"), {
                nome: 'Elimina File Condominio',
                timestamp: serverTimestamp(),
                dati: e
              }); */
        } 
    }

    const getFilesParticella = async (urlId) => {
        try {
            const getFiles = collection(firebase.db, "filesClienti");
            const q = query(getFiles, where('idCondominio', '==', urlId));
            let querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                deleteFileParticella('clienti/'+doc.data().idCartella, doc.id, doc.data().file)
            });
        } catch (e) {
            /* addDoc(collection(firebase.db, "errori"), {
                nome: 'Ricerca Files Condominio',
                timestamp: serverTimestamp(),
                dati: e
              }); */
        }
    }

    const getParticelle = async (urlId) => {
        try {
            const getFiles = collection(firebase.db, "particelle");
            const q = query(getFiles, where('condominio', '==', urlId));
            let querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                deleteParticella(doc.id)
            });
        } catch (e) {
            /* addDoc(collection(firebase.db, "errori"), {
                nome: 'Ricerca Particella',
                timestamp: serverTimestamp(),
                dati: e
              }); */
        }
    }

    const getClientiParticella = async (urlId) => {
        try {
            const getFiles = collection(firebase.db, "clientiParticella");
            const q = query(getFiles, where('condominio', '==', urlId));
            let querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                deleteClientiParticella(doc.id, doc.data().email)
            });
        } catch (e) {
            /* addDoc(collection(firebase.db, "errori"), {
                nome: 'Ricerca Clienti Particella',
                timestamp: serverTimestamp(),
                dati: e
              }); */
        }
    }

    const getCliente = async (email) => {
        try {
            const getCliente = collection(firebase.db, "clientiParticella");
            const q = query(getCliente, where('email', '==', email));
            let querySnapshot = await getDocs(q);
            if(querySnapshot.empty) {
                EliminaCliente(email)
            }
        } catch (e) {
            /* addDoc(collection(firebase.db, "errori"), {
                nome: 'Ricerca Cliente ',
                timestamp: serverTimestamp(),
                dati: e
              }); */
        }
    }

    const getFilesCondominio = async (urlId) => {
        try {
            const getFiles = collection(firebase.db, "files");
            const q = query(getFiles, where('idCartella', '==', urlId));
            let querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                deleteFileCondominio(urlId, doc.id, doc.data().file)
            });
        } catch (e) {
            /* addDoc(collection(firebase.db, "errori"), {
                nome: 'Ricerca Files Condominio',
                timestamp: serverTimestamp(),
                dati: e
              }); */
        }
    }

    const deleteCondominio = async (id) => {
        try {
            await deleteDoc(doc(firebase.db, "condomini", id));
            return  true 
            
            //console.log('a: '+risultato)
        } catch (e) {
            /* addDoc(collection(firebase.db, "errori"), {
                nome: 'Elimina Condominio',
                timestamp: serverTimestamp(),
                dati: e
              }); */
            return false
            
        } 
    }


    

    getFilesParticella(urlId)
    getFilesCondominio(urlId)
    getParticelle(urlId)
    getClientiParticella(urlId)
    deleteCondominio(urlId)


  return {
   
  };
};

export default EliminaCondominio
