import React, { useState } from "react";
import { useSpring, animated } from 'react-spring'

const Number = (numberEnd) => {
    let numero = 0
    const d = new Date();
    let anno = d.getFullYear();
    if(numberEnd.numberEnd === '40') numero = (anno-1980)
    if(numberEnd.numberEnd === '200') numero = 200
    if(numberEnd.numberEnd === '1000') numero = 8000
    const [flip, set] = useState(false)
    const { number } = useSpring({
      from: { number: 0 },
      number: numero,
      delay: 1000,
      config: {duration: 3000},
      onRest: () => set(!flip),
    })
  
    return <animated.div className=' numero text-center text-6xl font-bold py-3 text-yellow-500'>{number.to(n => n.toFixed(0))}</animated.div>
  }

export default Number