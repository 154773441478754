import React from "react";
import { getAuth, sendPasswordResetEmail, signInWithEmailAndPassword } from "firebase/auth";
import { addDoc, collection, serverTimestamp, setDoc } from "firebase/firestore";
import getFirebase from "./firebase";



//import getFirebase from "./firebase";
import useInput from "./useInput";

//import { signInWithEmailAndPassword } from "firebase/auth";

const SignInForm = () => {
  //const firebaseInstance = getFirebase();
  const email = useInput("");
  const password = useInput("");
  const verifica = useInput(false);
  const firebase = getFirebase();

  const signIn = async (event) => {
    event.preventDefault();
    const auth = getAuth();
    
    if(email.value===password.value && email.value){
      sendPasswordResetEmail(auth, email.value)
      .then(() => {
        alert('Gentile cliente sei al primo accesso. \n Ti abbiamo inviato un email per il cambio password')
      })
      .catch((error) => {
          //alert('d: '+error.message);
      });
    } else if(!verifica.value) {
      //Autenticazione di sessione
      /* setPersistence(auth, browserSessionPersistence)
        .then(() => {
          return signInWithEmailAndPassword(auth, email.value, password.value);
        })
        .catch((error) => {
          switch (error.code) {
            case 'auth/invalid-email':
              alert("Attenzione! L'email inserita non è valida");
              break;
            case 'auth/internal-error':
              alert("Attenzione! Le credenziali non sono corrette");
              break;
            case 'auth/user-not-found':
              alert("Attenzione! Le credenziali non sono corrette");
              break;
            case 'auth/wrong-password':
              alert("Attenzione! La password non è corretta");
              break;
            default:
              alert("Attenzione! L'autenticazione non è riuscita")
          }
        }); */

      //Autenticazione persistente sul device
      signInWithEmailAndPassword(auth, email.value, password.value)
      .then(() => {
        let amministratori = ['aaaa@aa.aa', 'visentini.n@gmail.com']
        if(!amministratori.includes(email.value)) {
          setDoc(collection(firebase.db, "autenticazioni", email.value), {
            timestamp: serverTimestamp()
          });
        }
        
          // Signed in 
          //const user = userCredential.user;
          //console.log("user", user);
          //alert("Welcome back!"+user.email);
          // ...
      })
      .catch((error) => {
          //const errorCode = error.code;
          //const errorMessage = error.message;
          //console.log("error", error);
          //alert(error.message);
          switch (error.code) {
            case 'auth/invalid-email':
              alert("Attenzione! L'email inserita non è valida");
              break;
            case 'auth/internal-error':
              alert("Attenzione! Le credenziali non sono corrette");
              break;
            case 'auth/user-not-found':
              alert("Attenzione! Le credenziali non sono corrette");
              break;
            case 'auth/wrong-password':
              alert("Attenzione! La password non è corretta");
              break;
            default:
              alert("Attenzione! L'autenticazione non è riuscita")
          }
      }); 
    }

  };

  return (
    <form  className='p-2' id='signIn'  onSubmit={signIn}>
      <div className="">
        
        <div className="flex flex-wrap ">
          <div className="w-full px-2 md:w-2/4">
            <label className="block mb-1">Email</label>
            <input className="w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline" type="email" {...email}/>
          </div>
          <div className="w-full px-2 md:w-2/4">
            <label className="block mb-1">Password</label>
            <input className="w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline" type="password" {...password}/>
          </div>
        </div>
        <div className='hidden'>
          <label className="text-gray-700">
            <input type="checkbox" value="false" {...verifica}/>
            <span className="ml-1">Verifica</span>
          </label>
        </div>
        <button type="submit" className='h-10 px-5 mt-4 ml-2 text-indigo-100 transition-colors duration-150 bg-indigo-700 rounded-lg focus:shadow-outline hover:bg-indigo-800'>Accedi</button>
      </div>
    </form>
  );
};

export default SignInForm;


