import React from "react";
import { Helmet } from 'react-helmet-async';
import { Link } from "react-router-dom";
import Footer from './Footer'
import Header from './Header'
import CookieConsent from "react-cookie-consent";

//import { useSpring, animated } from 'react-spring'

function Servizi(){
  


  

  return (
    <div className=''>
        <Helmet>
            <html lang="it" />
            <title>Studio Ballon - Servizi</title>
            <meta name="description" content="Studio Ballon - Studio di architettura, Gestioni Immobiliari" />
            <meta name="theme-color" content="#E6E6FA" />
            <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1'/>
            <meta property="og:locale" content="it_IT"/>
            <meta property="og:title" content="Studio Ballon - Servizi"/>
            <meta property="og:description" content="Studio Ballon - Montebelluna (TV) - Studio di architettura, Gestioni Immobiliari"/>
            <meta property="og:url" content="https://studioballon.it/servizi/"/>
            <meta property="og:site_name" content="studioballon.it"/>
            <meta property="article:modified_time" content="2022-03-21T10:32:09+00:00"/>
            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:label1" content="Tempo di lettura stimato"/>
            <meta name="twitter:data1" content="3 minuti"/>
        </Helmet>
        
        <Header />
        <div className='md:pt-48 py-20  bg-cover text-white'  style={{ backgroundImage: `url("servizi2.jpg")` }}>
            <h1 className='md:text-8xl text-5xl ObjectiveBold text-center'>UN SERVIZIO A 360°</h1>
            <div className="mb-10 mt-20 overflow-hidden">
                <div className="h-36 larghMin flex justify-center transform animate-translateScrittaMD z-50">
                    <div className="w-72 h-full text-center border-4 border-white rounded-bl-3xl p-3 bg-gray-900 bg-opacity-20">
                        <h3 className=" uppercase text-xl font-bold">progettazione</h3>
                        <p className="italic text-lg font-semibold">Pratiche edilizie e catastali, Direzione lavori, Coordinatore della sicurezza</p>
                    </div>
                    <div className="bg-white h-1 w-9 my-auto"></div>
                    <div className="triangoloDestro my-auto"></div>
                    <div className="w-72 h-full text-center border-4 border-white rounded-bl-3xl p-3 bg-gray-900 bg-opacity-20">
                        <h3 className=" uppercase text-xl font-bold">gestione</h3>
                        <p className="italic text-lg font-semibold">Contabile e fiscale, Esecuzione delle delibere assembleari, Sinistri e Liti</p>
                    </div>
                    <div className="bg-white h-1 w-9 my-auto"></div>
                    <div className="triangoloDestro my-auto"></div>
                    <div className="w-72 h-full text-center border-4 border-white rounded-bl-3xl p-3 bg-gray-900 bg-opacity-20">
                        <h3 className=" uppercase text-xl font-bold">Manutenzione</h3>
                        <p className="italic text-lg pt-6 font-semibold">Ordinaria e straordinaria</p>
                    </div>
                    <div className="bg-white h-1 w-9 my-auto"></div>
                    <div className="triangoloDestro my-auto"></div>
                    <div className="w-72 h-full text-center border-4 border-white rounded-bl-3xl p-3 bg-gray-900 bg-opacity-20">
                        <h3 className=" uppercase text-xl font-bold">ristrutturazione</h3>
                        <p className="italic text-lg font-semibold">Bonus fiscali, Edilizia, Riqualificazione energetica e delle facciate</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="py-20 ObjectiveRegular text-justify hidden lg:block">
            <div className=" shadow-xl w-4/5 mx-auto bg-white rounded-2xl transform -skew-x-6 flex">
                <div className=" lg:w-3/5 md:w-4/5 p-5 transform skew-x-6 ">
                    <h2 className="ObjectiveBold text-3xl p-5 uppercase text-center">Amministrazione immobiliare</h2>
                    <p className="px-7  py-2">Ai nostri clienti offriamo tutti i servizi necessari ad una vita del condominio serena e sicura.</p>
                    <ul className=" list-disc pl-14 pr-7 ObjectiveMedium">
                        <li>Redazione di documenti di condominio e tabelle millesimali</li>
                        <li>Interventi di manutenzione e ristrutturazione tramite detrazioni fiscali (es. Super Buonus 110%)</li>
                        <li>Gestione contabile e fiscale</li>
                        <li>Azioni di recupero credito</li>
                        <li>Convocazione annua dell'assemblea ed esecuzione delle delibere</li>
                        <li>Rappresentanza legale</li>
                    </ul>
                    <p className="px-7  py-2">L'esperienza professionale ed umana maturata negli anni nel complesso settore dell'amministrazione, ci consente di intervenire con umanità nei problemi che possono sorgere tra condomini, nella risoluzione bonaria dei conflitti e nella gestione delle assemblee.</p>
                    <p className="px-7 py-2">La competenza tecnica acquisita ci permette di individuare e risolvere i numerosi vizi costruttivi che affliggono il nostro sistema edilizio al fine di mantenere in efficienza il condominio al più basso costo possibile, oltre a sfruttare tutte le detrazioni previste al fine di ristrutturare gli immobili per migliorarne il valore.</p>
                 </div>
                <div className=" md:w-1/5 lg:w-2/5 bg-cover border-8 border-white "   style={{ backgroundImage: `url("condomini2.jpg")` }}></div>
            </div>
            <div className=" shadow-lg w-4/5 mx-auto bg-white rounded-2xl transform -skew-x-6 flex mt-20">
                <div className=" md:w-1/5 lg:w-2/5 bg-cover border-8 border-white "   style={{ backgroundImage: `url("architettura.jpg")` }}></div>
                <div className="md:w-4/5 lg:w-3/5 p-5 transform skew-x-6 ">
                    <h2 className="ObjectiveBold text-3xl p-5 uppercase text-center">Studio di Architettura</h2>
                    <p className="px-7 py-2">Grazie ai nostri servizi il cliente verrà seguito in tutto l’iter di realizzazione dell’opera: dall’idea progettuale alla direzione lavori fino alla realizzazione dell’opera.</p>
                    <ul className=" list-disc pl-14 pr-7 ObjectiveMedium">
                        <li>Progettazione architettonica e direzione lavori di edifici residenziali, commerciali ed industriali</li>
                        <li>Coordinatore per la sicurezza in fase di progettazione/esecuzione</li>
                        <li>Rilievi topografici</li>
                        <li>Visure catastali</li>
                        <li>Attestati di prestazione energetica</li>
                        <li>Perizie di stima</li>
                        <li>Pratiche di successione</li>
                        <li>CTP nelle controversie giudiaziarie in ambito edilizio e condominiale</li>
                        <li>CTU per tribunali</li>
                    </ul>
                </div>
            </div>
        </div>

        <div className="py-20 ObjectiveRegular text-justify  lg:hidden">
            <div className=" shadow-xl  bg-white rounded-2xl  flex ml-8 md:ml-20">
                <div className="md:w-3/5 p-5 ">
                    <h2 className="ObjectiveBold text-3xl p-5 uppercase text-center">Amministrazione immobiliare</h2>
                    <p className="md:px-5  py-2">Ai nostri clienti offriamo tutti i servizi necessari ad una vita del condominio serena e scura.</p>
                    <ul className=" list-disc pl-10 md:pl-14 md:pr-7 ObjectiveMedium">
                        <li>Redazione di documenti di condominio e tabelle millesimali</li>
                        <li>Interventi di manutenzione e ristrutturazione tramite detrazioni fiscali (es. Super Buonus 110%)</li>
                        <li>Gestione contabile e fiscale</li>
                        <li>Azioni di recupero credito</li>
                        <li>Convocazione annua dell'assemblea ed esecuzione delle delibere</li>
                        <li>Rappresentanza legale</li>
                    </ul>
                    <p className="md:px-5  py-2">L'esperienza professionale ed umana maturata negli anni nel complesso settore dell'amministrazione, ci consente di intervenire con umanità nei problemi che possono sorgere tra condomini, nella risoluzione bonaria dei conflitti e nella gestione delle assemblee.</p>
                    <p className="md:px-5 py-2">La competenza tecnica acquisita ci permette di individuare e risolvere i numerosi vizi costruttivi che affliggono il nostro sistema edilizio al fine di mantenere in efficienza il condominio al più basso costo possibile, oltre a sfruttare tutte le detrazioni previste al fine di ristrutturare gli immobili per migliorarne il valore.</p>
                 </div>
                <div className="w-2/5 bg-cover border-t-8 border-b-8 border-white hidden md:block lg:hidden"   style={{ backgroundImage: `url("condomini.jpg")` }}></div>
            </div>
            <div className=" shadow-lg bg-white rounded-2xl  flex mt-20 mr-8 md:mr-20">
                <div className="w-2/5 bg-cover border-t-8 border-b-8 border-white hidden md:block lg:hidden"   style={{ backgroundImage: `url("architettura.jpg")` }}></div>
                <div className="md:w-3/5 p-5">
                    <h2 className="ObjectiveBold text-3xl p-5 uppercase text-center">Studio di Architettura</h2>
                    <p className="md:px-5 py-2">Grazie ai nostri servizi il cliente verrà seguito in tutto l’iter di realizzazione dell’opera: dall’idea progettuale alla direzione lavori fino alla realizzazione dell’opera.</p>
                    <ul className=" list-disc pl-10 md:pl-14 md:pr-7 ObjectiveMedium">
                        <li>Progettazione architettonica e direzione lavori di edifici residenziali, commerciali ed industriali</li>
                        <li>Coordinatore per la sicurezza in fase di progettazione/esecuzione</li>
                        <li>Rilievi topografici</li>
                        <li>Visure catastali</li>
                        <li>Attestati di prestazione energetica</li>
                        <li>Perizie di stima</li>
                        <li>Pratiche di successione</li>
                        <li>CTP nelle controversie giudiaziarie in ambito edilizio e condominiale</li>
                        <li>CTU per tribunali</li>
                    </ul>
                </div>
            </div>
        </div>
        

        
        <Footer />
        <CookieConsent
            acceptOnScroll={true}
            acceptOnScrollPercentage={50}
            onAccept={(byScroll) => {
            }}
            location="bottom"
            buttonText="OK"
            cookieName="myAwesomeCookieName2"
            style={{ background: "#2B373B" }}
            buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
            expires={150}
            >
            <span style={{ fontSize: "12px" }}>Questo sito utilizza cookie tecnici, analitici e di profilazione, anche di terze parti, necessari rispettivamente, a consentire alcune funzionalità fondamentali per il sito, ad effettuare misurazioni delle performances del sito stesso o a fornirti indicazioni promozionali in linea con i tuoi interessi.

Per avere maggiori informazioni e scoprire come negare in tutto o in parte i tuoi consensi, consulta la <Link to='/PrivacyCookie' className='underline'>cookie policy</Link>.</span>
        </CookieConsent>
        
        
    </div>
  );
}

export default Servizi;