import React, { useState, useEffect } from "react";
import { Helmet } from 'react-helmet-async';
//import { Helmet } from "react-helmet";
import SignOutButton from "../SignOutButton";
import EliminaParticella from './EliminaParticella'
import EliminaClienteParticella from './EliminaClienteParticella'
import Header from '../Header'
import Footer from '../Footer'
//import AddClienteForm from "./AddClienteForm";
//import ListaFiles from "./ListaFiles";
import getFirebase from "../firebase";
//import { getAuth,  } from "firebase/auth";
import { doc, getDoc, getDocs, query, orderBy, addDoc, collection, where, deleteDoc, updateDoc, setDoc } from "firebase/firestore";
import { useHistory } from "react-router-dom";
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject  } from "firebase/storage";
import { getFunctions, httpsCallable } from "firebase/functions";
//import { getAuth, sendPasswordResetEmail } from "firebase/auth";

function GestioneParticella() {
    let url = new URL(window.location.href);
    let urlIdParticella = url.searchParams.get("id");
    let urlIdCondominio = url.searchParams.get("idc");
    let urlAnno = url.searchParams.get("anno");
    const d = new Date();
    let anno = d.getFullYear();

    let modal = document.getElementById("modal");
    const [btnNome, setBtnNome] = useState('');
    const [btnId, setBtnId] = useState('');
    const [btnEmail, setBtnEmail] = useState('')
    const [btnTitolo, setBtnTitolo] = useState('');
    const [btnTesto, setBtnTesto] = useState('');

    /* const [nome, setNome] = useState('');
    const [cognome, setCognome] = useState(''); */
    const [email, setEmail] = useState('');
    /* const [pec, setPec] = useState('');
    const [tel, setTel] = useState('') */
    const [particella, setParticella] = useState('');
    /* const [tipo, setTipo] = useState('');
    const [tipoP, setTipoP] = useState('');
    const [tipoI, setTipoI] = useState('');
    const [tipoE, setTipoE] = useState('') */
    const [nomeCon, setNomeCon] = useState('');

    const history = useHistory();
    const firebase = getFirebase();
    const storage = getStorage();
    const utente = firebase.auth.currentUser;
    const functions = getFunctions();

    const [file, setFile] = useState(null);
    const [nessunRisultato, setNessunRisultato] = useState('');
    const [nessunRisultatoReverse, setNessunRisultatoReverse] = useState('');
    const [annoSuc, setAnnoSuc] = useState('');
    const [annoPrec, setAnnoPrec] = useState('');
    const [allFiles, setAllFiles] = useState([]);
    const [nomeFile, setnomeFile] = useState('Carica un file (max 5MB)');
    const [allCognome, setAllCognome] = useState([])
    const [allClienti, setAllClienti] = useState([]);
    const [allProprietari, setAllProprietari] = useState([])
    const [allEntrambi, setAllEntrambi] = useState([])
    const [allInquilini, setAllInquilini] = useState([])

    const [urlFile, setUrlFile] = useState('')

    /* const [redNome, setRedNome] = useState('')
    const [redLabelNome, setRedLabelNome] = useState('')
    const [redCognome, setRedCognome] = useState('')
    const [redLabelCognome, setRedLabelCognome] = useState('')
    const [redEmail, setRedEmail] = useState('')
    const [redLabelEmail, setRedLabelEmail] = useState('')
    const [redTipo, setRedTipo] = useState('')
    const [redLabelTipo, setRedLabelTipo] = useState('') 
    const [redGetEmail, setRedGetEmail] = useState('')
    const [redGetLabelEmail, setRedGetLabelEmail] = useState('')
    const [redGetTipo, setRedGetTipo] = useState('')
    const [redGetLabelTipo, setRedGetLabelTipo] = useState('') */
    /* const [redGetTipoCognome, setRedGetTipoCognome] = useState('')
    const [redGetCognome, setRedGetCognome] = useState('')
    const [redGetLabelCognome, setRedGetLabelCognome] = useState('') */ 
/* 
    const [cssLabelNuovoCliente, setCssLabelNuovoCliente] = useState('text-white bg-indigo-500')
    const [cssLabelGiaCliente, setCssLabelGiaCliente] = useState('text-indigo-500 bg-white') */



    if(!utente) {
      history.replace("/");
    } else {
      //setCurrentUser(utente.email)
    }
    //
    //Cambio SELECT con ValueDefault


    const Capitalize = (prop) => {
      let str = prop.toLowerCase();
      let arr = str.split(" ");
      for (var i = 0; i < arr.length; i++) {
          arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }
      return (arr.join(" ")).trim();
    }
    





    const getParticella = async () => {
        let part = ''
        try {
          const docRef = doc(firebase.db, "particelle", urlIdParticella);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            setNomeCon(docSnap.data().nomeCondominio);
            setParticella(docSnap.data().particella)
            part = docSnap.data().particella
          }
          else {
            alert("Nessuna corrispondenza");
            history.replace('/amministratori/home');
          }
        } catch (e) {
          //alert("Errore del database ", e);
          /* addDoc(collection(firebase.db, "errori"), {
            nome: 'Ricerca Particella',
            timestamp: serverTimestamp(),
            dati: e
          }); */
        }
        window.scrollTo(0, 0)
        listaFiles(urlAnno, part);
        listaClienti();
    }



    const listeClientiPerTipo = async (email, tipo, particella, idClientiParticella) => {
      const getClienti = collection(firebase.db, "clienti");
      const q = query(getClienti, where('email', '==', email));
      let querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        //setAllClienti(oldArray => [...oldArray, {id: doc.id, particella: doc.data().particella, nome: doc.data().nome, email: doc.data().email, pec: doc.data().pec, cognome: doc.data().cognome, tipo: doc.data().tipo}]);
        switch (tipo) {
          case "Entrambi":
            setAllEntrambi(oldArray => [...oldArray, {idClientiParticella: idClientiParticella, id: doc.id, particella: particella, nome: doc.data().nome, email: doc.data().email, pec: doc.data().pec, cognome: doc.data().cognome, tipo: tipo, tel: doc.data().tel}]);
            break;
          case "Proprietario":
            setAllProprietari(oldArray => [...oldArray, {idClientiParticella: idClientiParticella, id: doc.id, particella: particella, nome: doc.data().nome, email: doc.data().email, pec: doc.data().pec, cognome: doc.data().cognome, tipo: tipo, tel: doc.data().tel}]);
            break;
          case "Inquilino":
            setAllInquilini(oldArray => [...oldArray, {idClientiParticella: idClientiParticella, id: doc.id, particella: particella, nome: doc.data().nome, email: doc.data().email, pec: doc.data().pec, cognome: doc.data().cognome, tipo: tipo, tel: doc.data().tel}]);
            break;
        }
        setAllClienti(oldArray => [...oldArray, {idClientiParticella: idClientiParticella, id: doc.id, particella: particella, nome: doc.data().nome, email: doc.data().email, pec: doc.data().pec, cognome: doc.data().cognome, tipo: tipo, tel: doc.data().tel}]);
      });
    }


    const listaClienti = async () => {
      setAllClienti([]);
      setAllEntrambi([])
      setAllInquilini([])
      setAllProprietari([])
      try {
        const getClienti = collection(firebase.db, "clientiParticella");
        const q = query(getClienti, where('condominio', '==', urlIdCondominio), where('particella', '==', urlIdParticella));
        let querySnapshot = await getDocs(q);
        if(querySnapshot.empty) {
          document.getElementById('numeroClienti').style.display='block'
        } else {
          document.getElementById('numeroClienti').style.display='none'
        }
        querySnapshot.forEach((doc) => {
          setEmail(doc.data().email)
          listeClientiPerTipo(doc.data().email, doc.data().tipo, doc.data().nomePart, doc.id)
        });
      } catch(e) {
        //console.log(e)
        //alert("Errore del database: "+e);
        
      }
    }
  
    const displayH1Clienti = () => {
      if(allEntrambi.length > 0) {
        document.getElementById('allEntrambi').style.display = 'block'
        document.getElementById('allEntrambiDiv').style.display = 'grid'
      }
      if(allProprietari.length > 0) {
        document.getElementById('allProprietari').style.display = 'block'
        document.getElementById('allProprietariDiv').style.display = 'grid'
      }
      if(allInquilini.length > 0) {
        document.getElementById('allInquilini').style.display = 'block'
        document.getElementById('allInquiliniDiv').style.display = 'grid'
      }
    }



    /* const addCliente = async (event) => {
        event.preventDefault();
        setRedTipo('')
        setRedEmail('')
        setRedNome('')
        setRedCognome('')

        setRedLabelTipo('')
        setRedLabelEmail('')
        setRedLabelNome('')
        setRedLabelCognome('')

        const nome = Capitalize(event.target[0].value);
        const cognome = Capitalize(event.target[1].value);
        const email = (event.target[2].value.toLowerCase()).trim();
        const pec = (event.target[3].value.toLowerCase()).trim();
        const tel = event.target[4].value;
        const tipo = Capitalize(event.target[5].value);

        const getCliente = collection(firebase.db, "clienti");
        const q = query(getCliente,  where('email', '==', email));
        let querySnapshot = await getDocs(q);
        if(querySnapshot.empty) {
            if(nome && cognome && email && tipo != '' ){
                try {
                    const docRef = await addDoc(collection(firebase.db, "clienti"), {
                        nome: nome,
                        cognome: cognome,
                        email: email,
                        pec: pec,
                        tel: tel,
                        condominio: urlIdCondominio,
                        nomeCon: nomeCon,
                    });
                    const docRef2 = await addDoc(collection(firebase.db, "clientiParticella"), {
                        email: email,
                        particella: urlIdParticella,
                        nomePart: particella,
                        condominio: urlIdCondominio,
                        nomeCon: nomeCon,
                        tipo: tipo,
                    });
                    document.getElementById("formNuovo_Cliente").reset();
                    listaClienti();
                    //listaClienti();
                    const createUser = httpsCallable(functions, 'createUser');

                    const user = {
                        email: email,
                        password: email
                    };

                    createUser(user)
                    .then((result) => {
                        //console.log(result)
                        const actionCodeSettings = {
                            url: 'https://studioballon.it/'
                        };
                        const auth = getAuth();
                        sendPasswordResetEmail(auth, email, actionCodeSettings)
                        .then((userCredential) => {
                        })
                        .catch((error) => {
                          //console.log(error)
                        });
                        })
                .catch((e) => {
                    });
                } catch (e) {
                }
            } else {
                if(!nome) {setRedNome('bg-red-50 border border-red-500')}
                if(!cognome) {setRedCognome('bg-red-50 border border-red-500')}
                if(!email) {setRedEmail('bg-red-50 border border-red-500')}
                if(!tipo) {setRedTipo('bg-red-50 border border-red-500')}

                if(!nome) {setRedLabelNome('text-red-500')}
                if(!cognome) {setRedLabelCognome('text-red-500')}
                if(!email) {setRedLabelEmail('text-red-500')}
                if(!tipo) {setRedLabelTipo('text-red-500')}
            }
        } else {
        document.getElementById("formNuovo_Cliente").reset();
        alert('Attenzione! Un cliente con la stessa email è già stato registrato')
        }
    }; */ 

    const existClienteCognome = async (email, nome, cognome) => {
      const getCliente = collection(firebase.db, "clientiParticella");
      const q = query(getCliente,  where('email', '==', email), where('particella', '==', urlIdParticella));
      let querySnapshot = await getDocs(q);
      if(querySnapshot.empty) {
        setAllCognome(oldArray => [...oldArray, {nome: nome, cognome: cognome, email: email, attivo: true}]);
      } else {
        setAllCognome(oldArray => [...oldArray, {nome: nome, cognome: cognome, email: email, attivo: false}]);
      }
    }

    /* const getClienteCognome = async (cog, event) => {
      setAllCognome([])
      setRedGetCognome('')
      setRedGetLabelCognome('')
      let cognome = ''
      if(event) {
        event.preventDefault()
        cognome = Capitalize((event.target[0].value.toLowerCase().trim()));
      }
      if(cog) {
        cognome = cog
      }
      if(cognome) {
        const getCliente = collection(firebase.db, "clienti");
        const q = query(getCliente,  where('cognome', '==', cognome));
        let querySnapshot = await getDocs(q);
        if(querySnapshot.empty) {
          alert('Nessun cliente trovato')
        } else {
          querySnapshot.forEach((doc) => {
            existClienteCognome(doc.data().email, doc.data().nome, cognome)
          })
          document.getElementById("formGia_ClienteCognome").reset();
        }
      } else {
        setRedGetCognome('bg-red-50 border border-red-500')
        setRedGetLabelCognome('text-red-500')
      }
      document.getElementById("formGia_ClienteCognome").reset();
      setRedGetTipoCognome('')
    } */
    

    /* const addClienteCognome = async (cog, email, event) => {
      event.preventDefault()
      const tipo = Capitalize(event.target[0].value);
      if(tipo) {
        const docRef2 = await addDoc(collection(firebase.db, "clientiParticella"), {
          email: email,
          particella: urlIdParticella,
          nomePart: particella,
          condominio: urlIdCondominio,
          nomeCon: nomeCon,
          tipo: tipo,
        });
        listaClienti();
        getClienteCognome(cog)
        setRedGetTipoCognome('')
      } else {
        setRedGetTipoCognome('bg-red-50 border border-red-500')
      }
      
    } */

    /* const addClienteEmail = async (event) => {
        event.preventDefault()
        const email = (event.target[0].value.toLowerCase().trim());
        const tipo = Capitalize(event.target[1].value);
        if(email  && tipo != '') {
            const getCliente = collection(firebase.db, "clientiParticella");
            const q = query(getCliente,  where('email', '==', email),  where('nomePart', '==', particella),  where('condominio', '==', urlIdCondominio));
            let querySnapshot = await getDocs(q);
            if(!querySnapshot.empty) {
                alert('Attenzione! \nQuesto cliente è già presente in questa particella catastale')
            } else {
                const getCliente2 = collection(firebase.db, "clienti");
                const q2 = query(getCliente2,  where('email', '==', email));
                let querySnapshot2 = await getDocs(q2);
                if(querySnapshot2.empty) {
                    alert('Attenzione! \nQuesto cliente non è ancora stato registrato')  
                } else {
                    const docRef2 = await addDoc(collection(firebase.db, "clientiParticella"), {
                        email: email,
                        particella: urlIdParticella,
                        nomePart: particella,
                        condominio: urlIdCondominio,
                        nomeCon: nomeCon,
                        tipo: tipo,
                    });
                    document.getElementById("formGia_ClienteEmail").reset();
                    listaClienti();
                }
            }
        } else {
            if(!email) {setRedGetEmail('bg-red-50 border border-red-500')}
            if(!tipo) {setRedGetTipo('bg-red-50 border border-red-500')}
            if(!email) {setRedGetLabelEmail('text-red-500')}
            if(!tipo) {setRedGetLabelTipo('text-red-500')}
        }

    } */

    const eliminaParticella = () => {
      if(EliminaParticella(urlIdCondominio,  urlIdParticella, particella)) {
        alert("La particella è stata eliminata!")
        //history.goBack()
        history.replace("/amministratori/condominio?id="+urlIdCondominio+'&anno='+anno)
      } else {
        alert("Impossibile eliminare il cliente! Contattare l'amministratore di sistema")
      }
    } 
    
    const eliminaClienteParticella = (email, urlId) => {
      if(EliminaClienteParticella(email, urlId)) {
        alert("Il cliente è stato eliminato!")
        //history.goBack()
        //history.push("/amministratori/home")
        getParticella()
      } else {
        alert("Impossibile eliminare il cliente! Contattare l'amministratore di sistema")
      }
      modal.style.display = "none";
    }
    

    /* const getSelection = () => {
      if(tipo === 'Proprietario') {
        return(
          <select id='tipo' className="w-72 mx-auto h-8 px-6 text-sm text-indigo-100 transition-colors duration-150 bg-indigo-700 rounded-lg focus:shadow-outline hover:bg-indigo-800">
            <option id='proprietario' value='proprietario'>Proprietario</option>
            <option value={email}>Personale</option>
          </select>
        )
      }else if(tipo === 'Inquilino') {
        return(
          <select id='tipo' className="w-72 mx-auto h-8 px-6 text-sm text-indigo-100 transition-colors duration-150 bg-indigo-700 rounded-lg focus:shadow-outline hover:bg-indigo-800">
            <option id='inquilino' value='inquilino'>Inquilino</option>
            <option value={email}>Personale</option>
          </select>
        )
      } else {
        return(
          <select id='tipo' className="w-72 mx-auto h-8 px-6 text-sm text-indigo-100 transition-colors duration-150 bg-indigo-700 rounded-lg focus:shadow-outline hover:bg-indigo-800">
            <option id='proprietario' value='proprietario'>Proprietario</option>
            <option id='inquilino' value='inquilino'>Inquilino</option>
            <option value={email}>Personale</option>
          </select>
        )
      }
    }  */


    const showModal = (tipo, nome, id) => {
      if(tipo === 'file') {
        document.getElementById("btnFile").style.display = "block";
        document.getElementById("btnParticella").style.display = "none";
        document.getElementById("btnCliente").style.display = "none";
        setBtnNome(nome);
        setBtnId(id);
        setBtnTitolo('Eliminazione file');
        setBtnTesto('Sei sicuro di eliminare il file? Una volta eliminato il file non potrà essere recuperato!')
      } else if(tipo === 'particella') {
        document.getElementById("btnFile").style.display = "none";
        document.getElementById("btnParticella").style.display = "block";
        document.getElementById("btnCliente").style.display = "none";
        setBtnTitolo('Eliminazione particella');
        setBtnTesto('Sei sicuro di eliminare la particella? Una volta eliminata, tutti i dati e i file della particella non potranno più essere recuperati!')
      } else if(tipo === 'cliente') {
        document.getElementById("btnFile").style.display = "none";
        document.getElementById("btnParticella").style.display = "none";
        document.getElementById("btnCliente").style.display = "block";
        setBtnNome(nome);
        setBtnEmail(email)
        setBtnTitolo('Eliminazione cliente dalla particella');
        setBtnTesto('Sei sicuro di eliminare il cliente dalla particella? Una volta eliminato, tutti i dati e i file del cliente per questa particella non potranno più essere recuperati!')
    }
      modal.style.display = "block";
    }




    const listaFiles = async (propAnno, propParticella) => {
      try {
        //const d = new Date();
        //let anno = d.getFullYear();
        if(propAnno >= (new Date().getFullYear())) {
          setAnnoSuc('hidden');
          setAnnoPrec('');
        }
        if(propAnno !== urlAnno) {
          history.replace('/amministratori/gestioneparticella?id='+urlIdParticella+'&anno='+propAnno+'&idc='+urlIdCondominio);
        }
        //history.push('/amministratori/gestionecliente?id='+urlId+'&anno='+prop+'&nc='+nomeCondominio+'&idc='+urlIdC+'&p='+urlParticella);
        setAllFiles([]);
        
        let inizioAnno = new Date( propAnno, 1 - 1, 1);
        let fineAnno = new Date( propAnno, 12 - 1, 31);
        const getFiles = collection(firebase.db, "filesClienti");
        let q = query(getFiles, where('idCartella', '==', encodeURIComponent(urlIdCondominio+propParticella)), where('data', '>=', inizioAnno.getTime()), where('data', '<=', fineAnno.getTime()), orderBy("data", "desc"))
        try {
            let querySnapshot = await getDocs(q);
            if(!querySnapshot.empty) {
              setNessunRisultato('hidden');
              setNessunRisultatoReverse('')
            } else {
              setNessunRisultato('');
            } 
            if(querySnapshot.empty && propAnno === (new Date().getFullYear())) {
              setNessunRisultatoReverse('hidden')
            }
            if(querySnapshot.empty && propAnno < (new Date().getFullYear())) {
              setAnnoPrec('hidden');
              setAnnoSuc('');
            }
            if(!querySnapshot.empty && propAnno < (new Date().getFullYear())) {
              setAnnoSuc('');
              setAnnoPrec('');
            }
            querySnapshot.forEach((doc) => {
            if(doc.data().data === undefined) {alert('Nessun file')}
            
            let dat = new Date(doc.data().data);
            const options = { year: 'numeric', month: 'long', day: 'numeric' };

            getDownloadURL(ref(storage, 'clienti/'+urlIdCondominio+encodeURIComponent(propParticella)+'/'+doc.data().file))
                .then((url) => {
                setUrlFile(url)
                setUrlFile((state) => {
                    //console.log(state)
                    setAllFiles(oldArray => [...oldArray, {url: state, id: doc.id, nome: doc.data().file, tipo: doc.data().tipo, data: dat.toLocaleDateString('it-IT', options)}]);
                    //return state;
                });
                })
            
            //setAllFiles(oldArray => [...oldArray, {id: doc.id, nome: doc.data().file, tipo: doc.data().tipo, data: dat.toLocaleDateString('it-IT', options)}]);
            
            });
        } catch (e) {
          /* addDoc(collection(firebase.db, "errori"), {
            nome: 'Lista Files',
            timestamp: serverTimestamp(),
            dati: e
          }); */
        }
        
      } catch (e) {
        /* addDoc(collection(firebase.db, "errori"), {
          nome: 'Lista Files',
          timestamp: serverTimestamp(),
          dati: e
        }); */
      }
    }; 
    
  
    
    const eliminaFile = async (nome,id) => {
      try {
        const deleteFile = ref(storage, 'clienti/'+encodeURIComponent(urlIdCondominio+particella)+'/'+nome);
        await deleteDoc(doc(firebase.db, "filesClienti", id));
        deleteObject(deleteFile).then(() => {
          // File deleted successfully
        }).catch((e) => {
          //alert("Errore del database: "+e);
        });
      } catch (e) {
        //alert("Errore del database: "+e);
      }
      setAllFiles([]);
      listaFiles(urlAnno, particella);
      //listaClienti();
      modal.style.display = "none";
    }; 

    const onFileChange = (e) => {
      const reader = new FileReader();
      let file = e.target.files[0]; 
      if (file && file.size < 5000000) {
        reader.onload = () => {
          if (reader.readyState === 2) {
            setFile(file);
            setnomeFile(file.name);
          }
        };
        reader.readAsDataURL(e.target.files[0]);
      
      } else if(file && file.size >= 5000000) {
        alert('Il file è troppo grande!');
        setFile(null);
        setnomeFile('Carica un file (max 5MB)');
      } else {
        setFile(null);
        setnomeFile('Carica un file (max 5MB)');
      }
    }; 

    const uploadToFirebase = () => {  
      if (file && document.getElementById('tipo').value !== '') {
        let nomed = String(Date.now())+file.name
        const fileRef = ref(storage, 'clienti/'+encodeURIComponent(urlIdCondominio+particella)+'/'+nomed);
        uploadBytes(fileRef, file).then((snapshot) => {
          const addLinkFile = async () => {
            const docRef = await addDoc(collection(firebase.db, "filesClienti"), {
              idCartella: encodeURIComponent(urlIdCondominio+particella),
              idParticella: urlIdParticella,
              idCondominio: urlIdCondominio,
              tipo: document.getElementById('tipo').value,
              file: nomed,
              data: Date.now()
            });
          };
          addLinkFile(); 
          alert('Il file è stato caricato!');
          setFile(null);
          setnomeFile('Carica un file (max 5MB)');
          setAllFiles([]);
          listaFiles(urlAnno, particella);
          invioMail(nomed, document.getElementById('tipo').value)
          
        });
      } else {
          if(!file) {
            alert("Devi prima selezionare un file");
          }
          if(document.getElementById('tipo').value === '') {
            alert("Devi prima selezionare il tipo file");
          }
        
      }
    }; 

    const invioMail = (nome, tipo) => {
      let allClientiSingle = []
      let allClientiSingleArray = []
      allClienti.forEach((item) => {
        if(!allClientiSingle.includes(item.email))
        {
          let tipoC = Capitalize(tipo)
          let tipoItemC = Capitalize(item.tipo)
          if(tipoItemC === 'Entrambi' || tipoC === tipoItemC || tipoC === 'Entrambi') {
            allClientiSingleArray.push({email: item.email, nome: item.nome, cognome: item.cognome})
            allClientiSingle.push(item.email)
          }
        }
      })
      allClientiSingleArray.forEach((item) => {
        sendEmail(item.email, item.nome, item.cognome, allClientiSingleArray.length) 
      })
    }
    const sendEmail = async ( email, nome, cognome, nuoveEmail) => {
      let today = new Date();
      let date = today.getDate()+'-'+(today.getMonth()+1)+'-'+today.getFullYear()
      const docRef = doc(firebase.db, "sendgrid", date);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        if((docSnap.data().numero+nuoveEmail) < 99) {
          const docRef = await addDoc(collection(firebase.db, "mail"), {
            to: email,
            message: {
                subject: "Nuovo documento condominiale",
                text: "Buongiorno gentile "+nome+' '+cognome+', \n \n'+
                "La informiamo, che all'interno della sua area riservata, è stato caricato un nuovo file condominiale ("+nome.slice(13)+')'+
                '\n \n Per prenderne visione clicchi il seguente link \n'+
                'https://studioballon.it'+
                '\n \n \n Grazie e cordiali saluti \n \n'+
                'Studio Ballon\n'+
                'Via Augusto Serena, 37 - 31044 Montebelluna (TV) - Italy\n'+
                'Tel. +39 0423 300507\n'+
                'Fax +39 0423 610699\n'+
                'E-mail segreteria@studioballon.it\n'+
                'studioballon.it\n \n'+
                'Ai sensi del D.Lgs. 196/2003 si avvisa che la presente comunicazione e gli eventuali documenti ad essa allegati contengono informazioni riservate e ad uso esclusivo del destinatario sopra indicato. Qualora non fossero a Lei destinati, La preghiamo di tenere presente che la divulgazione, distribuzione o riproduzione di qualunque informazione in essi contenuta sono vietate. Se ha ricevuto la presente comunicazione per errore, La preghiamo di volerci avvertire immediatamente e di distruggere quanto ricevuto. Grazie.\n'+
                'This message including any attachments contains confidential information intended for a specific individual and purpose, and is protected by law. If you are not the intended recipient, you are hereby notified that any disclosure, copying, or distribution of this message is strictly prohibited, and are kindly requested to return the e-mail to sender and delete this message from your computer. Many thanks for your kind co-operation.',
              },
          });
          let nuovoNum = docSnap.data().numero+1
          await setDoc(doc(firebase.db, 'sendgrid', date), {
            numero: nuovoNum
          })
        } else {
          const sendEmail = httpsCallable(functions, 'sendEmail');
          sendEmail({ 
            "email": email,
            "subject": "Nuovo documento condominiale",
            "message": "Buongiorno gentile "+nome+' '+cognome+', \n \n'+
            "La informiamo, che all'interno della sua area riservata, è stato caricato un nuovo file condominiale ("+nome.slice(13)+')'+
            '\n \n Per prenderne visione clicchi il seguente link \n'+
            'https://studioballon.it'+
            '\n \n \n Grazie e cordiali saluti \n \n'+
            'Studio Ballon\n'+
            'Via Augusto Serena, 37 - 31044 Montebelluna (TV) - Italy\n'+
            'Tel. +39 0423 300507\n'+
            'Fax +39 0423 610699\n'+
            'E-mail segreteria@studioballon.it\n'+
            'studioballon.it\n \n'+
            'Ai sensi del D.Lgs. 196/2003 si avvisa che la presente comunicazione e gli eventuali documenti ad essa allegati contengono informazioni riservate e ad uso esclusivo del destinatario sopra indicato. Qualora non fossero a Lei destinati, La preghiamo di tenere presente che la divulgazione, distribuzione o riproduzione di qualunque informazione in essi contenuta sono vietate. Se ha ricevuto la presente comunicazione per errore, La preghiamo di volerci avvertire immediatamente e di distruggere quanto ricevuto. Grazie.\n'+
            'This message including any attachments contains confidential information intended for a specific individual and purpose, and is protected by law. If you are not the intended recipient, you are hereby notified that any disclosure, copying, or distribution of this message is strictly prohibited, and are kindly requested to return the e-mail to sender and delete this message from your computer. Many thanks for your kind co-operation.'
          })
            .then((result) => {
              //console.log(result.data.status+' '+result.data.message)
            })
            .catch((e) => {
            }); 
        }
      } else {
        const docRef = await addDoc(collection(firebase.db, "mail"), {
          to: email,
          message: {
              subject: "Nuovo documento condominiale",
              text: "Buongiorno gentile "+nome+' '+cognome+', \n \n'+
              "La informiamo, che all'interno della sua area riservata, è stato caricato un nuovo file condominiale ("+nome.slice(13)+')'+
              '\n \n Per prenderne visione clicchi il seguente link \n'+
              'https://studioballon.it'+
              '\n \n \n Grazie e cordiali saluti \n \n'+
              'Studio Ballon\n'+
              'Via Augusto Serena, 37 - 31044 Montebelluna (TV) - Italy\n'+
              'Tel. +39 0423 300507\n'+
              'Fax +39 0423 610699\n'+
              'E-mail segreteria@studioballon.it\n'+
              'studioballon.it\n \n'+
              'Ai sensi del D.Lgs. 196/2003 si avvisa che la presente comunicazione e gli eventuali documenti ad essa allegati contengono informazioni riservate e ad uso esclusivo del destinatario sopra indicato. Qualora non fossero a Lei destinati, La preghiamo di tenere presente che la divulgazione, distribuzione o riproduzione di qualunque informazione in essi contenuta sono vietate. Se ha ricevuto la presente comunicazione per errore, La preghiamo di volerci avvertire immediatamente e di distruggere quanto ricevuto. Grazie.\n'+
              'This message including any attachments contains confidential information intended for a specific individual and purpose, and is protected by law. If you are not the intended recipient, you are hereby notified that any disclosure, copying, or distribution of this message is strictly prohibited, and are kindly requested to return the e-mail to sender and delete this message from your computer. Many thanks for your kind co-operation.',
            },
        });
        await setDoc(doc(firebase.db, 'sendgrid', date), {
          numero: 1
        })
      }
  }

    const cambiaTipo = async (id, e) => {
      e.preventDefault()
      if(e.target[1].value !== '') {
        const docRef = doc(firebase.db, "clientiParticella", id);
        await updateDoc(docRef, {
          tipo: Capitalize(e.target[1].value)
        });
      }
      
      getParticella()
      
    }

    /* const invioMail = (email, file, nome, cognome) => {
      const sendEmail = httpsCallable(functions, 'sendEmail');
      sendEmail({ 
        "email": email,
        "subject": "Nuovo documento personale",
        "message": "Buongiorno gentile "+nome+' '+cognome+', \n \n'+
        "La informiamo, che all'interno della sua area riservata, è stato caricato un nuovo file personale ("+file.slice(13)+')'+
        '\n \n Per prenderne visione clicchi il seguente link \n'+
        'https://studioballon-4f3e8.web.app'+
        '\n \n \n Grazie e cordiali saluti \n \n Studio Ballon'
       })
        .then((result) => {
          //console.log(result)
        })
        .catch((e) => {
          //alert('Errore email: '+e)
        });
    } */

    /* function openTab(tab) {
        var i;
        var x = document.getElementsByClassName("schede");
        for (i = 0; i < x.length; i++) {
          x[i].style.display = "none";
        }
        document.getElementById(tab).style.display = "block";
        if(tab != 'NuovoCliente') {
          setCssLabelNuovoCliente('text-indigo-500 bg-white')
          setCssLabelGiaCliente('text-white bg-indigo-500')
        } else {
          setCssLabelNuovoCliente('text-white bg-indigo-500')
          setCssLabelGiaCliente('text-indigo-500 bg-white')
        }
      } */


    /* useEffect(() => {
        getCliente();
    }, []) */
    useEffect(() => {
      if (urlIdParticella) {
        getParticella();     
      } else {
        history.replace('/');
      }   
    }, []);
    useEffect(() => {
      displayH1Clienti()
    }, [allClienti]);
    useEffect(() => {
      displayH1Clienti()
    }, [allEntrambi]);
    useEffect(() => {
      displayH1Clienti()
    }, [allProprietari]);
    useEffect(() => {
      displayH1Clienti()
    }, [allInquilini]);
    /* useEffect(() => {
      listaFiles(urlAnno);
    }, [])  */
    


    return(
      <div>
        <Helmet>
          <title>Studio Ballon - Gestione Particella Catastale</title>
        </Helmet>
        <Header />
        <div className='md:w-2/3 p-5 mx-auto text-indigo-500 font-semibold'>
          <span className='cursor-pointer underline' onClick={() => history.push("/amministratori/home")}>Home Amministrazione</span> / <span className='cursor-pointer underline' onClick={() => history.push({
          pathname: '/amministratori/condominio',
          search: '?id='+urlIdCondominio+'&anno='+new Date().getFullYear()
        })}>Gestione {nomeCon}</span> / Particella: {particella}
        </div>
        <div className=" p-5 w-full md:w-2/3 mx-auto mt-5 mb-14 shadow-2xl bg-white rounded-xl">
            <h2 className='font-bold mb-5 text-2xl'>Gestione Particella </h2>
            <div>

              {/* Intestazione Particella */}
              <div className='p-5 border-l-2 border-indigo-500 bg-gray-100'>
                <div className="md:flex md:justify-between items-center">
                  <div className='mb-3 md:mb-0'>
                    <h1 className='text-xl text-indigo-500'>Particella: {particella}   ({nomeCon})</h1>
                  </div>
                  <button  onClick={(e) => {showModal('particella', '', '')}} type='button'  className="mt-2 md:px-6 w-full justify-center rounded-md border border-transparent shadow-sm h-10 px-5 text-lg font-semibold bg-red-600  text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm block">Elimina Particella</button>  
                </div>
              </div>

              
              <div className=' border-indigo-500 border mt-5'>
                <div id="Clienti" className='  m-1 '>
                  <div className='text-xl font-semibold p-5'> Proprietari / Inquilini Particella Catastale</div>
                  <div  id='numeroClienti' className='p-4 hidden' >
                    <div className='text-red-500 bg-white border border-red-500 text-center rounded-xl font-semibold  p-2'>Nessun Cliente ancora registrato in questa particella!</div>
                  </div>
                  
                  <h1 id='allEntrambi' className='hidden   mx-6 pt-4 font-semibold '>Entrambi</h1>
                  <div id='allEntrambiDiv' className=' hidden grid pt-2 pb-4 mx-4'>
                    {allEntrambi.map((item, i) => {
                      if(url.searchParams.get("r") !== item.id) {
                        
                        return (
                          <div key={i} className='md:flex justify-between px-5 py-2 m-1 border border-indigo-500  text-gray-900 font-semibold transition-colors duration-150 bg-gray-100 rounded-lg focus:shadow-outline hover:bg-indigo-100'>
                            <div id={item.id}  onClick={() => history.push("/amministratori/gestionecliente?id="+item.id+'&anno='+anno+'&idC='+urlIdCondominio+'&idP='+urlIdParticella)} className='  cursor-pointer '>
                              <div className='font-semibold text-base'>{item.cognome} {item.nome} </div>
                              <div className='font-semibold text-xs'><span className='font-extrabold'>Email:</span> {item.email}</div>
                              <div className='font-semibold text-xs'><span className='font-extrabold'>Tel:</span> {item.tel}   <span className='font-extrabold'>PEC:</span> {item.pec}</div>
                              {/* <div className='font-semibold text-sm'>PEC: {item.pec}</div> */}
                              <div className='font-semibold text-xs'><span className='font-extrabold'>Tipo:</span> {item.tipo}   <span className='font-extrabold'>P.Catastale:</span> {item.particella}</div>
                              {/* <div className='font-semibold text-sm'>P.Catastale: {item.particella}</div> */}
                            </div>
                            <div className='my-auto'>
                              <div className='p-2 cursor-pointer font-bold text-red-600' onClick={(e) => {showModal('cliente', item.nome+' '+item.cognome, '', item.email)}}>Elimina dalla particella</div>
                              <div className=' border-indigo-500 border-t p-2'>
                                <form className='inline-block' onSubmit={(e) => {cambiaTipo(item.idClientiParticella, e)}}>                                 
                                    <button type='submit' className='my-auto cursor-pointer font-bold text-yellow-600 mr-2'>Modifica tipo</button>
                                    <select className={"   px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline"} >
                                        <option value=''>- seleziona -</option>
                                        <option value='proprietario'>Proprietario</option>
                                        <option value='inquilino'>Inquilino</option>
                                        <option value='entrambi'>Entrambi</option>
                                    </select>
                                </form>
                              </div>
                            </div>
                          </div>
                        );
                      }
                      //}
                    })}
                  </div>
                  <div className='border-b border-white'></div>
                  <h1 id='allProprietari' className='hidden   mx-6 pt-4 font-semibold '>Proprietari</h1>
                  <div id='allProprietariDiv'  className=' hidden grid  mx-4 pt-2 pb-4'>
                    {allProprietari.map((item, i) => {
                      if(url.searchParams.get("r") !== item.id) {
                        
                        return (
                          <div key={i} className='md:flex justify-between px-5 py-2 m-1 border border-indigo-500  text-gray-900 font-semibold transition-colors duration-150 bg-gray-100 rounded-lg focus:shadow-outline hover:bg-indigo-100'>
                            <div  id={item.id}  onClick={() => history.push("/amministratori/gestionecliente?id="+item.id+'&anno='+anno+'&idC='+urlIdCondominio+'&idP='+urlIdParticella)} className='  cursor-pointer '>
                              <div className='font-semibold text-base'>{item.cognome} {item.nome} </div>
                              <div className='font-semibold text-xs'><span className='font-extrabold'>Email:</span> {item.email}</div>
                              <div className='font-semibold text-xs'><span className='font-extrabold'>Tel:</span> {item.tel}   <span className='font-extrabold'>PEC:</span> {item.pec}</div>
                              {/* <div className='font-semibold text-sm'>PEC: {item.pec}</div> */}
                              <div className='font-semibold text-xs'><span className='font-extrabold'>Tipo:</span> {item.tipo}   <span className='font-extrabold'>P.Catastale:</span> {item.particella}</div>
                              {/* <div className='font-semibold text-sm'>P.Catastale: {item.particella}</div> */}
                            </div>
                            <div className='my-auto'>
                              <div className='p-2 cursor-pointer font-bold text-red-600' onClick={(e) => {showModal('cliente', item.nome+' '+item.cognome, '', item.email)}}>Elimina dalla particella</div>
                              <div className=' border-indigo-500 border-t p-2'>
                                <form className='inline-block' onSubmit={(e) => {cambiaTipo(item.idClientiParticella, e)}}>                                 
                                    <button type='submit' className='my-auto cursor-pointer font-bold text-yellow-600 mr-2'>Modifica tipo</button>
                                    <select className={"   px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline"} >
                                        <option value=''>- seleziona -</option>
                                        <option value='proprietario'>Proprietario</option>
                                        <option value='inquilino'>Inquilino</option>
                                        <option value='entrambi'>Entrambi</option>
                                    </select>
                                </form>
                              </div>
                            </div>
                          </div>
                        );
                      }
                      //}
                    })}
                  </div>
                  <div className='border-b border-white'></div>
                  <h1 id='allInquilini' className='hidden   mx-6 pt-4 font-semibold  '>Inquilini</h1>
                  <div id='allInquiliniDiv' className=' hidden grid  mx-4 pt-2 pb-4'>
                    {allInquilini.map((item, i) => {
                      if(url.searchParams.get("r") !== item.id) {
                        
                        return (
                          <div key={i} className='md:flex justify-between px-5 py-2 m-1 border border-indigo-500  text-gray-900 font-semibold transition-colors duration-150 bg-gray-100 rounded-lg focus:shadow-outline hover:bg-indigo-100'>
                            <div  id={item.id}  onClick={() => history.push("/amministratori/gestionecliente?id="+item.id+'&anno='+anno+'&idC='+urlIdCondominio+'&idP='+urlIdParticella)} className='  cursor-pointer '>
                              <div className='font-semibold text-base'>{item.cognome} {item.nome} </div>
                              <div className='font-semibold text-xs'><span className='font-extrabold'>Email:</span> {item.email}</div>
                              <div className='font-semibold text-xs'><span className='font-extrabold'>Tel:</span> {item.tel}   <span className='font-extrabold'>PEC:</span> {item.pec}</div>
                              {/* <div className='font-semibold text-sm'>PEC: {item.pec}</div> */}
                              <div className='font-semibold text-xs'><span className='font-extrabold'>Tipo:</span> {item.tipo}   <span className='font-extrabold'>P.Catastale:</span> {item.particella}</div>
                              {/* <div className='font-semibold text-sm'>P.Catastale: {item.particella}</div> */}
                            </div>
                            <div className='my-auto'>
                              <div className='p-2 cursor-pointer font-bold text-red-600' onClick={(e) => {showModal('cliente', item.nome+' '+item.cognome, '', item.email)}}>Elimina dalla particella</div>
                              <div className=' border-indigo-500 border-t p-2'>
                                <form className='inline-block' onSubmit={(e) => {cambiaTipo(item.idClientiParticella, e)}}>                                 
                                    <button type='submit' className='my-auto cursor-pointer font-bold text-yellow-600 mr-2'>Modifica tipo</button>
                                    <select className={"   px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline"} >
                                        <option value=''>- seleziona -</option>
                                        <option value='proprietario'>Proprietario</option>
                                        <option value='inquilino'>Inquilino</option>
                                        <option value='entrambi'>Entrambi</option>
                                    </select>
                                </form>
                              </div>
                            </div>
                          </div>
                        );
                      }
                      //}
                    })}
                  </div>
                </div>
                {/* <div className='flex  border-b border-indigo-500' >
                  <button className={'w-1/2 p-2 m-1 align-middle border-indigo-500 border text-center font-semibold '+(cssLabelNuovoCliente)} id='labelNuovoCliente' onClick={() => openTab('NuovoCliente')}>Aggingi un nuovo cliente</button>
                  <button className={'w-1/2 p-2 m-1 align-middle border border-indigo-500 text-center font-semibold '+(cssLabelGiaCliente)} id='labelGiaCliente' onClick={() => openTab('GiaCliente')}>Aggiungi un cliente esistente</button>
                </div> */}
                {/* <div id="NuovoCliente" className='schede p-4 m-1 bg-indigo-500' >
                    <form className='p-5 border-indigo-500 border rounded-lg bg-gray-100' id='formNuovo_Cliente' onSubmit={(e) => {addCliente(e); }}>
                        <div className='text-sm text-red-500 mb-2'>* Campi obbligatori</div>
                        <h1 className=' font-semibold '>Nuovo Proprietario/Inquilino</h1>
                        <div className="flex flex-wrap -mx-2 my-4 space-y-4 md:space-y-0">
                            <div className="w-full px-2 md:w-1/2">
                                <label className={redLabelNome+" block mb-1 pl-2"} htmlFor="formGridNome">Nome <span className='text-red-500 font-bold'>*</span></label>
                                <input className={redNome+" w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline"} type="text" id="formGridNome"/>
                            </div>
                            <div className="w-full px-2 md:w-1/2">
                                <label className={redLabelCognome+" block mb-1 pl-2"} htmlFor="formGridCognome">Cognome <span className='text-red-500 font-bold'>*</span></label>
                                <input className={redCognome+" w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline"} type="text" id="formGridCognome"/>
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-2 my-4 space-y-4 md:space-y-0">
                            <div className="w-full px-2 md:w-1/3">
                                <label className={redLabelEmail+" block mb-1 pl-2"} htmlFor="formGridEmail">Email <span className='text-red-500 font-bold'>*</span></label>
                                <input className={redEmail+" w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline"} type="email" id="formGridEmail"/>
                            </div>
                            <div className="w-full px-2 md:w-1/3">
                                <label className="block mb-1 pl-2" htmlFor="formGridPec">PEC</label>
                                <input className="w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline" type="email" id="formGridPec"/>
                            </div>
                            <div className="w-full px-2 md:w-1/3">
                                <label className="block mb-1 pl-2" htmlFor="formGridPec">Tel</label>
                                <input className="w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline" type="tel" id="formGridTel"/>
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-2 space-y-4 md:space-y-0">
                            <div className="w-full px-2 md:w-1/2">
                                <label className={redLabelTipo+" block mb-1 pl-2"} htmlFor="formGridCliente">Proprietario/Inquilino <span className='text-red-500 font-bold'>*</span></label>
                                <select className={redTipo+" w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline"} type="select" id="formGridTipo">
                                    <option value=''>- seleziona -</option>
                                    <option value='proprietario'>Proprietario</option>
                                    <option value='inquilino'>Inquilino</option>
                                    <option value='entrambi'>Entrambi</option>
                                </select>
                            </div>
                        </div>
                        <button type="submit" className='h-10 px-5 mt-4 text-indigo-100 transition-colors duration-150 bg-indigo-700 rounded-lg focus:shadow-outline hover:bg-indigo-800'>Aggiungi</button>
                    </form> 
                </div> */}
                {/* <div id="GiaCliente" className='schede p-4 m-1 bg-indigo-500 ' >
                    <div className='mb-2 border-indigo-500 border rounded-lg bg-gray-100'>
                      <form className='p-5 ' id='formGia_ClienteCognome'  onSubmit={(e) => {getClienteCognome(false, e); }} >
                          <h1 className=' font-semibold '>Cerca Proprietario/Inquilino già registrato</h1>
                          <div className="flex flex-wrap -mx-2 my-4 space-y-4 md:space-y-0">
                              <div className="w-full px-2 md:w-1/2">
                                  <label className={redGetLabelCognome+" block mb-1 pl-2"} htmlFor="formGridCognome">Cognome</label>
                                  <input className={redGetCognome+" w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline"} type="text" id="formGridCognome"/>
                              </div>
                          </div> 
                          <button type="submit" className='h-10 px-5  text-indigo-100 transition-colors duration-150 bg-indigo-700 rounded-lg focus:shadow-outline hover:bg-indigo-800'>Cerca</button>  
                      </form>
                      {allCognome.map((item, i) => {
                        if(item.attivo === true) {
                          return (
                            <div key={i} className=' px-5 py-2'>
                              <div className='border-indigo-500 border p-2 rounded-lg font-semibold md:flex md:justify-between' >
                                <div>{item.nome} {item.cognome} - {item.email}</div>
                                <div>
                                  <form className=''  id='formGia_ClienteCognome'  onSubmit={(e) => {addClienteCognome(item.cognome, item.email, e); }} >
                                    <select className={redGetTipoCognome+" rounded-lg focus:shadow-outline"} type="text" >
                                      <option value=''>- seleziona -</option>
                                      <option value='proprietario'>Proprietario</option>
                                      <option value='inquilino'>Inquilino</option>
                                      <option value='entrambi'>Entrambi</option>
                                    </select>
                                    <button type="submit" className=' ml-5 px-5  text-indigo-100 transition-colors duration-150 bg-indigo-700 rounded-lg focus:shadow-outline hover:bg-indigo-800'>Aggiungi</button>
                                  </form>
                                </div>
                              </div>
                            </div>
                          );
                        } else {
                          return (
                            <div className=' px-5 py-2' key={i}>
                              <div className='border-indigo-500 border p-2 rounded-lg font-semibold md:flex md:justify-between'>
                                <div>{item.nome} {item.cognome} - {item.email}</div>
                                <div className='text-red-700'>
                                  Cliente già inserito in questa particella
                                </div>
                              </div>
                            </div>
                          )
                        }                       
                      })}
                    </div>
                    <form className='p-5 border-indigo-500 border rounded-lg bg-gray-100' id='formGia_ClienteEmail'  onSubmit={(e) => {addClienteEmail(e); }} >
                        <div className='text-sm text-red-500 mb-2'>Tutti i campi sono obbligatori</div>
                        <h1 className=' font-semibold '>Inserisci Proprietario/Inquilino già registrato</h1>
                        <div className="flex flex-wrap -mx-2 my-4 space-y-4 md:space-y-0">
                            <div className="w-full px-2 md:w-1/2">
                                <label className={redGetLabelEmail+" block mb-1 pl-2"} htmlFor="formGridPec">Email</label>
                                <input className={redGetEmail+" w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline"} type="email" id="formGridEmail"/>
                            </div>
                            <div className="w-full px-2 md:w-1/2">
                                <label className={redGetLabelTipo+" block mb-1 pl-2"} htmlFor="formGridCliente">Proprietario/Inquilino </label>
                                <select className={redGetTipo+" w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline"} type="text" id="formGridCliente">
                                    <option value=''>- seleziona -</option>
                                    <option value='proprietario'>Proprietario</option>
                                    <option value='inquilino'>Inquilino</option>
                                    <option value='entrambi'>Entrambi</option>
                                </select>
                            </div>
                        </div> 
                        <button type="submit" className='h-10 px-5  text-indigo-100 transition-colors duration-150 bg-indigo-700 rounded-lg focus:shadow-outline hover:bg-indigo-800'>Aggiungi</button>  
                    </form>
                </div> */}
              </div> 


              <div className='p-5 border-indigo-500 border mt-5'>

                
                <h1 className='text-lg font-semibold '>Files particella</h1>
                <h2 className={'text-semibold text-red-800 '+(nessunRisultato)}>Nessun File presente</h2>

               
                <div>
                  
                  {allFiles.map((item, i) => {
                    
                     
                      return (
                        <div className='md:flex md:justify-between border border-indigo-500 my-2 py-2 px-5 bg-gray-50 rounded-lg focus:shadow-outline hover:bg-indigo-100 cursor-pointer' key={i}>
                          <a className='cursor-pointer font-semibold mb-3 md:mb-0' href={item.url}>{item.nome.slice(13)}  <span className='text-sm block'>{item.data}</span><span className='text-sm block'>File riservato a: {item.tipo}</span></a>
                          <div className='my-auto cursor-pointer font-bold text-red-600' onClick={(e) => {showModal('file', item.nome, item.id)}}>Elimina</div>
                        </div>
                      );
                    
                  })}
                  <div className={'mt-8 flex justify-center '+(nessunRisultatoReverse)}>
                    <div className={'py-2 px-4 border border-indigo-500 rounded-full font-bold text-indigo-500 cursor-pointer '+(annoSuc)} onClick={(e) => {listaFiles(Number(urlAnno)+1, particella)}}>&lt;</div>
                    <div className='mx-5 p-2 border border-indigo-500 rounded-full font-bold text-indigo-500'>{urlAnno}</div>
                    <div className={'py-2 px-4 border border-indigo-500 rounded-full font-bold text-indigo-500 cursor-pointer '+(annoPrec)} onClick={(e) => {listaFiles(Number(urlAnno)-1, particella)}}>&gt;</div>
                  </div>
                </div> 

               
                <div>
                  <div className="flex w-full h-64 items-center justify-center bg-grey-lighter">
                      <label className="w-72 flex flex-col items-center px-4 py-6 bg-white text-indigo-500 rounded-lg shadow-lg tracking-wide uppercase border border-indigo-500 cursor-pointer hover:bg-indigo-500 hover:text-white">
                          <svg className="w-10 h-10" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                              <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                          </svg>
                          <span className="mt-2 text-sm leading-normal">{nomeFile}</span>
                          <input type='file' className="hidden" onChange={(e) => {onFileChange(e); }} />
                      </label>
                  </div>
                  <div className='flex items-center justify-center -mt-14'>
                    <select id='tipo' className="w-72 mx-auto h-8 px-6 text-sm text-indigo-100 transition-colors duration-150 bg-indigo-700 rounded-lg focus:shadow-outline hover:bg-indigo-800">
                        <option id='vuoto' value=''>- Seleziona il tipo di file -</option>
                        <option id='entrambi' value='entrambi'>Entrambi</option>
                        <option id='proprietario' value='proprietario'>Proprietario</option>
                        <option id='inquilino' value='inquilino'>Inquilino</option>
                    </select>  
                  </div>
                  <div className='flex items-center justify-center mt-2'>
                    <button className="w-72 mx-auto h-8 px-4 text-sm text-indigo-100 transition-colors duration-150 bg-indigo-700 rounded-lg focus:shadow-outline hover:bg-indigo-800" onClick={(e) => {uploadToFirebase(); }}>Invia file</button>
                  </div>
                </div>
              </div> 






            </div>
            <div className="flex justify-between">
              <button className=' h-10 px-5 mx-4 my-6 text-indigo-100 transition-colors duration-150 bg-indigo-700 rounded-lg focus:shadow-outline hover:bg-indigo-800' onClick={() => history.push({
          pathname: '/amministratori/condominio',
          search: '?id='+urlIdCondominio+'&anno='+new Date().getFullYear()
        })}> Indietro</button>
              <SignOutButton />    
            </div>






            {/* modal */}
            <div className="hidden fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true" id='modal'>
              <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        {/* <!-- Heroicon name: outline/exclamation --> */}
                        <svg className="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                        </svg>
                      </div>
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                          {btnTitolo}
                        </h3>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            {btnTesto}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                    <button id='btnFile' type="button" onClick={(e) => {{eliminaFile(btnNome, btnId)}}}  className="hidden w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
                      Elimina
                    </button>
                    <button id='btnParticella' type="button" onClick={(e) => {{eliminaParticella()}}}  className="hidden w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
                      Elimina
                    </button>
                    <button id='btnCliente' type="button" onClick={(e) => {{eliminaClienteParticella(btnEmail, urlIdParticella)}}} className="hidden w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
                      Elimina 
                    </button>
                    <button type="button"  onClick={(e) => {modal.style.display = "none"}} className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                      Cancella
                    </button>
                  </div>
                </div>
              </div>
            </div>
        </div> 
        <Footer />
      </div>   
    );
}

export default GestioneParticella;