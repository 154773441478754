import getFirebase from "../firebase";
import { doc, getDocs, query, addDoc, collection, where, serverTimestamp, updateDoc } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";


const AddCliente = async (nome, cognome, email, pec, tel) => {

    const firebase = getFirebase();
    const functions = getFunctions();


    const updateCliente = async (id, pec, tel) => {
        const docRef = doc(firebase.db, "clienti", id);
        await updateDoc(docRef, {
            pec: pec,
            tel: tel
        });
    }



    const getCliente = collection(firebase.db, "clienti");
    const q = query(getCliente,  where('email', '==', email));
    let querySnapshot = await getDocs(q);
    if(querySnapshot.empty) {
        if(nome && cognome && email ){
            try {
                const docRef = await addDoc(collection(firebase.db, "clienti"), {
                    nome: nome,
                    cognome: cognome,
                    email: email,
                    pec: pec,
                    tel: tel,
                    timestamp: serverTimestamp()
                });
                //listaClienti();
                const createUser = httpsCallable(functions, 'createUser');

                const user = {
                    email: email,
                    password: email
                };

                createUser(user)
                    .then((result) => {
                        //le.log(result)
                        const actionCodeSettings = {
                            url: 'https://studioballon-4f3e8.web.app/'
                        };
                        const auth = getAuth();
                        sendPasswordResetEmail(auth, email, actionCodeSettings)
                            .then((userCredential) => {
                            })
                            .catch((error) => {
                                //console.log(error)
                            });
                        })
                    .catch((e) => {
                        });
            } catch (e) {
                /* addDoc(collection(firebase.db, "errori"), {
                    nome: 'Inserimento Cliente',
                    timestamp: serverTimestamp(),
                    dati: e
                }); */
            }
        } 
    } else {
        querySnapshot.forEach((doc) => {
            updateCliente(doc.id, pec, tel)
        })
    }


}

export default AddCliente