import React, { useEffect } from "react";
import Footer from './Footer'
import Header from './Header'
import 'animate.css';

//import { useSpring, animated } from 'react-spring'

function InformazioniLegali(){
    
    

  //const props = useSpring({ to: { opacity: 1 }, from: { opacity: 0 } })
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  

  return (
    <div >
        
        <Header />
        
        <div className='p-5 mx-5 my-20 md:w-1/3 md:mx-auto bg-white rounded-lg ObjectiveRegular'>
          <h1 className=' text-3xl mb-5 font-semibold'>Informazioni Legali</h1>
          <p>I testi, le informazioni e gli altri dati pubblicati in questo sito nonchè i link ad altri siti presenti sul web hanno esclusivamente scopo informativo e non assumono alcun carattere di ufficialità. Non assume alcuna responsabilità per eventuali errori od omissioni di qualsiasi tipo e per qualunque tipo di danno diretto, indiretto o accidentale derivante dalla lettura o dall'impiego delle informazioni pubblicate, o di qualsiasi forma di contenuto presente nel sito o per l'accesso o l'uso del materiale contenuto in altri siti.</p>
          <div className='mt-20 font-semibold'>Studio Ballon</div>
          <div className='font-semibold'>P.I. 01152820260</div>
        </div>
        
        
        <Footer />
        
    </div>
  );
};

export default InformazioniLegali;