import React, { useEffect,useState } from "react";
import { Helmet } from 'react-helmet-async';
import { GoogleMap, useLoadScript, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import { Link } from "react-router-dom";
import Footer from './Footer'
import Header from './Header'
import { BsPhone } from 'react-icons/bs';
import { BsMailbox } from 'react-icons/bs';
import { HiOutlineLocationMarker } from 'react-icons/hi'
import {AiOutlineLike} from 'react-icons/ai'
import {IoLogoWhatsapp} from 'react-icons/io'
import CookieConsent from "react-cookie-consent";
import useInput from "./useInput";
import { getFunctions, httpsCallable } from "firebase/functions";

//import { useSpring, animated } from 'react-spring'

function Contatti(){
    //const [currentUser, setCurrentUser] = useState(null);
    const functions = getFunctions();

    const [modal, setModal] = useState('hidden')
    const [spinner, setSpinner] = useState('hidden')
    const [responseTrue, setResponseTrue] = useState('hidden')
    const [responseFalse, setResponseFalse] = useState('hidden')
    
    //let url = new URL(window.location.href);
    const [redNome, setRedNome] = useState('')
    const [redEmail, setRedEmail] = useState('')
    const [redMessaggio, setRedMessaggio] = useState('')

    const email = useInput("");
    const nome = useInput("");
    const messaggio = useInput("");
    const terms = useInput(false);
    const verifica = useInput(false);

    const invioMail = (nome, email, messaggio) => {
        setModal('')
        setSpinner('')
        const sendEmail = httpsCallable(functions, 'sendEmail');
        sendEmail({ 
            "email": 'segreteria@studioballon.it',
            "subject": "Nuova Richiesta di Contatto",
            "message": "È arrivata una nuova richiesta di contatto tramite studioballon.it,"+' \n \n'+
            "Nome Mittente: "+nome+' \n \n'+
            "Email Mittente: "+email+' \n \n'+
            "Messaggio: "+messaggio+' \n \n'
        })
        .then((result) => {
            setSpinner('hidden')
            setResponseTrue('')
        })
        .catch((e) => {
            setSpinner('hidden')
            setResponseFalse('')
        });

        /* sendEmail({ 
            "email": email,
            "subject": "Presa in carico della vostra richiesta di contatto",
            "message": "È arrivata una nuova richiesta di contatto tramite studioballon.it,"+' \n \n'+
            "Nome Mittente: "+nome+' \n \n'+
            "Email Mittente: "+email+' \n \n'+
            "Messaggio: "+messaggio+' \n \n'
        })
        .then((result) => {
            alert('Gentile cliente la richiesa di contatto è stata inviata \n\n Verrà contattato nel minor tempo possibile')
              //console.log(result.data.status+' '+result.data.message)
        })
        .catch((e) => {
        }); */
    }

    const sendMessage = (event) => {
        event.preventDefault()
        if(!verifica.value) {
            if(nome.value.trim() !== '' && email.value.trim() !== '' && messaggio.value.trim() !== ''){
                setRedNome('')
                setRedEmail('')
                setRedMessaggio('')
                invioMail(nome.value, email.value, messaggio.value)
            } else {
                if(nome.value.trim() === '') {
                    setRedNome('bg-red-50 border border-red-500')
                } else {
                    setRedNome('')
                }
                if(email.value.trim() === '') {
                    setRedEmail('bg-red-50 border border-red-500')
                } else {
                    setRedEmail('')
                }
                if(messaggio.value.trim() === '') {
                    setRedMessaggio('bg-red-50 border border-red-500')
                } else {
                    setRedMessaggio('')
                }
            }
        }
    }

    const closeModal = () => {
        setSpinner('hidden')
        setResponseTrue('hidden')
        setResponseFalse('hidden')
        setModal('hidden')
        document.getElementById('formMessaggio').reset()
    }

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: "AIzaSyBjTG9p_f1DZ2lKsPFxo_iZ8FMztXgxuGw" // ,
        // ...otherOptions
      })
      const MapContainer = (props) => {
        const mapStyles = {        
          height: "30vh",
          width: "100%"};
      
        const center = {
          lat: 45.77678911065497, lng: 12.043988869103384
        }
    
        let zoom = 15
  
        const onLoad = React.useCallback(
          function onLoad (mapInstance) {
            // do something with map Instance
          }
        )
  
      
        return (
          
          <GoogleMap
          mapContainerStyle={mapStyles}
          center={center}
          zoom={zoom}
          onLoad={onLoad}>
              {
                      <Marker   
                      position={center}
                      title = 'Studio Ballon'                  
                      />
              }
              
          </GoogleMap>
     
        )
      }
      if (loadError) {
        return <div>Map cannot be loaded right now, sorry.</div>
      }

      

    


    /* useEffect(() => {
        window.scrollTo(0, 0)
      }, []) */
    
    

  //const props = useSpring({ to: { opacity: 1 }, from: { opacity: 0 } })
  


  

  return (
    <div className=''>
        <Helmet>
            <html lang="it" />
            <title>Studio Ballon - Contatti</title>
            <meta name="description" content="Studio Ballon - Come e dove trovarci" />
            <meta name="theme-color" content="#E6E6FA" />
            <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1'/>
            <meta property="og:locale" content="it_IT"/>
            <meta property="og:title" content="Studio Ballon - Contatti"/>
            <meta property="og:description" content="Studio Ballon - Montebelluna (TV) - Come e dove trovarci"/>
            <meta property="og:url" content="https://studioballon.it/contatti/"/>
            <meta property="og:site_name" content="studioballon.it"/>
            <meta property="article:modified_time" content="2022-03-21T10:32:09+00:00"/>
            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:label1" content="Tempo di lettura stimato"/>
            <meta name="twitter:data1" content="3 minuti"/>
        </Helmet>
        
        <Header />
        <div className='md:py-48 py-20  bg-cover'  style={{ backgroundImage: `url("contact.jpg")` }}>
            <div className='md:text-8xl text-5xl text-white ObjectiveBold text-center'>CONTATTI</div>
        </div>
        <div className=' bg-white py-10 text-blueGray-700'>
            <div className='lg:w-3/4 mx-auto md:flex'>
                <div className='md:w-1/2 p-5 text-center'>
                    <div className='shadow-lg hover:shadow-2xl px-5 py-7 rounded-xl text-yellow-600 bg-blueGray-200 hover:bg-blueGray-300'>
                        <BsPhone className='mx-auto font-bold text-6xl'/>
                        <div className='my-10 text-lg ObjectiveMedium'>CHIAMA</div>
                        <div className=' text-lg ObjectiveBold'><a className='underline hover:text-yellow-800' href="tel:+390423300507">+39 0423 300507</a></div>
                    </div>
                    <div className='shadow-lg hover:shadow-2xl px-5 py-7 md:my-5 mt-10 rounded-xl text-yellow-600 bg-blueGray-200 hover:bg-blueGray-300'>
                        <a href="https://wa.me/390423300507?text=Gentile%20Cliente%20benvenuto%20nella%20chat%20dello%20Studio%20Ballon.%20In%20cosa%20possiamo%20aiutarti?" target="_blank" rel="noopener"><IoLogoWhatsapp className='mx-auto font-bold text-6xl animate-pulse'/></a>
                        <a href="https://wa.me/390423300507?text=Gentile%20Cliente%20benvenuto%20nella%20chat%20dello%20Studio%20Ballon.%20In%20cosa%20possiamo%20aiutarti?" target="_blank" rel="noopener"><div className='my-10 text-lg ObjectiveMedium underline'>CHATTA CON NOI</div></a>
                    </div>
                    <div className='shadow-lg hover:shadow-2xl px-5 py-7 md:my-5 mt-10 rounded-xl text-yellow-600 bg-blueGray-200 hover:bg-blueGray-300'>
                        <BsMailbox className='mx-auto font-bold text-6xl'/>
                        <div className='my-10 text-lg ObjectiveMedium'>EMAIL</div>
                        <div className=' text-lg ObjectiveBold'><a className='underline hover:text-yellow-800'  href="mailto:segreteria@studioballon.it ?soggetto=Richiesta%20di%20informazioni">segreteria@studioballon.it </a></div>
                    </div>
                    <div className='shadow-lg hover:shadow-2xl px-5 py-7 mt-10 md:mt-0 rounded-xl text-yellow-600 bg-blueGray-200 hover:bg-blueGray-300'>
                        <HiOutlineLocationMarker className='mx-auto font-bold text-6xl text-yellow-600'/>
                        <div className='mt-8 mb-11 text-lg ObjectiveMedium'>INDIRIZZO</div>
                        <div className=' mb-5 text-lg ObjectiveBold'><a className='underline hover:text-yellow-800' href='https://goo.gl/maps/Ze6PAJcute78b1rn6'>Via Augusto Serena, 37 - 31044 Montebelluna (TV)</a></div>
                        {isLoaded ? <MapContainer/> : <div className='text-red-600 text-xl font-semibold p-5'>Errore nel caricamento della mappa</div>}
                    </div>
                    <div className='shadow-lg hover:shadow-2xl px-5 py-7 mt-10 md:mt-5 rounded-xl text-yellow-600 bg-blueGray-200 hover:bg-blueGray-300'>
                        <AiOutlineLike className='mx-auto font-bold text-6xl text-yellow-600'/>
                        <div className='mt-8 mb-11 text-lg ObjectiveMedium'>RECENSIONI</div>
                        <div className=' mb-5 text-lg ObjectiveBold'><a className='underline hover:text-yellow-800' href='https://g.page/r/Cf7v5SFYJyYTEAg/review'>Premiaci con una recensione</a></div>
                    </div>
                </div>
                <div className='md:w-2/4 '>
                    <div className='mx-5 md:mx-auto p-5 my-5 bg-blueGray-200 rounded-xl shadow-lg  hover:shadow-2xl hover:bg-blueGray-300'>
                        <div className='ObjectiveBold text-3xl text-center'>Contattaci!</div>
                        <div className='py-2 text-center'>Chiamaci o scrivici</div>
                        <form className='md:px-10 py-5' id='formMessaggio' onSubmit={sendMessage}>
                            <div className='text-sm text-red-500 mb-2'>* Tutti i campi sono obbligatori</div>
                            <input placeholder='Nome' className={redNome+" w-full h-10 px-3 mb-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline"} type="text" id="nome" {...nome}/>
                            <input placeholder='Email' className={redEmail+" w-full h-10 px-3 mb-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline"} type="email" id="email" {...email}/>
                            <textarea placeholder='Lascia un messaggio...' rows='12' className={redMessaggio+" w-full  px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline"} id='messaggio' {...messaggio}></textarea>
                            <div className="flex items-start mb-6">
                                <div className="flex items-center h-5">
                                    <input id="terms" aria-describedby="terms" type="checkbox" className="bg-gray-50 border border-gray-300 focus:ring-3 focus:ring-blue-300 h-4 w-4 rounded dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800" required  value="false"  {...terms} />
                                </div>
                                <div className="text-sm ml-3">
                                    <label htmlFor="terms" className="font-medium text-gray-900 dark:text-gray-300">Acconsenti all'archiviazione e alla gestione dei tuoi dati da parte dello Studio Ballon.</label>
                                </div>
                            </div>
                            <div className='hidden'>
                                <label className="text-gray-700">
                                    <input type="checkbox"  value="false"  {...verifica}/>
                                    <span className="ml-1">Verifica</span>
                                </label>
                            </div>
                            <button type="submit" className='h-10 px-5 mt-4 text-indigo-100 transition-colors duration-150 bg-indigo-700 rounded-lg focus:shadow-outline hover:bg-indigo-800'>Aggiungi</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        

        
        <Footer />
        <CookieConsent
            acceptOnScroll={true}
            acceptOnScrollPercentage={50}
            onAccept={(byScroll) => {
            }}
            location="bottom"
            buttonText="OK"
            cookieName="myAwesomeCookieName2"
            style={{ background: "#2B373B" }}
            buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
            expires={150}
            >
            <span style={{ fontSize: "12px" }}>Questo sito utilizza cookie tecnici, analitici e di profilazione, anche di terze parti, necessari rispettivamente, a consentire alcune funzionalità fondamentali per il sito, ad effettuare misurazioni delle performances del sito stesso o a fornirti indicazioni promozionali in linea con i tuoi interessi.

Per avere maggiori informazioni e scoprire come negare in tutto o in parte i tuoi consensi, consulta la <Link to='/PrivacyCookie' className='underline'>cookie policy</Link>.</span>
        </CookieConsent>
        {/* Modal */}
        <div className={modal+" fixed z-10 inset-0 overflow-y-auto"} aria-labelledby="modal-title" role="dialog" aria-modal="true" id='modal'>
              <div className="flex items-end justify-center min-h-screen pt-4 px-4 md:pb-20 pb-96 text-center sm:block sm:p-0">
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle ">
                <div className="flex justify-center items-center p-5">
                    <div id='spinner' className={spinner+" animate-spin rounded-full h-20 w-20 border-t-4 border-b-4 border-purple-500"}></div>
                    <div id='responseTrue' className={responseTrue+' relative px-5 py-8 ObjectiveMedium text-lg'} onClick={closeModal}>
                        <div className='pb-2 pl-4 pt-5 pr-4 font-bold cursor-pointer bg-blueGray-700 -top-8 -right-5 absolute text-white'>X</div>
                        <div>Gentile cliente la richiesa di contatto è stata inviata </div>
                        <div> Verrà contattato nel minor tempo possibile</div>
                    </div>
                    <div id='responseFalse' className={responseFalse+' relative px-5 py-8 ObjectiveMedium text-lg'} onClick={closeModal}>
                        <div className='pb-2 pl-4 pt-5 pr-4 font-bold cursor-pointer bg-blueGray-700 -top-8 -right-5 absolute text-white'>X</div>
                        <div>Gentile cliente c'è stato un problema nell'invio della sua richiesta </div>
                        <div> La preghiamo di riprovare più tardi</div>
                    </div>
                </div>
                </div>
              </div>
            </div>
        
    </div>
  );
}

export default Contatti;