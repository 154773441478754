import React, { useEffect,useState } from "react";
import { Helmet } from 'react-helmet-async';
import { Link } from "react-router-dom";
import Footer from './Footer'
import Header from './Header'
import {IoMdArrowDropdownCircle} from 'react-icons/io'
import {IoMdArrowDropupCircle} from 'react-icons/io'
import CookieConsent from "react-cookie-consent";
import useInput from "./useInput";
import { getFunctions, httpsCallable } from "firebase/functions";

//import { useSpring, animated } from 'react-spring'

function Preventivi(){
    //const [currentUser, setCurrentUser] = useState(null);
    const functions = getFunctions();
    
    //let url = new URL(window.location.href);
    const [redNome, setRedNome] = useState('')
    const [redEmail, setRedEmail] = useState('')
    const [redTel, setRedTel] = useState('')
    const [redNomeCon, setRedNomeCon] = useState('')
    const [redIndirizzo, setRedIndirizzo] = useState('')
    const [redCitta, setRedCitta] = useState('')

    const [redNomeA, setRedNomeA] = useState('')
    const [redEmailA, setRedEmailA] = useState('')
    const [redTelA, setRedTelA] = useState('')

    const [modal, setModal] = useState('hidden')
    const [spinner, setSpinner] = useState('hidden')
    const [responseTrue, setResponseTrue] = useState('hidden')
    const [responseFalse, setResponseFalse] = useState('hidden')

    const email = useInput("");
    const tel = useInput("");
    const nome = useInput("");
    const nomeCon = useInput("");
    const indirizzo = useInput("");
    const citta = useInput("");
    const annoCost = useInput("");
    const numeroApp = useInput("");
    const numeroPiani = useInput("");
    const [riscaldamento, setRiscaldamento] = useState(false)
    const [ascensore, setAscensore] = useState(false)
    const [piuEdifici, setPiuEdifici] = useState(false)
    const [pianoInterrato, setPianoInterrato] = useState(false)
    const messaggio = useInput("");
    const terms = useInput(false);
    const verifica = useInput(false);

    const emailA = useInput("");
    const telA = useInput("");
    const nomeA = useInput("");
    const messaggioA = useInput("");
    const termsA = useInput(false);
    const verificaA = useInput(false);

    const [prevCond, setPrevCond] = useState('py-0 h-0')
    const [prevArch, setPrevArch] = useState('py-0 h-0')
    const [frecciaGiuCond, setFrecciaGiuCond] = useState('block')
    const [frecciaSuCond, setFrecciaSuCond] = useState('hidden')
    const [frecciaGiuArch, setFrecciaGiuArch] = useState('block')
    const [frecciaSuArch, setFrecciaSuArch] = useState('hidden')

    const invioMailArch = (nome, email, tel, messaggio) => {
        setModal('')
        setSpinner('')
        const sendEmail = httpsCallable(functions, 'sendEmail');
        sendEmail({ 
            "email": 'segreteria@studioballon.it',
            "subject": "Nuova Richiesta di Preventivo per i Servizi di Architettura",
            "message": "È arrivata una nuova richiesta di preventivo tramite studioballon.it per i servizi di Architettura, \n \n"+
            "Nome Mittente: "+nome+' \n \n'+
            "Email Mittente: "+email+' \n \n'+
            "Telefono Mittente: "+tel+' \n \n'+
            "Messaggio: "+messaggio+' \n \n'
        })
        .then((result) => {
            setSpinner('hidden')
            setResponseTrue('')
            
        })
        .catch((e) => {
            setSpinner('hidden')
            setResponseFalse('')
        });
    }
    const invioMailCond = (data) => {
        setModal('')
        setSpinner('')
        let riscaldamento = 'NO'
        if(data[9]) riscaldamento = 'SI'
        let ascensore = 'NO'
        if(data[10]) ascensore = 'SI'
        let piuEdifici = 'NO'
        if(data[11]) piuEdifici = 'SI'
        let pianoInterrato = 'NO'
        if(data[12]) pianoInterrato = 'SI'
        const sendEmail = httpsCallable(functions, 'sendEmail');
        sendEmail({ 
            "email": 'segreteria@studioballon.it',
            "subject": "Nuova Richiesta di Preventivo per Gestione Condominio",
            "message": "È arrivata una nuova richiesta di preventivo tramite studioballon.it per Gestione Condominio, \n \n"+
            "Nome Mittente: "+data[0]+' \n '+
            "Email Mittente: "+data[1]+'  \n'+
            "Telefono Mittente: "+data[2]+'  \n'+
            "Nome del Condominio: "+data[3]+'  \n'+
            "Indirizzo del Condominio: "+data[4]+'  \n'+
            "Città: "+data[5]+'  \n'+
            "Anno di Costruzione: "+data[6]+'  \n'+
            "Numero Appartamenti: "+data[7]+'  \n'+
            "Numero Piani: "+data[8]+'  \n'+
            "Riscaldamento Centralizzato: "+riscaldamento+'  \n'+
            "Ascensore: "+ascensore+'  \n'+
            "Più Edifici Condominiali: "+piuEdifici+'  \n'+
            "Garage Interrati: "+pianoInterrato+'  \n'+
            "Messaggio: "+data[13]+'  \n'
        })
        .then((result) => {
            setSpinner('hidden')
            setResponseFalse('hidden')
            setResponseTrue('')
        })
        .catch((e) => {
            setSpinner('hidden')
            setResponseTrue('hidden')
            setResponseFalse('')
        });
    }

    const sendMessageCond = (event) => {
        event.preventDefault()
        if(!verifica.value) {
            if(nome.value.trim() !== '' && email.value.trim() !== '' && tel.value.trim() !== '' && nomeCon.value.trim() !== '' && indirizzo.value.trim() !== '' && citta.value.trim() !== ''){
                setRedNome('')
                setRedEmail('')
                setRedTel('')
                setRedNomeCon('')
                setRedIndirizzo('')
                setRedCitta('')
                invioMailCond([nome.value, email.value, tel.value, nomeCon.value, indirizzo.value, citta.value, annoCost.value, numeroApp.value, numeroPiani.value, riscaldamento, ascensore, piuEdifici, pianoInterrato, messaggio.value])
            } else {
                if(nome.value.trim() === '') {
                    setRedNome('bg-red-50 border border-red-500')
                } else {
                    setRedNome('')
                }
                if(email.value.trim() === '') {
                    setRedEmail('bg-red-50 border border-red-500')
                } else {
                    setRedEmail('')
                }
                if(tel.value.trim() === '') {
                    setRedTel('bg-red-50 border border-red-500')
                } else {
                    setRedTel('')
                }
                if(nomeCon.value.trim() === '') {
                    setRedNomeCon('bg-red-50 border border-red-500')
                } else {
                    setRedNomeCon('')
                }
                if(indirizzo.value.trim() === '') {
                    setRedIndirizzo('bg-red-50 border border-red-500')
                } else {
                    setRedIndirizzo('')
                }
                if(citta.value.trim() === '') {
                    setRedCitta('bg-red-50 border border-red-500')
                } else {
                    setRedCitta('')
                }
            }
        }
    }
    const sendMessageArch = (event) => {
        event.preventDefault()
        if(!verificaA.value) {
            if(nomeA.value.trim() !== '' && emailA.value.trim() !== '' && telA.value.trim() !== ''){
                setRedNomeA('')
                setRedEmailA('')
                setRedTelA('')
                invioMailArch(nomeA.value, emailA.value, telA.value, messaggioA.value)
            } else {
                if(nomeA.value.trim() === '') {
                    setRedNomeA('bg-red-50 border border-red-500')
                } else {
                    setRedNomeA('')
                }
                if(emailA.value.trim() === '') {
                    setRedEmailA('bg-red-50 border border-red-500')
                } else {
                    setRedEmailA('')
                }
                if(telA.value.trim() === '') {
                    setRedTelA('bg-red-50 border border-red-500')
                } else {
                    setRedTelA('')
                }
            }
        }
    }

    const showPrevCond = (e) => {
        e.stopPropagation();
        if(prevCond === 'py-0 h-0') {
            setFrecciaGiuCond('hidden')
            setFrecciaSuCond('block')
            setPrevCond('py-5 h-auto') 
        } else {
            setFrecciaGiuCond('block')
            setFrecciaSuCond('hidden')
            setPrevCond('py-0 h-0')
        }
    }
    const showPrevArch = () => {
        if(prevArch === 'py-0 h-0') {
            setFrecciaGiuArch('hidden')
            setFrecciaSuArch('block')
            setPrevArch('py-5 h-auto') 
        } else {
            setFrecciaGiuArch('block')
            setFrecciaSuArch('hidden')
            setPrevArch('py-0 h-0')
        }
    }

    const closeModal = () => {
        setSpinner('hidden')
        setResponseTrue('hidden')
        setResponseFalse('hidden')
        setModal('hidden')
        document.getElementById('formMessaggioArch').reset()
        document.getElementById('formMessaggioCond').reset()
    }

    

    


    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    
    

  //const props = useSpring({ to: { opacity: 1 }, from: { opacity: 0 } })
  


  

  return (
    <div className=''>
        <Helmet>
            <html lang="it" />
            <title>Studio Ballon - Preventivi</title>
            <meta name="description" content="Studio Ballon - Richiedi un preventivo per la gestione del tuo condominio" />
            <meta name="theme-color" content="#E6E6FA" />
            <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1'/>
            <meta property="og:locale" content="it_IT"/>
            <meta property="og:title" content="Studio Ballon - Preventivi"/>
            <meta property="og:description" content="Studio Ballon - Richiedi un preventivo per la gestione del tuo condominio"/>
            <meta property="og:url" content="https://studioballon.it/preventivi/"/>
            <meta property="og:site_name" content="studioballon.it"/>
            <meta property="article:modified_time" content="2022-03-21T10:32:09+00:00"/>
            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:label1" content="Tempo di lettura stimato"/>
            <meta name="twitter:data1" content="3 minuti"/>
        </Helmet>
        
        <Header />
        <div className='md:py-48 py-20   bg-cover'  style={{ backgroundImage: `url("preventivi.jpg")` }}>
            <div className='md:text-8xl text-4xl text-white ObjectiveBold text-center'>PREVENTIVI</div>
        </div>
        <div className=' bg-white py-10 text-blueGray-700'>
        <div className='lg:w-2/4 md:w-3/4 px-2 mx-auto'>
            <div className='relative mx-5 md:mx-auto p-5 my-5  bg-blueGray-200 rounded-xl shadow-lg  hover:shadow-2xl  ' >
                <div className='absolute top-0 right-0 mt-2 mr-2  cursor-pointer text-blueGray-500 hover:text-blueGray-700' onClick={showPrevCond}>
                    <IoMdArrowDropdownCircle className={frecciaGiuCond+' h-10 w-10'} />
                    <IoMdArrowDropupCircle className={frecciaSuCond+' h-10 w-10'} />
                </div>
                <div className='ObjectiveBold md:text-3xl text-xl  text-center'>Richiedi un<br/> Preventivo per il Tuo Condominio</div>
                <form className={prevCond+' md:px-10 overflow-hidden'} id='formMessaggioCond' onSubmit={sendMessageCond}>
                    <div className='text-sm text-red-500 mb-2'>* Campi obbligatori</div>
                    <label><span className='text-red-500 font-bold mr-2'>*</span>Nome e Cognome</label>
                    <input  className={redNome+" w-full h-10 px-3 mb-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline"} type="text" id="nome" {...nome}/>
                    <label><span className='text-red-500 font-bold mr-2'>*</span>Email</label>
                    <input  className={redEmail+" w-full h-10 px-3 mb-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline"} type="email" id="email" {...email}/>
                    <label><span className='text-red-500 font-bold mr-2'>*</span>Telefono</label>
                    <input  className={redTel+" w-full h-10 px-3 mb-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline"} type="tel" id="tel" {...tel}/>
                    <label><span className='text-red-500 font-bold mr-2'>*</span>Nome del Condominio</label>
                    <input  className={redNomeCon+" w-full h-10 px-3 mb-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline"} type="text" id="nomeCon" {...nomeCon}/>
                    <label><span className='text-red-500 font-bold mr-2'>*</span>Indirizzo del Condominio</label>
                    <input  className={redIndirizzo+" w-full h-10 px-3 mb-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline"} type="text" id="indirizzo" {...indirizzo}/>
                    <label><span className='text-red-500 font-bold mr-2'>*</span>Città</label>
                    <input  className={redCitta+" w-full h-10 px-3 mb-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline"} type="text" id="citta" {...citta}/>
                    <label>Anno di Costruzione</label>
                    <input  className={" w-full h-10 px-3 mb-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline"} type="text" id="annoCost" {...annoCost}/>
                    <label>Numero Appartamenti</label>
                    <input  className={" w-full h-10 px-3 mb-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline"} type="text" id="numeroApp" {...numeroApp}/>
                    <label>Numero Piani del Condominio</label>
                    <input  className={" w-full h-10 px-3 mb-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline"} type="text" id="numeroPiani" {...numeroPiani}/>
                    <div className="flex items-start mb-6">
                        <div className="flex items-center h-5">
                            <input id="riscaldamento" aria-describedby="riscaldamento" type="checkbox" className="bg-gray-50 border border-gray-300 focus:ring-3 focus:ring-blue-300 h-4 w-4 rounded dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800"  checked={riscaldamento} onChange={e => setRiscaldamento(e.target.checked)} />
                        </div>
                        <div className="text-sm ml-3">
                            <label htmlFor="riscaldamento" className=" md:text-lg text-gray-800 ">C'è il riscaldamento centralizzato?</label>
                        </div>
                    </div>
                    <div className="flex items-start mb-6">
                        <div className="flex items-center h-5">
                            <input id="ascensore" aria-describedby="ascensore" type="checkbox" className="bg-gray-50 border border-gray-300 focus:ring-3 focus:ring-blue-300 h-4 w-4 rounded dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800"   checked={ascensore} onChange={e => setAscensore(e.target.checked)} />
                        </div>
                        <div className="text-sm ml-3">
                            <label htmlFor="ascensore" className="md:text-lg text-gray-800">C'è l'ascensore?</label>
                        </div>
                    </div>
                    <div className="flex items-start mb-6">
                        <div className="flex items-center h-5">
                            <input id="piuEdifici" aria-describedby="piuEdifici" type="checkbox" className="bg-gray-50 border border-gray-300 focus:ring-3 focus:ring-blue-300 h-4 w-4 rounded dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800"   checked={piuEdifici} onChange={e => setPiuEdifici(e.target.checked)} />
                        </div>
                        <div className="text-sm ml-3">
                            <label htmlFor="piuEdifici" className="md:text-lg text-gray-800">Il condominio si divide in più edifici?</label>
                        </div>
                    </div>
                    <div className="flex items-start mb-6">
                        <div className="flex items-center h-5">
                            <input id="pianoInterrato" aria-describedby="pianoInterrato" type="checkbox" className="bg-gray-50 border border-gray-300 focus:ring-3 focus:ring-blue-300 h-4 w-4 rounded dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800"   checked={pianoInterrato} onChange={e => setPianoInterrato(e.target.checked)} />
                        </div>
                        <div className="text-sm ml-3">
                            <label htmlFor="pianoInterrato" className="md:text-lg text-gray-800">Esiste un piano interrato adibito ad autorimessa?</label>
                        </div>
                    </div>
                    <textarea placeholder='Lascia un messaggio...' rows='12' className={" w-full  px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline"} id='messaggio' {...messaggio}></textarea>
                    <div className="flex items-start mb-6">
                        <div className="flex items-center h-5">
                            <input id="terms" aria-describedby="terms" type="checkbox" className="bg-gray-50 border border-gray-300 focus:ring-3 focus:ring-blue-300 h-4 w-4 rounded dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800" required  value="false"  {...terms} />
                        </div>
                        <div className="text-sm ml-3">
                            <label htmlFor="terms" className="font-medium text-gray-900 dark:text-gray-300">Acconsenti all'archiviazione e alla gestione dei tuoi dati da parte dello Studio Ballon.</label>
                        </div>
                    </div>
                    <div className='hidden'>
                        <label className="text-gray-700">
                            <input type="checkbox"  value="false"  {...verifica}/>
                            <span className="ml-1">Verifica</span>
                        </label>
                    </div>
                    <button type="submit" className='h-10 px-5 mt-4 text-indigo-100 transition-colors duration-150 bg-indigo-700 rounded-lg focus:shadow-outline hover:bg-indigo-800'>Aggiungi</button>
                </form>
            </div>
            <div className=' relative mx-5 md:mx-auto p-5 my-5  bg-blueGray-200 rounded-xl shadow-lg  hover:shadow-2xl'>
                <div className='absolute top-0 right-0 mt-2 mr-2  cursor-pointer text-blueGray-500 hover:text-blueGray-700' onClick={showPrevArch}>
                    <IoMdArrowDropdownCircle className={frecciaGiuArch+' h-10 w-10'} />
                    <IoMdArrowDropupCircle className={frecciaSuArch+' h-10 w-10'} />
                </div>
                <div className='ObjectiveBold md:text-3xl text-xl text-center'>Richiedi un<br/> Preventivo per i servizi di Architettura</div>
                <form className={prevArch+' md:px-10 overflow-hidden'} id='formMessaggioArch' onSubmit={sendMessageArch}>
                    <div className='text-sm text-red-500 mb-2'>* Campi obbligatori</div>
                    <label><span className='text-red-500 font-bold mr-2'>*</span>Nome e Cognome</label>
                    <input  className={redNomeA+" w-full h-10 px-3 mb-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline"} type="text" id="nomeA" {...nomeA}/>
                    <label><span className='text-red-500 font-bold mr-2'>*</span>Email</label>
                    <input  className={redEmailA+" w-full h-10 px-3 mb-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline"} type="email" id="emailA" {...emailA}/>
                    <label><span className='text-red-500 font-bold mr-2'>*</span>Telefono</label>
                    <input  className={redTelA+" w-full h-10 px-3 mb-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline"} type="tel" id="telA" {...telA}/>
                    <textarea placeholder='La tua richiesta...' rows='12' className={" w-full  px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline"} id='messaggioA' {...messaggioA}></textarea>
                    <div className="flex items-start mb-6">
                        <div className="flex items-center h-5">
                            <input id="termsA" aria-describedby="terms" type="checkbox" className="bg-gray-50 border border-gray-300 focus:ring-3 focus:ring-blue-300 h-4 w-4 rounded dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800" required  value="false"  {...termsA} />
                        </div>
                        <div className="text-sm ml-3">
                            <label htmlFor="termsA" className="font-medium text-gray-900 dark:text-gray-300">Acconsenti all'archiviazione e alla gestione dei tuoi dati da parte dello Studio Ballon.</label>
                        </div>
                    </div>
                    <div className='hidden'>
                        <label className="text-gray-700">
                            <input type="checkbox"  value="false"  {...verificaA}/>
                            <span className="ml-1">Verifica</span>
                        </label>
                    </div>
                    <button type="submit" className='h-10 px-5 mt-4 text-indigo-100 transition-colors duration-150 bg-indigo-700 rounded-lg focus:shadow-outline hover:bg-indigo-800'>Aggiungi</button>
                </form>
            </div>
        </div>
    </div>
        

        
        <Footer />
        <CookieConsent
            acceptOnScroll={true}
            acceptOnScrollPercentage={50}
            onAccept={(byScroll) => {
            }}
            location="bottom"
            buttonText="OK"
            cookieName="myAwesomeCookieName2"
            style={{ background: "#2B373B" }}
            buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
            expires={150}
            >
            <span style={{ fontSize: "12px" }}>Questo sito utilizza cookie tecnici, analitici e di profilazione, anche di terze parti, necessari rispettivamente, a consentire alcune funzionalità fondamentali per il sito, ad effettuare misurazioni delle performances del sito stesso o a fornirti indicazioni promozionali in linea con i tuoi interessi.

Per avere maggiori informazioni e scoprire come negare in tutto o in parte i tuoi consensi, consulta la <Link to='/PrivacyCookie' className='underline'>cookie policy</Link>.</span>
        </CookieConsent>

        {/* Modal */}
            <div className={modal+" fixed z-10 inset-0 overflow-y-auto"} aria-labelledby="modal-title" role="dialog" aria-modal="true" id='modal'>
              <div className="flex items-end justify-center min-h-screen pt-4 px-4 md:pb-20 pb-96 text-center sm:block sm:p-0">
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle ">
                <div className="flex justify-center items-center p-5">
                    <div id='spinner' className={spinner+" animate-spin rounded-full h-20 w-20 border-t-4 border-b-4 border-purple-500"}></div>
                    <div id='responseTrue' className={responseTrue+' relative px-5 py-8 ObjectiveMedium text-lg'} onClick={closeModal}>
                        <div className='pb-2 pl-4 pt-5 pr-4 font-bold cursor-pointer bg-blueGray-700 -top-8 -right-5 absolute text-white'>X</div>
                        <div>Gentile cliente la richiesa di preventivo è stata inviata </div>
                        <div> Verrà contattato nel minor tempo possibile</div>
                    </div>
                    <div id='responseFalse' className={responseFalse+' relative px-5 py-8 ObjectiveMedium text-lg'} onClick={closeModal}>
                        <div className='pb-2 pl-4 pt-5 pr-4 font-bold cursor-pointer bg-blueGray-700 -top-8 -right-5 absolute text-white'>X</div>
                        <div>Gentile cliente c'è stato un problema nell'invio della sua richiesta </div>
                        <div> La preghiamo di riprovare più tardi</div>
                    </div>
                </div>
                </div>
              </div>
            </div>
        
    </div>
  );
}

export default Preventivi;