import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore"
import { getStorage } from "firebase/storage";
import { getFunctions} from 'firebase/functions';
import { getAnalytics, logEvent } from "firebase/analytics";

// If you're not using Code Sandbox, never hard-code the keys! Add them in your .env file and link them here
/* var firebaseConfig = {
    apiKey: "AIzaSyAgNAgHUoKnfVxJkRUSDgE2tQDu0QbtaDs",
    authDomain: "prova-3365e.firebaseapp.com",
    projectId: "prova-3365e",
    storageBucket: "prova-3365e.appspot.com",
    messagingSenderId: "870433160730",
    appId: "1:870433160730:web:5850236767bbf42b721ba3",
    measurementId: "G-308NZV91FH"
}; */

const firebaseConfig = {
  apiKey: "AIzaSyBjTG9p_f1DZ2lKsPFxo_iZ8FMztXgxuGw",
  authDomain: "studioballon-4f3e8.firebaseapp.com",
  projectId: "studioballon-4f3e8",
  storageBucket: "studioballon-4f3e8.appspot.com",
  messagingSenderId: "214483276839",
  appId: "1:214483276839:web:a8943c200070f9ee2fea02",
  measurementId: "G-V9R4VFGV2S"
};

// Initialize Firebase
let instance;

export default function getFirebase() {
  if (typeof window !== "undefined") {
    //if (instance) return instance;
    instance = initializeApp(firebaseConfig);
    const analytics = getAnalytics(instance);
    logEvent(analytics, 'notification_received');
    //console.log(instance);
    const auth = getAuth(instance);
    const db = getFirestore();
    const storage = getStorage(instance);
    const functions = getFunctions(instance);
    //const db = instance.firestore();
    //console.log(auth);
    return {instance, auth, db, storage, functions, analytics};
  }

  return null;
}
