import React, { useState, useEffect } from "react";
//import { Helmet } from "react-helmet";
import { Helmet } from 'react-helmet-async';
import SignOutButton from "../SignOutButton";
import EliminaCondominio from './EliminaCondominio'
import EliminaClienteCondominio from './EliminaClienteCondominio'
import AddCliente from './AddCliente'
import AddClientiParticella from './AddClientiParticella'
import EliminaClienteParticella from './EliminaClienteParticella'
import { MultiSelect } from "react-multi-select-component";
import Header from '../Header'
import Footer from '../Footer'
//import AddClienteForm from "./AddClienteForm";
//import ListaFiles from "./ListaFiles";
import getFirebase from "../firebase";
//import { getAuth,  } from "firebase/auth";
import { doc, getDoc, setDoc, getDocs, query, orderBy, addDoc, collection, where, deleteDoc, serverTimestamp, updateDoc } from "firebase/firestore";
import { useHistory } from "react-router-dom";
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject  } from "firebase/storage";
import { getFunctions, httpsCallable } from "firebase/functions";
//import { getAuth, sendPasswordResetEmail } from "firebase/auth";
//import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";

//import Geocode from "react-geocode";





function Amministratore() {
  //localStorage.clear();
  //const auth = getAuth();
  /* if(window.google) {
    //alert('ok')
  } else {
    return <LoadScript
        googleMapsApiKey='AIzaSyBjTG9p_f1DZ2lKsPFxo_iZ8FMztXgxuGw'></LoadScript>
  } */
  

  let url = new URL(window.location.href);
  const urlId = url.searchParams.get("id");
  let urlAnno = url.searchParams.get("anno");
  const d = new Date();
  let anno = d.getFullYear();

  let modal = document.getElementById("modal");
  const [btnNome, setBtnNome] = useState('');
  const [btnId, setBtnId] = useState('');
  const [btnEmail, setBtnEmail] = useState('')
  const [btnTitolo, setBtnTitolo] = useState('');
  const [btnTesto, setBtnTesto] = useState('');

  const history = useHistory();
  const firebase = getFirebase();
  const storage = getStorage();
  //const utente = firebase.auth.currentUser;

  const functions = getFunctions();

  const [nomeCon, setNomeCon] = useState(null);
  const [via, setVia] = useState(null);
  const [indirizzo, setIndirizzo] = useState(null);
  const [civico, setCivico] = useState(null);
  const [cap, setCap] = useState(null);
  const [citta, setcitta] = useState(null);

  const [file, setFile] = useState(null);
  const [nessunRisultato, setNessunRisultato] = useState('');
  const [nessunRisultatoReverse, setNessunRisultatoReverse] = useState('');
  const [annoSuc, setAnnoSuc] = useState('');
  const [annoPrec, setAnnoPrec] = useState('');
  const [allParticelle, setAllParticelle] = useState([])
  const [allParticelleSelect, setAllParticelleSelect] = useState([])
  //const [inserimentoParticelleVecchie, setInserimentoParticelleVecchie] = useState([])
  const [inserimentoParticelleNuove, setInserimentoParticelleNuove] = useState([])
  const [selected, setSelected] = useState([]);
  const [allFiles, setAllFiles] = useState([]);
  const [allClienti, setAllClienti] = useState([]);
  const [allParticelleDisplay, setAllParticelleDisplay] = useState([])
  //const [allClientiDisplay, setAllClientiDisplay] = useState([]);
  const [allIdentificativi, setAllIdentificativi] = useState([])
  //const [allTipi, setAllTipi] = useState([])
  //const [allTipiDisplay, setAllTipiDisplay] = useState([])
  const [allEmail, setAllEmail] = useState([])
  const [allCognomiDisplay, setAllCognomiDisplay] = useState([])
  const [allCognome, setAllCognome] = useState([])
  /* const [allProprietari, setAllProprietari] = useState([])
  const [allEntrambi, setAllEntrambi] = useState([])
  const [allInquilini, setAllInquilini] = useState([]) */
  const [nomeFile, setnomeFile] = useState('Carica un file (max 5MB)');

  const [urlFile, setUrlFile] = useState('')


  const [redNome, setRedNome] = useState('')
  const [redLabelNome, setRedLabelNome] = useState('')
  const [redCognome, setRedCognome] = useState('')
  const [redLabelCognome, setRedLabelCognome] = useState('')
  const [redEmail, setRedEmail] = useState('')
  const [redLabelEmail, setRedLabelEmail] = useState('')
  //const [redTipo, setRedTipo] = useState('')
  //const [redLabelTipo, setRedLabelTipo] = useState('') 
  const [redGetEmail, setRedGetEmail] = useState('')
  const [redGetLabelEmail, setRedGetLabelEmail] = useState('')
  /* const [redGetTipo, setRedGetTipo] = useState('')
  const [redGetLabelTipo, setRedGetLabelTipo] = useState('') */
  //const [redGetTipoCognome, setRedGetTipoCognome] = useState('')
  const [redGetCognome, setRedGetCognome] = useState('')
  const [redGetLabelCognome, setRedGetLabelCognome] = useState('')
  const [redIdentificativoCliente, setRedIdentificativoCliente] = useState('')
  const [redLabelIdentificativoCliente, setRedLabelIdentificativoCliente] = useState('')
  const [redNewIdentificativoCatastale, setRedNewIdentificativoCatastale] = useState('')
  const [redLabelNewIdentificativoCatastale, setRedLabelNewIdentificativoCatastale] = useState('')
  const [redParticelleAggiunte, setRedParticelleAggiunte] = useState('')

  //const [cssLabelNuovoCliente2, setCssLabelNuovoCliente2] = useState('text-white bg-indigo-500')
  //const [cssLabelGiaCliente, setCssLabelGiaCliente] = useState('text-indigo-500 bg-white')

  //const [redParticella, setRedParticella] = useState('')
  //const [redLabelParticella, setRedLabelParticella] = useState('')
  const [cssLabelNuovoCliente, setCssLabelNuovoCliente] = useState('text-white bg-indigo-500')
  const [cssLabelClientiEsistenti, setCssLabelClientiEsistenti] = useState('text-indigo-500 bg-white')

  const [checked1, setChecked1] = useState(true)
  const [checked2, setChecked2] = useState(false)
  const [checked3, setChecked3] = useState(true)
  const [checked4, setChecked4] = useState(false)
  

  /* Geocode.setApiKey("AIzaSyBjTG9p_f1DZ2lKsPFxo_iZ8FMztXgxuGw");
  Geocode.setLanguage("it");
  Geocode.setRegion("it");
  Geocode.setLocationType("ROOFTOP"); */



  /* if(!utente) {
    history.replace("/");
  } else {
    //setCurrentUser(utente.email)
  } */

  //const [anno, setAnno] = useState(null);
  
  //console.log(utente);

  
  /* const aggiornaCoordinate = async (lat, lng) => {
    try {
        const docRef = doc(firebase.db, "condomini", urlId);
        await updateDoc(docRef, {
            lat: lat,
            lng: lng
        });
    } catch(e) {
        //console.log('a'+e)
    }
  } */
      
  const getCondominio = async () => {
    try {
      const docRef = doc(firebase.db, "condomini", urlId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setNomeCon(docSnap.data().nome);
        setVia(docSnap.data().via);
        setIndirizzo(docSnap.data().indirizzo);
        setCivico(docSnap.data().civico);
        setCap(docSnap.data().cap);
        setcitta(docSnap.data().citta);
        /* if(!docSnap.data().lat) {
          Geocode.fromAddress(docSnap.data().via+' '+docSnap.data().indirizzo+' '+docSnap.data().civico+' '+docSnap.data().cap+' '+docSnap.data().citta).then(
            (response) => {
              const { lat, lng } = response.results[0].geometry.location;
              aggiornaCoordinate(lat, lng)
            },
            (error) => {
              console.log(error);
            }
          );
        } */
      }
      else {
        /* alert("Nessuna corrispondenza");
        history.replace('/amministratori/home'); */
      }
    } catch (e) {
      /* if(utente) {
        addDoc(collection(firebase.db, "errori"), {
          nome: 'Ricerca Condominio',
          timestamp: serverTimestamp(),
          dati: e
        });
      } else {
        history.replace("/");
      } */
      
    }
    listaFiles(urlAnno);
    let a = ''
    listaParticelle(a);
    listaAllParticelle()
    listaClienti();
    window.scrollTo(0, 0)
  }

  const eliminaCondominio = () => {
    const result = EliminaCondominio(urlId)
    if(result) {
      alert("Il condominio è stato eliminato!")
      
      
      
      history.replace("/amministratori/home")
    } else {
      alert("Impossibile eliminare il condominio! Contattare l'amministratore di sistema")
    }
  }
  
  const eliminaClienteCondominio = (email, urlId) => {
    if(EliminaClienteCondominio(email, urlId)) {
      alert("Il cliente è stato eliminato!")
      //history.goBack()
      //history.push("/amministratori/home")
      let clienti = []
      allClienti.forEach((item) => {
        if(item.email !== email) {
          clienti.push(item)
        }
      })
      setAllClienti(clienti)
      /* let email2 = []
      allEmail.forEach((item) => {
        if(item.email != email) {
          email2.push(item)
        }
      })
      setAllEmail(email2) */
      if(clienti.length === 0) {
        document.getElementById('numeroClienti').style.display='block'
      }
      /* let identificativi = []
      clienti.forEach((item) => {
        identificativi.push(item.identificativoCliente)
      })
      identificativi = identificativi.sort()
      setAllIdentificativi(identificativi) */
      
    } else {
      alert("Impossibile eliminare il cliente! Contattare l'amministratore di sistema")
    }
    modal.style.display = "none";
  }

  const eliminaClienteParticella = (email, idParticella) => {
    if(EliminaClienteParticella(email, idParticella)) {
      alert("Il cliente è stato eliminato!")
      //history.goBack()
      //history.push("/amministratori/home")
      
      let clienti = []
      allClienti.forEach((item) => {
        if(item.email === email && item.particella === idParticella) {
        } else {
          clienti.push(item)
        }
      })
      setAllClienti(clienti)
    } else {
      alert("Impossibile eliminare il cliente! Contattare l'amministratore di sistema")
    }
    modal.style.display = "none";
  }

  const cambiaTipo = async (id, e, email) => {
    e.preventDefault()
    if(e.target[1].value !== '') {
      let tipo = Capitalize(e.target[1].value)
      let tipoSingle = ''
      switch (tipo) {
        case 'Entrambi':
          tipoSingle = 'P'
          break;
        case 'Proprietario':
          tipoSingle = 'P'
          break;
        case 'Inquilino':
          tipoSingle = 'I'
          break;
      }
      const docRef = doc(firebase.db, "clientiParticella", id);
      await updateDoc(docRef, {
        tipo: tipo,
        tipoSingle: tipoSingle
      });
      let clienti = []
      allClienti.forEach((item) => {
        if(item.email === email && item.idClientiParticella === id) {
          clienti.push({nome: item.nome, cognome: item.cognome, email: item.email, pec: item.pec, tel: item.tel, tipo: tipo, tipoSingle: tipoSingle, nomePart: item.nomePart, particella: item.particella, identificativoCliente: item.identificativoCliente, id: item.id, idClientiParticella: item.idClientiParticella})
        } else {
          clienti.push(item)
        }
      })
      setAllClienti(clienti)
    }
    
    
  }

  const showModal = (tipo, nome, id, email) => {
    if(tipo === 'file') {
      document.getElementById("btnFile").style.display = "block";
      document.getElementById("btnCondominio").style.display = "none";
      document.getElementById("btnCliente").style.display = "none";
      document.getElementById("btnParticella").style.display = "none";
      setBtnNome(nome);
      setBtnId(id);
      setBtnTitolo('Eliminazione file');
      setBtnTesto('Sei sicuro di eliminare il file? Una volta eliminato il file non potrà essere recuperato!')
    } else if(tipo === 'condominio') {
      document.getElementById("btnFile").style.display = "none";
      document.getElementById("btnCondominio").style.display = "block";
      document.getElementById("btnCliente").style.display = "none";
      document.getElementById("btnParticella").style.display = "none";
      setBtnTitolo('Eliminazione condominio');
      setBtnTesto('Sei sicuro di eliminare il condominio? Una volta eliminato, tutti i dati e i file del condominio e dei condomini non potranno più essere recuperati!')
    } else if(tipo === 'cliente') {
      document.getElementById("btnFile").style.display = "none";
      document.getElementById("btnCondominio").style.display = "none";
      document.getElementById("btnCliente").style.display = "block";
      document.getElementById("btnParticella").style.display = "none";
      setBtnNome(nome);
      setBtnEmail(email)
      setBtnTitolo('Eliminazione cliente dal condominio');
      setBtnTesto('Sei sicuro di eliminare il cliente dal condominio? Una volta eliminato, tutti i dati e i file del cliente per questo condominio non potranno più essere recuperati!')
    }else if(tipo === 'particella') {
      document.getElementById("btnFile").style.display = "none";
      document.getElementById("btnCondominio").style.display = "none";
      document.getElementById("btnCliente").style.display = "none";
      document.getElementById("btnParticella").style.display = "block";
      setBtnId(id);
      setBtnEmail(email)
      setBtnTitolo('Eliminazione cliente dalla particella');
      setBtnTesto('Sei sicuro di eliminare il cliente dalla particella? Una volta eliminato, tutti i dati e i file del cliente per questa particella non potranno più essere recuperati!')
    }
    modal.style.display = "block";
  }

  const listaParticelle = async (a) => {
    try {
      //setAllParticelle([]);
      setAllParticelleSelect([])
      const getParticelle = collection(firebase.db, "particelle");
      const q = query(getParticelle, where('condominio', '==', urlId), orderBy("particella"));
      let querySnapshot = await getDocs(q);
      if(querySnapshot.empty) {document.getElementById('numeroParticelle').style.display='block'}
      querySnapshot.forEach((doc) => {
        //setAllClienti(oldArray => [...oldArray, {id: doc.id, particella: doc.data().particella, nome: doc.data().nome, email: doc.data().email, pec: doc.data().pec, cognome: doc.data().cognome, tipo: doc.data().tipo}]);
        //setAllParticelle(oldArray => [...oldArray, {id: doc.id, particella: doc.data().particella, condominio: doc.data().condominio}]);
        if(!a.includes(doc.data().particella)) {
          setAllParticelleSelect(oldArray => [...oldArray, {label: doc.data().particella, value: doc.id, new: 'false'}]);
        }
      });
    } catch(e) {
      /* if(utente) {
        addDoc(collection(firebase.db, "errori"), {
          nome: 'Lista Particelle',
          timestamp: serverTimestamp(),
          dati: e
        });
      } else {
        history.replace("/");
      } */
      //alert("Errore del database: "+e);
    }
  }

  const addParticella = async (urlId, nomeCon, particella, email, identificativoCliente, tipo) => {
    //setRedParticella('')
    //const particella = event.target[0].value.toLowerCase().replace(/ /g, "");
    let idParticella = ''
    const getParticella= collection(firebase.db, "particelle");
    const q = query(getParticella, where('condominio', '==', urlId), where('particella', '==', particella));
    let querySnapshot = await getDocs(q);
    if(querySnapshot.empty) {
      if(particella){
        try {
          const docRef = await addDoc(collection(firebase.db, "particelle"), {
            particella: particella,
            condominio: urlId,
            nomeCondominio: nomeCon,
            timestamp: serverTimestamp()
          });
          idParticella = docRef.id
          //AddClientiParticella(urlId, nomeCon, docRef.id, particella, email, identificativoCliente, tipo)
          document.getElementById("formNuova_Particella").reset();
          document.getElementById('numeroParticelle').style.display='none'
          let a = ''
          listaParticelle(a);
          listaClienti()
        } catch (e) {
        }
      } else {
        //setRedParticella('bg-red-50 border border-red-500')
      }
    } else {
      querySnapshot.forEach((doc) => {
        idParticella = doc.id
      })
    }
    return idParticella

  }

  /* const listeClientiPerTipo = async (email, tipo, particella) => {
    const getClienti = collection(firebase.db, "clienti");
    const q = query(getClienti, where('email', '==', email));
    let querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      //setAllClienti(oldArray => [...oldArray, {id: doc.id, particella: doc.data().particella, nome: doc.data().nome, email: doc.data().email, pec: doc.data().pec, cognome: doc.data().cognome, tipo: doc.data().tipo}]);
      switch (tipo) {
        case "Entrambi":
          setAllEntrambi(oldArray => [...oldArray, {id: doc.id, particella: particella, nome: doc.data().nome, email: doc.data().email, pec: doc.data().pec, cognome: doc.data().cognome, tipo: tipo, tel: doc.data().tel}]);
          break;
        case "Proprietario":
          setAllProprietari(oldArray => [...oldArray, {id: doc.id, particella: particella, nome: doc.data().nome, email: doc.data().email, pec: doc.data().pec, cognome: doc.data().cognome, tipo: tipo, tel: doc.data().tel}]);
          break;
        case "Inquilino":
          setAllInquilini(oldArray => [...oldArray, {id: doc.id, particella: particella, nome: doc.data().nome, email: doc.data().email, pec: doc.data().pec, cognome: doc.data().cognome, tipo: tipo, tel: doc.data().tel}]);
          break;
      }
      setAllClienti(oldArray => [...oldArray, {id: doc.id, particella: particella, nome: doc.data().nome, email: doc.data().email, pec: doc.data().pec, cognome: doc.data().cognome, tipo: tipo, tel: doc.data().tel}]);
    });
  } */

  const getCliente = async (email, tipo, nomePart, particella, tipoSingle, identificativoCliente, idClientiParticella) => {
    if(email) {
      const getCliente = collection(firebase.db, "clienti");
      const q = query(getCliente,  where('email', '==', email));
      let querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        //setAllTipi(oldArray => [...oldArray,  tipo]);
        
        //setAllIdentificativi(oldArray => [...oldArray, identificativoCliente]);
        //setAllEmail(oldArray => [...oldArray, {nome: doc.data().nome, cognome: doc.data().cognome, email: email, pec: doc.data().pec, tel: doc.data().tel, identificativoCliente: identificativoCliente}]);
        setAllClienti(oldArray => [...oldArray, {nome: doc.data().nome, cognome: doc.data().cognome, email: email, pec: doc.data().pec, tel: doc.data().tel, tipo: tipo, tipoSingle: tipoSingle, nomePart: nomePart, particella: particella, identificativoCliente: identificativoCliente, id: doc.id, idClientiParticella: idClientiParticella}]);
      })
      
    }
  }

  const listaClienti = async () => {
    
    setAllClienti([]);
    try {
      const getClienti = collection(firebase.db, "clientiParticella");
      const q = query(getClienti, where('condominio', '==', urlId));
      let querySnapshot = await getDocs(q);
      if(querySnapshot.empty) {document.getElementById('numeroClienti').style.display='block'}
      querySnapshot.forEach((doc) => {
        getCliente(doc.data().email, doc.data().tipo, doc.data().nomePart, doc.data().particella, doc.data().tipoSingle, doc.data().identificativoCliente, doc.id)
      });      
    } catch(e) {
    }
    let sorted = allClienti
    sorted = sorted.sort((a, b) => (a.cognome > b.cognome) ? 1 : (a.cognome === b.cognome) ? ((a.nome > b.nome) ? 1 : -1) : -1 )
    setAllClienti(sorted)    
  }

  const listaAllParticelle = async () => {
    
    setAllParticelleDisplay([]);
    try {
      const getClienti = collection(firebase.db, "particelle");
      const q = query(getClienti, where('condominio', '==', urlId), orderBy('particella'));
      let querySnapshot = await getDocs(q);
      if(!querySnapshot.empty) {
        document.getElementById('numeroAllParticelle').style.display='none'
      } else {
        document.getElementById('numeroAllParticelle').style.display='block'
      }

      let preParticelle = []
      querySnapshot.forEach((doc) => {
        preParticelle.push({particella: doc.data().particella, idParticella: doc.id})
        setAllParticelleDisplay(oldArray => [...oldArray, {particella: doc.data().particella, idParticella: doc.id}]);
      });
       
      preParticelle = preParticelle.reduce((unique, o) => {
        if(!unique.some(obj => obj.particella === o.particella && obj.idParticella === o.idParticella)) {
              unique.push(o);
            }
            return unique;
      },[]);
      setAllParticelleDisplay(preParticelle)
      //console.log(preParticelle)     
    } catch(e) {
      //console.log(e)
    }   
  }


 

  


 




  const listaFiles = async (prop) => {
    try {
      //const d = new Date();
      //let anno = d.getFullYear();
      if(prop >= (new Date().getFullYear())) {
        setAnnoSuc('hidden');
        setAnnoPrec('');
      }
      if(prop !== urlAnno) {
        history.replace('/amministratori/condominio?id='+urlId+'&anno='+prop);
      }
      //history.push('/amministratori/condominio?id='+urlId+'&anno='+prop);
      setAllFiles([]);
      
      let inizioAnno = new Date( prop, 1 - 1, 1);
      let fineAnno = new Date( prop, 12 - 1, 31);
      const getFiles = collection(firebase.db, "files");
      const q = query(getFiles, where('idCartella', '==', urlId), where('data', '>=', inizioAnno.getTime()), where('data', '<=', fineAnno.getTime()), orderBy("data", "desc"));
      let querySnapshot = await getDocs(q);
      if(!querySnapshot.empty) {
        setNessunRisultato('hidden');
        setNessunRisultatoReverse('')
      } else {
        setNessunRisultato('');
      } 
      if(querySnapshot.empty && prop === (new Date().getFullYear())) {
        setNessunRisultatoReverse('hidden')
      }
      if(querySnapshot.empty && prop < (new Date().getFullYear())) {
        setAnnoPrec('hidden');
        setAnnoSuc('');
      }
      if(!querySnapshot.empty && prop < (new Date().getFullYear())) {
        setAnnoSuc('');
        setAnnoPrec('');
      }
      querySnapshot.forEach((doc) => {
        if(doc.data().data === undefined) {alert('nessun file')}
        
        let dat = new Date(doc.data().data);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        //setUrlFile('')
        //getUrlFile(doc.data().file)

        getDownloadURL(ref(storage, urlId+'/'+doc.data().file))
          .then((url) => {
            //console.log('url: '+url)
            setUrlFile(url)
            setUrlFile((state) => {
              setAllFiles(oldArray => [...oldArray, {url: state, id: doc.id, nome: doc.data().file, tipo: doc.data().tipo, data: dat.toLocaleDateString('it-IT', options)}]);
              //return state;
            });
          })
        
        
        //setAllFiles(oldArray => [...oldArray, {id: doc.id, nome: doc.data().file, data: dat.toLocaleDateString('it-IT', options)}]);
        
      });
    } catch (e) {
      //alert("Errore del database: "+e);
      /* if(utente) {
        addDoc(collection(firebase.db, "errori"), {
          nome: 'Lista Files',
          timestamp: serverTimestamp(),
          dati: e
        });
      } else {
        history.replace("/");
      } */
    }
  };

 

  const eliminaFile = async (nome,id) => {
    try {
      const deleteFile = ref(storage, urlId+'/'+nome);
      await deleteDoc(doc(firebase.db, "files", id));
      deleteObject(deleteFile).then(() => {
        // File deleted successfully
      }).catch((e) => {
        //alert("Errore del database: "+e);
        //console.log(e)
        /* if(utente) {
          addDoc(collection(firebase.db, "errori"), {
            nome: 'Elimina File',
            timestamp: serverTimestamp(),
            dati: e
          });
        } else {
          history.replace("/");
        } */
      });
    } catch (e) {
      //alert("Errore del database: "+e);
      //console.log(e)
      /* if(utente) {
        addDoc(collection(firebase.db, "errori"), {
          nome: 'Elimina File',
          timestamp: serverTimestamp(),
          dati: e
        });
      } else {
        history.replace("/");
      } */
    }
    setAllFiles([]);
    listaFiles(urlAnno);
    //listaClienti();
    modal.style.display = "none";
  };

  

  const onFileChange = (e) => {
    const reader = new FileReader();
    let file = e.target.files[0]; 
    if (file && file.size < 5000000) {
      reader.onload = () => {
        if (reader.readyState === 2) {
          setFile(file);
          setnomeFile(file.name);
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    
    } else if(file && file.size >= 5000000) {
      alert('Il file è troppo grande!');
      setFile(null);
      setnomeFile('Carica un file (max 5MB)');
    } else {
      setFile(null);
      setnomeFile('Carica un file (max 5MB)');
    }
  };

  const uploadToFirebase = () => {  
    if (file && document.getElementById('tipo').value !== '') {
      let nomed = String(Date.now())+file.name
      const fileRef = ref(storage, urlId+'/'+nomed);
      uploadBytes(fileRef, file).then((snapshot) => {
        //console.log(snapshot)
        const addLinkFile = async () => {
          const docRef = await addDoc(collection(firebase.db, "files"), {
            cartella: nomeCon,
            idCartella: urlId,
            tipo: document.getElementById('tipo').value,
            file: nomed,
            data: Date.now(),
            timestamp: serverTimestamp(),
          });
        };
        addLinkFile(); 
        alert('Il file è stato caricato!');
        setFile(null);
        setnomeFile('Carica un file (max 5MB)');
        setAllFiles([]);
        listaFiles(urlAnno);
        invioMailFile(nomed, document.getElementById('tipo').value)
        
      });
    } else {
      if(!file) {
        alert("Devi prima selezionare un file");
      }
      if(document.getElementById('tipo').value === '') {
        alert("Devi prima selezionare il tipo file");
      }
    }
  };

  const invioMailFile = async (nome, tipo) => {
    let allClientiSingle = []
    let allClientiSingleArray = []
    allClienti.forEach((item) => {
      if(!allClientiSingle.includes(item.email))
      {
        let tipoC = Capitalize(tipo)
        let tipoItemC = Capitalize(item.tipo)
        //console.log(tipoC+'   '+tipoItemC)
        if(tipoItemC === 'Entrambi' || tipoC === tipoItemC || tipoC === 'Entrambi') {
          allClientiSingleArray.push({email: item.email, nome: item.nome, cognome: item.cognome})
          allClientiSingle.push(item.email)
        }
        /* allClientiSingleArray.push({email: item.email, nome: item.nome, cognome: item.cognome})
        allClientiSingle.push(item.email) */
      }
      
    })
    allClientiSingleArray.forEach((item) => {
      sendEmail(item.email, item.nome, item.cognome, allClientiSingleArray.length)     
    })
  }

  const sendEmail = async ( email, nome, cognome, nuoveEmail) => {
      let today = new Date();
      let date = today.getDate()+'-'+(today.getMonth()+1)+'-'+today.getFullYear()
      const docRef = doc(firebase.db, "sendgrid", date);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        if((docSnap.data().numero+nuoveEmail) < 99) {
          const docRef = await addDoc(collection(firebase.db, "mail"), {
            to: email,
            message: {
                subject: "Nuovo documento condominiale",
                text: "Buongiorno gentile "+nome+' '+cognome+', \n \n'+
                "La informiamo, che all'interno della sua area riservata, è stato caricato un nuovo file condominiale ("+nome.slice(13)+')'+
                '\n \n Per prenderne visione clicchi il seguente link \n'+
                'https://studioballon.it'+
                '\n \n \n Grazie e cordiali saluti \n \n'+
                'Studio Ballon\n'+
                'Via Augusto Serena, 37 - 31044 Montebelluna (TV) - Italy\n'+
                'Tel. +39 0423 300507\n'+
                'Fax +39 0423 610699\n'+
                'E-mail segreteria@studioballon.it\n'+
                'studioballon.it\n \n'+
                'Ai sensi del D.Lgs. 196/2003 si avvisa che la presente comunicazione e gli eventuali documenti ad essa allegati contengono informazioni riservate e ad uso esclusivo del destinatario sopra indicato. Qualora non fossero a Lei destinati, La preghiamo di tenere presente che la divulgazione, distribuzione o riproduzione di qualunque informazione in essi contenuta sono vietate. Se ha ricevuto la presente comunicazione per errore, La preghiamo di volerci avvertire immediatamente e di distruggere quanto ricevuto. Grazie.\n'+
                'This message including any attachments contains confidential information intended for a specific individual and purpose, and is protected by law. If you are not the intended recipient, you are hereby notified that any disclosure, copying, or distribution of this message is strictly prohibited, and are kindly requested to return the e-mail to sender and delete this message from your computer. Many thanks for your kind co-operation.',
              },
          });
          let nuovoNum = docSnap.data().numero+1
          await setDoc(doc(firebase.db, 'sendgrid', date), {
            numero: nuovoNum
          })
        } else {
          const sendEmail = httpsCallable(functions, 'sendEmail');
          sendEmail({ 
            "email": email,
            "subject": "Nuovo documento condominiale",
            "message": "Buongiorno gentile "+nome+' '+cognome+', \n \n'+
            "La informiamo, che all'interno della sua area riservata, è stato caricato un nuovo file condominiale ("+nome.slice(13)+')'+
            '\n \n Per prenderne visione clicchi il seguente link \n'+
            'https://studioballon.it'+
            '\n \n \n Grazie e cordiali saluti \n \n'+
            'Studio Ballon\n'+
            'Via Augusto Serena, 37 - 31044 Montebelluna (TV) - Italy\n'+
            'Tel. +39 0423 300507\n'+
            'Fax +39 0423 610699\n'+
            'E-mail segreteria@studioballon.it\n'+
            'studioballon.it\n \n'+
            'Ai sensi del D.Lgs. 196/2003 si avvisa che la presente comunicazione e gli eventuali documenti ad essa allegati contengono informazioni riservate e ad uso esclusivo del destinatario sopra indicato. Qualora non fossero a Lei destinati, La preghiamo di tenere presente che la divulgazione, distribuzione o riproduzione di qualunque informazione in essi contenuta sono vietate. Se ha ricevuto la presente comunicazione per errore, La preghiamo di volerci avvertire immediatamente e di distruggere quanto ricevuto. Grazie.\n'+
            'This message including any attachments contains confidential information intended for a specific individual and purpose, and is protected by law. If you are not the intended recipient, you are hereby notified that any disclosure, copying, or distribution of this message is strictly prohibited, and are kindly requested to return the e-mail to sender and delete this message from your computer. Many thanks for your kind co-operation.'
          })
            .then((result) => {
              //console.log(result.data.status+' '+result.data.message)
            })
            .catch((e) => {
            }); 
        }
      } else {
        const docRef = await addDoc(collection(firebase.db, "mail"), {
          to: email,
          message: {
              subject: "Nuovo documento condominiale",
              text: "Buongiorno gentile "+nome+' '+cognome+', \n \n'+
              "La informiamo, che all'interno della sua area riservata, è stato caricato un nuovo file condominiale ("+nome.slice(13)+')'+
              '\n \n Per prenderne visione clicchi il seguente link \n'+
              'https://studioballon.it'+
              '\n \n \n Grazie e cordiali saluti \n \n'+
              'Studio Ballon\n'+
              'Via Augusto Serena, 37 - 31044 Montebelluna (TV) - Italy\n'+
              'Tel. +39 0423 300507\n'+
              'Fax +39 0423 610699\n'+
              'E-mail segreteria@studioballon.it\n'+
              'studioballon.it\n \n'+
              'Ai sensi del D.Lgs. 196/2003 si avvisa che la presente comunicazione e gli eventuali documenti ad essa allegati contengono informazioni riservate e ad uso esclusivo del destinatario sopra indicato. Qualora non fossero a Lei destinati, La preghiamo di tenere presente che la divulgazione, distribuzione o riproduzione di qualunque informazione in essi contenuta sono vietate. Se ha ricevuto la presente comunicazione per errore, La preghiamo di volerci avvertire immediatamente e di distruggere quanto ricevuto. Grazie.\n'+
              'This message including any attachments contains confidential information intended for a specific individual and purpose, and is protected by law. If you are not the intended recipient, you are hereby notified that any disclosure, copying, or distribution of this message is strictly prohibited, and are kindly requested to return the e-mail to sender and delete this message from your computer. Many thanks for your kind co-operation.',
            },
        });
        await setDoc(doc(firebase.db, 'sendgrid', date), {
          numero: 1
        })
      }
  }



  const Capitalize = (prop) => {
    let str = prop.toLowerCase();
    let arr = str.split(" ");
    for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    return (arr.join(" ")).trim();
  }



  const getClienteEmail = async (e) => {
    e.preventDefault()
    const email = (e.target[0].value.toLowerCase().trim());
    if(email) {
      const getCliente = collection(firebase.db, "clienti");
      const q = query(getCliente,  where('email', '==', email));
      let querySnapshot = await getDocs(q);
      if(querySnapshot.empty) {
        alert('Nessun cliente trovato')
      } else {
        querySnapshot.forEach((doc) => {
          addClienteCognomeEmail(false, doc.data().nome, doc.data().cognome, email, doc.data().pec, doc.data().tel);
          document.getElementById("formGia_ClienteEmail").reset();
          return false
        })
      }
    } else {
      setRedGetEmail('bg-red-50 border border-red-500')
      setRedGetLabelEmail('text-red-500')
    }
  }

  const getClienteCognome = async (cog, event) => {
    setAllCognome([])
    setRedGetCognome('')
    setRedGetLabelCognome('')
    let cognome = ''
    if(event) {
      event.preventDefault()
      cognome = Capitalize((event.target[0].value.toLowerCase().trim()));
    }
    if(cog) {
      cognome = cog
    }
    if(cognome) {
      const getCliente = collection(firebase.db, "clienti");
      const q = query(getCliente,  where('cognome', '==', cognome));
      let querySnapshot = await getDocs(q);
      if(querySnapshot.empty) {
        alert('Nessun cliente trovato')
      } else {
        querySnapshot.forEach((doc) => {
          setAllCognome(oldArray => [...oldArray, {nome: doc.data().nome, cognome: cognome, email: doc.data().email, pec: doc.data().pec, tel: doc.data().tel}]);
        })
        document.getElementById("formGia_ClienteCognome").reset();
      }
    } else {
      setRedGetCognome('bg-red-50 border border-red-500')
      setRedGetLabelCognome('text-red-500')
    }
    document.getElementById("formGia_ClienteCognome").reset();
    //setRedGetTipoCognome('')
  }

  const addClienteCognomeEmail = (e, nome, cognome, email, pec, tel) => {
    if(e) {
      e.preventDefault()
    }
    document.getElementById('formGridNome').value = nome
    document.getElementById('formGridCognome').value = cognome
    document.getElementById('formGridEmail').value = email
    document.getElementById('formGridPec').value = pec
    if(tel === 'undefined') {
      document.getElementById('formGridTel').value = ''
    } else {
      document.getElementById('formGridTel').value = tel
    }
    aggiornamentoParticelleSelected(email)
    setAllCognome([])
    openTab2('NuovoCliente2')    
  }

  const aggiornamentoParticelleSelected = async (email) => {
    let a = []
    const getParticelle = collection(firebase.db, "clientiParticella");
    const q = query(getParticelle,  where('email', '==', email),  where('condominio', '==', urlId));
    let querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      a.push(doc.data().nomePart)
    })
    listaParticelle(a)
  }





  function openTab(tab) {
    var i;
    var x = document.getElementsByClassName("schede");
    for (i = 0; i < x.length; i++) {
      x[i].style.display = "none";
    }
    document.getElementById(tab).style.display = "block";
    if(tab !== 'NuovoCliente') {
      setCssLabelNuovoCliente('text-indigo-500 bg-white')
      setCssLabelClientiEsistenti('text-white bg-indigo-500')
    } else {
      setCssLabelNuovoCliente('text-white bg-indigo-500')
      setCssLabelClientiEsistenti('text-indigo-500 bg-white')
    }
  }
  function openTab2(tab) {
    var i;
    var x = document.getElementsByClassName("schede2");
    for (i = 0; i < x.length; i++) {
      x[i].style.display = "none";
    }
    document.getElementById(tab).style.display = "block";
    if(tab !== 'NuovoCliente2') {
      setChecked2(true)
      setChecked1(false)
      //setCssLabelNuovoCliente2('text-indigo-500 bg-white')
      //setCssLabelGiaCliente('text-white bg-indigo-500')
    } else {
      setChecked2(false)
      setChecked1(true)
      //setCssLabelNuovoCliente2('text-white bg-indigo-500')
      //setCssLabelGiaCliente('text-indigo-500 bg-white')
    }
  }
  function openTab3(tab) {
    var i;
    var x = document.getElementsByClassName("schede3");
    for (i = 0; i < x.length; i++) {
      x[i].style.display = "none";
    }
    document.getElementById(tab).style.display = "block";
    if(tab !== 'displayIdentificativi') {
      setChecked4(true)
      setChecked3(false)
    } else {
      setChecked4(false)
      setChecked3(true)
    }
  }
  const newIdentificativoCatastale = async (e, value) => {
    e.preventDefault()
    let presente = true
    const getParticelle = collection(firebase.db, "particelle");
    const q = query(getParticelle,  where('particella', '==', value),  where('condominio', '==', urlId));
    let querySnapshot = await getDocs(q);
    if(querySnapshot.empty) {presente = false}
    if( value && value.trim() !== '' && presente === false) {
      setInserimentoParticelleNuove(oldArray => [...oldArray, {label: value, value: value, new: 'true', tipo: ''}]);
      
      let preSorted = []
      allParticelle.forEach((item) => {
        preSorted.push({label: item.label, value: item.value, new: item.new, tipo: ''});
      })    
      preSorted.push({label: value, value: value, new: 'true', tipo: ''});     
      let sorted = preSorted.sort((a, b) => (a.label > b.label) ? 1 : -1)
      let result = sorted.reduce((unique, o) => {
        if(!unique.some(obj => obj.label === o.label && obj.value === o.value)) {
              unique.push(o);
            }
            return unique;
      },[]);
      setAllParticelle(result)
      //setAllParticelle(sorted)
      document.getElementById('newIdentificativoCatastale').value = ''
    }
    if(presente === true) {
      alert('Attenzione! Particella già registrata in questo condominio')
    }
  }

  function removeIdentificativoCatastale(e, label, nuovo) {
    e.preventDefault()
    let preSorted = []
    let preSortedNuovo = []
    let preSortedVecchio = []
    allParticelle.forEach((item) => {
      if(nuovo === 'true') {
        if(item.label !== label) {
          preSorted.push({label: item.label, value: item.value, new: item.new, tipo: ''})
        }
        if(item.label !== label && item.new === 'true') {
          preSortedNuovo.push({label: item.label, value: item.value, new: item.new, tipo: ''})
        }
      } else {
        if(item.label !== label) {
          preSorted.push({label: item.label, value: item.value, new: item.new, tipo: ''})
        }
        if(item.label !== label && item.new === 'false') {
          preSortedVecchio.push({label: item.label, value: item.value, new: item.new, tipo: ''})
        }
      }
    }) 
    setAllParticelle(preSorted)
    if(nuovo === 'true') { setInserimentoParticelleNuove(preSortedNuovo)}
    if(nuovo === 'false') { setSelected(preSortedVecchio)}
  }


  const addCliente = async (event) => {
    event.preventDefault()


    //setRedTipo('')
    setRedEmail('')
    setRedNome('')
    setRedCognome('')
    setRedIdentificativoCliente('')
    setRedNewIdentificativoCatastale('')

    //setRedLabelTipo('')
    setRedLabelEmail('')
    setRedLabelNome('')
    setRedLabelCognome('')
    setRedLabelIdentificativoCliente('')
    setRedLabelNewIdentificativoCatastale('')
    setRedParticelleAggiunte('')

    const nome = Capitalize(event.target[0].value);
    const cognome = Capitalize(event.target[1].value);
    const email = (event.target[2].value.toLowerCase()).trim();
    const pec = (event.target[3].value.toLowerCase()).trim();
    const tel = event.target[4].value;
    const identificativoCliente = event.target[5].value.trim();

    let verifica = true
    let idParticella = ''
    let tipo = ''
    if(allParticelle.length === 0) {
      verifica = false
      setRedNewIdentificativoCatastale('bg-red-50 border border-red-500')
      setRedLabelNewIdentificativoCatastale('text-red-600')
    }
    allParticelle.forEach((item) => {
      let form = document.getElementById(item.label);
      tipo = Capitalize(form.getElementsByTagName('select')[0].value)
      if(form.getElementsByTagName('select')[0].value === '') {
        verifica = false
        form.getElementsByTagName('select')[0].style.border = "1px solid rgb(220, 38, 38)"
        form.getElementsByTagName('select')[0].style.color = "rgb(220, 38, 38)"
        setRedParticelleAggiunte('text-red-500')
      } else {
        form.getElementsByTagName('select')[0].style.border = "0px solid red"
        form.getElementsByTagName('select')[0].style.color = "black"
      }
    })
    if(!nome || !cognome || !email || !identificativoCliente) {
      verifica = false
      if(!nome) {setRedNome('bg-red-50 border border-red-500')}
      if(!cognome) {setRedCognome('bg-red-50 border border-red-500')}
      if(!email) {setRedEmail('bg-red-50 border border-red-500')}
      if(!identificativoCliente) {setRedIdentificativoCliente('bg-red-50 border border-red-500')}

      if(!nome) {setRedLabelNome('text-red-500')}
      if(!cognome) {setRedLabelCognome('text-red-500')}
      if(!email) {setRedLabelEmail('text-red-500')}
      if(!identificativoCliente) {setRedLabelIdentificativoCliente('text-red-500')}
    }
    if(verifica === false) {
      alert('Attenzione! Errori di inserimento')
      return false
    } else {
      document.getElementById("formNuovo_Cliente").reset();
      setAllParticelle([])
      AddCliente(nome, cognome, email, pec, tel)
      allParticelle.forEach((item) => {
        //addParticella(urlId, nomeCon, item.label, email, identificativoCliente, tipo)
        let promise = addParticella(urlId, nomeCon, item.label, email, identificativoCliente, tipo)
        Promise.resolve(promise).then(function(value) {
          idParticella = value
          let promise2 = AddClientiParticella(urlId, nomeCon, idParticella, item.label, email, identificativoCliente, tipo)
          Promise.resolve(promise2).then(function(tipoSingle) {
            setAllClienti(oldArray => [...oldArray, {nome: nome, cognome: cognome, email: email, pec: pec, tel: tel, tipo: tipo, tipoSingle: tipoSingle, nomePart: item.label, particella: idParticella, identificativoCliente: identificativoCliente}]);
            setSelected([])
            setInserimentoParticelleNuove([])
            setAllParticelle([])
            let a = ''
            listaParticelle(a)
          })
        })        
      })
    }
  }

  function reset(e) {
    e.preventDefault()
    document.getElementById("formNuovo_Cliente").reset();
    setSelected([])
    setInserimentoParticelleNuove([])
    setAllParticelle([])
    let a = ''
    listaParticelle(a)
  }
  



  useEffect(() => {
    if (urlId) {
      getCondominio();     
    } else {
      history.replace('/');
    }
    
  }, []);

  useEffect(() => {
      if(allParticelleDisplay.length > 0) {
        document.getElementById('numeroAllParticelle').style.display = 'none'
      }  else {
        document.getElementById('numeroAllParticelle').style.display = 'block'
      }
  }, [allParticelleDisplay]);

  useEffect(() => {
    listaAllParticelle()
    setAllIdentificativi([])
    let preEmail = []
    let preCognomi = []
    allClienti.forEach((item) => {
      preEmail.push({nome: item.nome, cognome: item.cognome, email: item.email, pec: item.pec, tel: item.tel, identificativoCliente: item.identificativoCliente, id: item.id})
      setAllIdentificativi(oldArray => [...oldArray, item.identificativoCliente]);
    })

    
    preEmail = preEmail.sort((a, b) => (a.cognome > b.cognome) ? 1 : (a.cognome === b.cognome) ? ((a.nome > b.nome) ? 1 : -1) : -1 )
    preCognomi = preEmail
    

    preEmail = preEmail.reduce((unique, o) => {
      if(!unique.some(obj => obj.email === o.email && obj.identificativoCliente === o.identificativoCliente)) {
            unique.push(o);
          }
          return unique;
    },[]);
    setAllEmail(preEmail)
    //console.log(preEmail)
    preCognomi = preCognomi.reduce((unique, o) => {
      if(!unique.some(obj => obj.email === o.email)) {
            unique.push(o);
          }
          return unique;
    },[]);
    setAllCognomiDisplay(preCognomi)
    setAllEmail(preEmail)
    

    if(allClienti.length > 0) {
      document.getElementById('numeroClienti').style.display='none'
    } 
  }, [allClienti]);

  useEffect(() => {
    let sorted = allIdentificativi
    sorted = sorted.sort()
    setAllIdentificativi(sorted)
  }, [allIdentificativi])

  

  

  


  useEffect(() => {
    //setInserimentoParticelleVecchie(selected)
    let preSorted = []
    selected.forEach((item) => {
      preSorted.push({label: item.label, value: item.value, new: 'false', tipo: ''});
    })    
    inserimentoParticelleNuove.forEach((item) => {
      preSorted.push({label: item.label, value: item.value, new: 'true', tipo: ''});
    })      
    let sorted = preSorted.sort((a, b) => (a.label > b.label) ? 1 : -1)
    setAllParticelle(sorted)
  }, [selected]);

  


    
    
    

    


    return (
      <div>
        <Helmet>
          <title>Studio Ballon - Gestione Condominio</title>
        </Helmet>
        <Header />
        <div className='md:w-2/3 p-5 mx-auto text-indigo-500 font-semibold'>
          <span className='cursor-pointer underline' onClick={() => history.push("/amministratori/home")}>Home Amministrazione</span> / {nomeCon}
        </div>
        <div className=" p-5 md:w-2/3 mx-auto w-full mt-5 mb-14 shadow-2xl bg-white rounded-xl">
            <h2 className='font-bold mb-5 text-2xl'>Gestione condominio </h2>
            <div>

              {/* Intestazione condominio */}
              <div className='p-5 border-l-2 border-indigo-500 bg-gray-100'>
                <div className="md:flex md:justify-between items-center">
                  <div className='mb-3 md:mb-0'>
                    <h1 className='text-xl text-indigo-500'>{nomeCon}</h1>
                    <p className='font-semibold'><span>{via} {indirizzo}, {civico}</span> <span className='hidden md:inline'>-</span> <span className='block md:inline'>{cap} - {citta}</span></p>
                  </div>
                  <button  onClick={(e) => {showModal('condominio', '', '', '')}} type='button'  className=" w-full justify-center rounded-md border border-transparent shadow-sm h-10 px-5 text-lg font-semibold bg-red-600  text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">Elimina Condominio</button>
                </div>
              </div>


              <div className='p-5 border-indigo-500 border mt-5'>

                {/* Gestione files condominio */}
                <h1 className='text-lg font-semibold '>Files condominiali</h1>
                <h2 className={'text-semibold text-red-800 '+(nessunRisultato)}>Nessun File presente</h2>
                
                {/* Elenco files */}
                <div>
                  {/* <ListaFiles firebase={firebase} /> */}
                  {allFiles.map((item, i) => {
                      return (
                        <div className='md:flex justify-between border border-indigo-500 my-2 py-2 px-5 bg-gray-50 rounded-lg focus:shadow-outline hover:bg-indigo-100 cursor-pointer' key={i}>
                          {/* <a className='cursor-pointer font-semibold mb-3 md:mb-0' href={'https://firebasestorage.googleapis.com/v0/b/studioballon-4f3e8.appspot.com/o/'+(urlId)+'%2F'+(item.nome)+'?alt=media'}>{item.nome.slice(13)}  <span className='text-sm block'>{item.data}</span></a> */}
                          <a className='cursor-pointer font-semibold mb-3 md:mb-0' href={item.url}>{item.nome.slice(13)}  <span className='text-sm block'>{item.data}</span><span className='text-sm block'>File riservato a: {item.tipo}</span></a>
                          {/* <div className='cursor-pointer font-semibold mb-3 md:mb-0' onClick={(e) => {openFile(item.nome)}}>{item.nome.slice(13)}  <span className='text-sm block'>{item.data}</span> </div> */}
                          {/* <div className='cursor-pointer font-bold text-red-600' onClick={(e) => {eliminaFile(item.nome, item.id)}}>Elimina</div> */}
                          <div className='my-auto cursor-pointer font-bold text-red-600' onClick={(e) => {showModal('file', item.nome, item.id, '')}}>Elimina</div>
                        </div>
                      );
                    //}
                  })}
                  <div className={'mt-8 flex justify-center '+(nessunRisultatoReverse)}>
                    <div className={'py-2 px-4 border border-indigo-500 rounded-full font-bold text-indigo-500 cursor-pointer '+(annoSuc)} onClick={(e) => {listaFiles(Number(urlAnno)+1)}}>&lt;</div>
                    <div className='mx-5 p-2 border border-indigo-500 rounded-full font-bold text-indigo-500'>{urlAnno}</div>
                    <div className={'py-2 px-4 border border-indigo-500 rounded-full font-bold text-indigo-500 cursor-pointer '+(annoPrec)} onClick={(e) => {listaFiles(Number(urlAnno)-1)}}>&gt;</div>
                  </div>
                </div>

                {/* Upload file */}
                <div>
                  <div className="flex w-full h-64 items-center justify-center bg-grey-lighter">
                      <label className="w-72 flex flex-col items-center px-4 py-6 bg-white text-indigo-500 rounded-lg shadow-lg tracking-wide uppercase border border-indigo-500 cursor-pointer hover:bg-indigo-500 hover:text-white">
                          <svg className="w-10 h-10" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                              <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                          </svg>
                          <span className="mt-2 text-sm leading-normal">{nomeFile}</span>
                          <input type='file' id='fileCon' className="hidden"  onChange={(e) => { onFileChange(e); }} />
                      </label>
                  </div>
                  <div className='flex items-center justify-center -mt-14'>
                    <select id='tipo' className="w-72 mx-auto h-8 px-6 text-sm text-indigo-100 transition-colors duration-150 bg-indigo-700 rounded-lg focus:shadow-outline hover:bg-indigo-800">
                        <option id='vuoto' value=''>- Seleziona il tipo di file -</option>
                        <option id='entrambi' value='entrambi'>Entrambi</option>
                        <option id='proprietario' value='proprietario'>Proprietario</option>
                        <option id='inquilino' value='inquilino'>Inquilino</option>
                    </select>  
                  </div>
                  <div className='flex items-center justify-center mt-2'>
                    <button className="w-72 mx-auto h-8 px-4 text-sm text-indigo-100 transition-colors duration-150 bg-indigo-700 rounded-lg focus:shadow-outline hover:bg-indigo-800" onClick={(e) => {uploadToFirebase(); }}>Invia file</button>
                  </div>
                </div>
              </div>

              <div className='mt-8  border-t border-l border-r border-indigo-500 text-center font-bold text-xl py-3'>Gestione Clienti Condominio</div>
              <div className=' border-indigo-500 border '>
                <div className='flex  border-b border-indigo-500' >
                  <button className={'w-1/2 p-2 m-1 align-middle border-indigo-500 border text-center font-semibold '+(cssLabelNuovoCliente)} id='labelNuovoCliente' onClick={() => openTab('NuovoCliente')}>Nuovo Cliente</button>
                  <button className={'w-1/2 p-2 m-1 align-middle border border-indigo-500 text-center font-semibold '+(cssLabelClientiEsistenti)} id='labelClientiEsistenti' onClick={() => openTab('ClientiEsistenti')}>Clienti Condominio</button>
                </div>
                {/* <div id="Particelle" className='schede p-4 m-1 bg-indigo-500' >
                  <div  id='numeroParticelle' className='p-4 hidden' >
                    <div className='text-red-500 bg-white border border-red-500 text-center rounded-xl font-semibold  p-2'>Nessuna Particella ancora registrato!</div>
                  </div>
                  <div className='  grid md:grid-cols-3 py-2'>
                    {allParticelle.map((item, i) => {
                        return (
                          <div key={i} id={item.id}  onClick={() => history.replace("/amministratori/gestioneparticella?id="+item.id+'&idc='+item.condominio+'&anno='+anno)} className='  px-5 py-2 m-1 border border-indigo-500 text-center text-gray-900 font-semibold transition-colors duration-150 bg-gray-100 rounded-lg focus:shadow-outline hover:bg-indigo-100 cursor-pointer' >
                            <div className='font-semibold text-sm'>P.Catastale: {item.particella}</div>
                          </div>
                        );
                      }
                    )}
                  </div>

                  <form className='p-5 mt-5 border-indigo-500 border rounded-lg bg-gray-100' id='formNuova_Particella' onSubmit={addParticella}>
                    <h1 className=' font-semibold px-2 pb-2'>Nuova Particella</h1>
                    <div className="w-full px-2">
                      <input className={redParticella+" w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline"} type="text" id="formGridParticella"/>
                      <button type="submit" className='h-10 px-5 mt-4 text-indigo-100 transition-colors duration-150 bg-indigo-700 rounded-lg focus:shadow-outline hover:bg-indigo-800'>Aggiungi</button>
                    </div>
                  </form>
                </div> */}
                <div id='NuovoCliente' className='schede'>
                  <div className=' p-4 mr-1 mt-1 ml-1   bg-indigo-500 font-bold' >
                    <input className='inline-block pl-5' type='radio' value='NuovoCliente2'  id='labelNuovoCliente2' onChange={() => openTab2('NuovoCliente2')} checked ={checked1} /><span className='text-white font-bold'>Aggingi un nuovo cliente</span>
                    <input className='inline-block pl-5 md:ml-5' type='radio' value='GiaCliente'  id='labelGiaCliente' onChange={() => openTab2('GiaCliente')} checked ={checked2} /><span className='text-white font-bold'>Aggiungi un cliente esistente</span>
                  </div>
                  {/* <div className='flex  border-b border-indigo-500' >
                    <button className={'w-1/2 p-2 m-1 align-middle border-indigo-500 border text-center font-semibold '+(cssLabelNuovoCliente2)} id='labelNuovoCliente2' onClick={() => openTab2('NuovoCliente2')}>Aggingi un nuovo cliente</button>
                    <button className={'w-1/2 p-2 m-1 align-middle border border-indigo-500 text-center font-semibold '+(cssLabelGiaCliente)} id='labelGiaCliente' onClick={() => openTab2('GiaCliente')}>Aggiungi un cliente esistente</button>
                  </div> */}
                  <div id="NuovoCliente2" className='schede2 p-4 pt-0 mr-1 mb-1 ml-1  bg-indigo-500' >
                      {/* Inserimento condomini */}
                      <form className='p-5 border-indigo-500 border rounded-lg bg-gray-100' id='formNuovo_Cliente' onSubmit={(e) => {addCliente(e); }}>
                          <div className='text-sm text-red-500 mb-2'>* Campi obbligatori</div>
                          <h1 className=' font-semibold pb-4'>Nuovo Proprietario/Inquilino</h1>
                          <div className='border border-indigo-500 p-5'>  
                            <h1 className=' font-semibold '>Anagrafica</h1>
                            <div className="flex flex-wrap -mx-2 my-4 space-y-4 md:space-y-0">
                                <div className="w-full px-2 md:w-1/2">
                                    <label className={redLabelNome+" block mb-1 pl-2"} htmlFor="formGridNome">Nome <span className='text-red-500 font-bold'>*</span></label>
                                    <input className={redNome+" w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline"} type="text" id="formGridNome"/>
                                </div>
                                <div className="w-full px-2 md:w-1/2">
                                    <label className={redLabelCognome+" block mb-1 pl-2"} htmlFor="formGridCognome">Cognome <span className='text-red-500 font-bold'>*</span></label>
                                    <input className={redCognome+" w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline"} type="text" id="formGridCognome"/>
                                </div>
                            </div>
                            <div className="flex flex-wrap -mx-2 my-4 space-y-4 md:space-y-0">
                                <div className="w-full px-2 md:w-1/3">
                                    <label className={redLabelEmail+" block mb-1 pl-2"} htmlFor="formGridEmail">Email <span className='text-red-500 font-bold'>*</span></label>
                                    <input className={redEmail+" w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline"} type="email" id="formGridEmail"/>
                                </div>
                                <div className="w-full px-2 md:w-1/3">
                                    <label className="block mb-1 pl-2" htmlFor="formGridPec">PEC</label>
                                    <input className="w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline" type="email" id="formGridPec"/>
                                </div>
                                <div className="w-full px-2 md:w-1/3">
                                    <label className="block mb-1 pl-2" htmlFor="formGridPec">Tel</label>
                                    <input className="w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline" type="tel" id="formGridTel"/>
                                </div>
                            </div>
                          </div>
                          <div className='border border-indigo-500 p-5 mt-5'>  
                            <h1 className=' font-semibold '>Dati condominiali cliente</h1>
                            <div className="flex flex-wrap -mx-2 my-4 space-y-4 md:space-y-0">
                                <div className="w-full px-2 ">
                                    <label className={" block mb-1 pl-2 "+redLabelIdentificativoCliente} >Identificativo Cliente <span className='text-red-500 font-bold'>*</span></label>
                                    <input className={redIdentificativoCliente+" w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline"} type="text" />
                                </div>
                            </div>
                            <div className={" block mt-4 mb-1 pl-2 pt-2 border-t border-indigo-500"} htmlFor="formGridNome">Particella Catastale <span className='text-red-500 font-bold'>*</span></div>
                            <div className="flex justify-between ">
                                <div className="w-full  md:w-5/12 ">
                                  <div className={redLabelNewIdentificativoCatastale+' mt-5 ml-2'}>Nuova Particella</div>
                                  <div className="w-full  relative ">
                                    <input className={redNewIdentificativoCatastale+" w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline"} type="text"id="newIdentificativoCatastale" />
                                    <button type="submit" className='w-auto  absolute inset-y-0 right-0 flex items-center px-4 h-10 font-bold text-indigo-100 bg-indigo-700 rounded-lg rounded-l-none hover:bg-indigo-800 focus:bg-indigo-700' onClick={(e) => {newIdentificativoCatastale(e, document.getElementById('newIdentificativoCatastale').value); }}>Aggiungi</button>
                                  </div>
                                  <div className='mt-5 ml-2'>Seleziona particella già registrata</div>
                                  <MultiSelect
                                    className=''
                                    options={allParticelleSelect}
                                    value={selected}
                                    onChange={setSelected}
                                    labelledBy="Particelle Registrate"
                                  />
                                </div>
                                <div className="w-full   md:w-7/12 pl-5 pt-5">
                                  <div className=' border border-indigo-500 p-2 md:mt-6'>
                                    <div className={redParticelleAggiunte}>Particelle aggiunte al cliente:</div>
                                    {allParticelle.map((item, i) => {
                                        return (
                                          <div key={i} id={item.label} className=''>
                                            <div className='grid grid-cols-3 '>
                                              <div className='font-semibold m-2 text-sm '>{item.label}</div>
                                              <select className={"   px-3 m-2 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline"} >
                                                <option value=''>- seleziona -</option>
                                                <option value='proprietario'>Proprietario</option>
                                                <option value='inquilino'>Inquilino</option>
                                                <option value='entrambi'>Entrambi</option>
                                              </select>
                                              <button type='submit' className='my-auto cursor-pointer font-bold text-red-600 mr-2'  onClick={(e) => removeIdentificativoCatastale(e, item.label, item.new)}>Rimuovi</button>
                                              <input className='hidden' type='text' value={item.label} disabled='disabled' />
                                              <input className='hidden' type='text' value={item.value} disabled='disabled' />
                                              <input className='hidden' type='text' value={item.new} disabled='disabled' />
                                            </div>
                                          </div>
                                          
                                          
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                            </div>
                          </div>
                          <div className="flex justify-between">
                            <button  className='h-10 px-5 mt-4 text-indigo-100 transition-colors duration-150 bg-indigo-700 rounded-lg focus:shadow-outline hover:bg-indigo-800'  onClick={(e) => reset(e)}>Reset</button>
                            <button type="submit" className='h-10 px-5 mt-4 text-indigo-100 transition-colors duration-150 bg-indigo-700 rounded-lg focus:shadow-outline hover:bg-indigo-800'>Aggiungi</button>
                          </div>
                      </form> 
                  </div>
                  <div id="GiaCliente" className='schede2 p-4 pt-0 mr-1 mb-1 ml-1 bg-indigo-500 hidden' >
                      <div className='mb-2 border-indigo-500 border rounded-lg bg-gray-100'>
                        <form className='p-5 ' id='formGia_ClienteCognome'  onSubmit={(e) => {getClienteCognome(false, e); }} >
                            <h1 className=' font-semibold '>Cerca Proprietario/Inquilino già registrato</h1>
                            <div className="flex flex-wrap -mx-2 my-4 space-y-4 md:space-y-0">
                                <div className="w-full px-2 md:w-1/2">
                                    <label className={redGetLabelCognome+" block mb-1 pl-2"} htmlFor="formGridCognome">Cognome</label>
                                    <input className={redGetCognome+" w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline"} type="text" id="formGridCognome"/>
                                </div>
                            </div> 
                            <button type="submit" className='h-10 px-5  text-indigo-100 transition-colors duration-150 bg-indigo-700 rounded-lg focus:shadow-outline hover:bg-indigo-800'>Cerca</button>  
                        </form>
                        {allCognome.map((item, i) => {
                            return (
                              <div key={i} className=' px-5 py-2'>
                                <div className='border-indigo-500 border p-2 rounded-lg font-semibold md:flex md:justify-between' >
                                  <div>{item.nome} {item.cognome} - {item.email}</div>
                                  <div>
                                    <button type="submit" className=' ml-5 px-5  text-indigo-100 transition-colors duration-150 bg-indigo-700 rounded-lg focus:shadow-outline hover:bg-indigo-800' onClick={(e) => {addClienteCognomeEmail(e, item.nome, item.cognome, item.email, item.pec, item.tel); }}>Aggiungi</button>
                                  </div>
                                </div>
                              </div>
                            );                      
                        })}
                      </div>
                      <form className='p-5 border-indigo-500 border rounded-lg bg-gray-100' id='formGia_ClienteEmail'  onSubmit={(e) => {getClienteEmail(e); }} >
                          <h1 className=' font-semibold '>Inserisci Proprietario/Inquilino già registrato</h1>
                          <div className="flex flex-wrap -mx-2 my-4 space-y-4 md:space-y-0">
                              <div className="w-full px-2 md:w-1/2">
                                  <label className={redGetLabelEmail+" block mb-1 pl-2"} htmlFor="formGridPec">Email</label>
                                  <input className={redGetEmail+" w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline"} type="email" id="formGridEmail"/>
                              </div>
                          </div> 
                          <button type="submit" className='h-10 px-5  text-indigo-100 transition-colors duration-150 bg-indigo-700 rounded-lg focus:shadow-outline hover:bg-indigo-800'>Aggiungi</button>  
                      </form>
                  </div>
                </div>



















                <div id="ClientiEsistenti" className='hidden schede  m-1 bg-indigo-500'>
                  <div className=' p-4 mr-1 mt-1 ml-1   bg-indigo-500 font-bold' >
                    <input className='inline-block pl-5' type='radio' value='displayIdentificativi'  id='labelDisplayIdentificativi' onChange={() => openTab3('displayIdentificativi')} checked ={checked3} /><span className='text-white font-bold'>Visualizzazione per identificativo</span>
                    <input className='inline-block pl-5 md:ml-5' type='radio' value='displayCognomi'  id='labelDisplayCognomi' onChange={() => openTab3('displayCognomi')} checked ={checked4} /><span className='text-white font-bold'>Visualizzazione per cognome</span>
                  </div>
                  <div  id='numeroClienti' className='p-4 hidden' >
                    <div className='text-red-500 bg-white border border-red-500 text-center rounded-xl font-semibold  p-2'>Nessun Cliente ancora registrato!</div>
                  </div>
                  
                  <div id='displayIdentificativi' className='schede3  grid  pt-2 pb-4 mx-4'>
                    {[...new Set(allIdentificativi)].map((item1, i1) => {
                        return (
                          <div key={i1} className='bg-white my-3 rounded-lg p-3'>
                            <div className='text-xl mx-6 pb-3 font-bold text-indigo-500'>Identificativo cliente: {item1}</div>
                            {allEmail.sort((a, b) => (a.cognome > b.cognome) ? 1 : (a.nome === b.nome) ? ((a.size > b.size) ? 1 : -1) : -1 ).map((item, i) => {
                              if(item.identificativoCliente === item1){
                                return (
                                  <div key={i} className=' px-5 py-1 m-1 border border-indigo-500  text-gray-900 font-semibold transition-colors duration-150 bg-gray-100 rounded-lg focus:shadow-outline hover:bg-indigo-100'>
                                    <div className='md:flex justify-between p-1 text-sm'>
                                      <div className='cursor-pointer'   onClick={() => history.push("/amministratori/gestionecliente?id="+item.id+'&anno='+anno+'&idC='+urlId)}>
                                        <div className='font-bold text-base'>{item.cognome} {item.nome} </div>
                                        <div className='font-semibold '><span className='font-extrabold'>Email:</span> {item.email} <span className='font-extrabold'>Tel:</span> {item.tel}   <span className='font-extrabold'>PEC:</span> {item.pec}</div>
                                        {/* <div className='font-semibold text-xs'><span className='font-extrabold'>Tipo:</span> {item.tipo}</div>
                                        <div className='font-semibold text-xs'><span className='font-extrabold'>Identificativo:</span> {item.identificativoCliente}   <span className='font-extrabold'>P.Catastale:</span> {item.nomePart}</div> */}
                                      </div>
                                      <div className='my-auto cursor-pointer font-bold text-red-600' onClick={(e) => {showModal('cliente', item.nome+' '+item.cognome, '', item.email)}}>Elimina dal condominio</div>
                                    </div>
                                    <div>
                                      {allClienti.map((item2, i2) => {
                                        if(item2.identificativoCliente === item1 && item2.email === item.email){
                                          return (
                                            <div key={i2} className='md:flex justify-between  px-10 border-t-2 border-indigo-500 text-xs'>
                                              <div className='my-auto'>
                                                <div className='font-semibold '><span className='font-extrabold'>P.Catastale:</span> {item2.nomePart}</div>
                                                <div className='font-semibold '><span className='font-extrabold'>Tipo:</span> {item2.tipo}</div>
                                              </div>
                                              <div className='my-auto'>
                                                <div className='p-1 cursor-pointer font-bold text-red-600' onClick={(e) => {showModal('particella', '', item2.particella, item.email)}}>Elimina dalla particella</div>
                                                <div className=' border-indigo-500 border-t p-1'>
                                                  <form className='inline-block' onSubmit={(e) => {cambiaTipo(item2.idClientiParticella, e, item.email)}}>                                 
                                                      <button type='submit' className='my-auto cursor-pointer font-bold text-yellow-600 mr-2'>Modifica tipo</button>
                                                      <select className={"   px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline"} >
                                                          <option value=''>- seleziona -</option>
                                                          <option value='proprietario'>Proprietario</option>
                                                          <option value='inquilino'>Inquilino</option>
                                                          <option value='entrambi'>Entrambi</option>
                                                      </select>
                                                  </form>
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        }
                                      })}
                                    </div>
                                  </div>
                                );
                              }
                            })}
                          </div>
                        );
                    })}
                  </div>


                  <div id='displayCognomi' className='schede3  grid  pt-2 pb-4 mx-4 hidden'>
                    {allCognomiDisplay.map((item, i) => {
                      return (
                        <div key={i}>
                              <div  className=' px-5 py-1 m-1 border border-indigo-500  text-gray-900 font-semibold transition-colors duration-150 bg-gray-100 rounded-lg focus:shadow-outline hover:bg-indigo-100'>
                                <div className='md:flex justify-between p-1 text-sm'>
                                  <div className='cursor-pointer'   onClick={() => history.push("/amministratori/gestionecliente?id="+item.id+'&anno='+anno+'&idC='+urlId)}>
                                    <div className='font-bold text-base'>{item.cognome} {item.nome} </div>
                                    <div className='font-semibold '><span className='font-extrabold'>Email:</span> {item.email} <span className='font-extrabold'>Tel:</span> {item.tel}   <span className='font-extrabold'>PEC:</span> {item.pec}</div>
                                  </div>
                                  <div className='my-auto cursor-pointer font-bold text-red-600' onClick={(e) => {showModal('cliente', item.nome+' '+item.cognome, '', item.email)}}>Elimina dal condominio</div>
                                </div>
                                <div>
                                {allClienti.map((item2, i2) => {
                                        if(item2.email === item.email){
                                          return (
                                            <div key={i2} className='md:flex justify-between px-10 border-t-2 border-indigo-500 text-xs'>
                                              <div className='my-auto'>
                                                <div className='font-semibold '><span className='font-extrabold'>P.Catastale:</span> {item2.nomePart}</div>
                                                <div className='font-semibold '><span className='font-extrabold'>Tipo:</span> {item2.tipo}</div>
                                              </div>
                                              <div className='my-auto'>
                                                <div className='p-1 cursor-pointer font-bold text-red-600'  onClick={(e) => {showModal('particella', '', item2.particella, item.email)}}>Elimina dalla particella</div>
                                                <div className=' border-indigo-500 border-t p-1'>
                                                  <form className='inline-block' onSubmit={(e) => {cambiaTipo(item2.idClientiParticella, e, item.email)}}>                                 
                                                      <button type='submit' className='my-auto cursor-pointer font-bold text-yellow-600 mr-2'>Modifica tipo</button>
                                                      <select className={"   px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline"} >
                                                          <option value=''>- seleziona -</option>
                                                          <option value='proprietario'>Proprietario</option>
                                                          <option value='inquilino'>Inquilino</option>
                                                          <option value='entrambi'>Entrambi</option>
                                                      </select>
                                                  </form>
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        }
                                  })}
                                </div>
                              </div>
                        </div>
                      )
                    })}
                  </div>
                </div>

              </div>




              <div className='mt-8  border-t border-l border-r border-indigo-500 text-center font-bold text-xl py-3'>Gestione Particelle Condominio</div>
              <div className=' border-indigo-500 border '>
                <div  id='numeroAllParticelle' className='p-4 hidden' >
                  <div className='text-red-500 bg-white border border-red-500 text-center rounded-xl font-semibold  p-2'>Nessuna Particella ancora registrata!</div>
                </div>
                {allParticelleDisplay.map((item, i) => {
                  return (
                    <div key={i} className='inline-block p-4'>
                      <div className='py-2 px-5 text-center border border-indigo-500 text-gray-900 font-semibold transition-colors duration-150 bg-gray-100 cursor-pointer rounded-full  hover:bg-indigo-100' onClick={() => history.push("/amministratori/gestioneparticella?id="+item.idParticella+'&idc='+urlId+'&anno='+anno)}>{item.particella}</div>
                    </div>
                  )
                })}
              </div>
            </div>
            <div className="flex justify-between">
              <button className=' h-10 px-5 mx-4 my-6 text-indigo-100 transition-colors duration-150 bg-indigo-700 rounded-lg focus:shadow-outline hover:bg-indigo-800' onClick={() => history.push("/amministratori/home")}> Indietro</button>
              <SignOutButton />    
            </div>





            {/* modal */}
            <div className="hidden fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true" id='modal'>
              <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        {/* <!-- Heroicon name: outline/exclamation --> */}
                        <svg className="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                        </svg>
                      </div>
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                          {btnTitolo}
                        </h3>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            {btnTesto}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                    <button id='btnFile' type="button" onClick={(e) => {{eliminaFile(btnNome, btnId)}}} className="hidden w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
                      Elimina
                    </button>
                    <button id='btnCondominio' type="button" onClick={(e) => {{eliminaCondominio()}}} className="hidden w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
                      Elimina 
                    </button>
                    <button id='btnCliente' type="button" onClick={(e) => {{eliminaClienteCondominio(btnEmail, urlId)}}} className="hidden w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
                      Elimina 
                    </button>
                    <button id='btnParticella' type="button" onClick={(e) => {{eliminaClienteParticella(btnEmail, btnId)}}} className="hidden w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
                      Elimina 
                    </button>
                    <button type="button"  onClick={(e) => {modal.style.display = "none"}} className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                      Cancella
                    </button>
                  </div>
                </div>
              </div>
            </div>
        </div>
        <Footer />
      </div>
    );
}

export default Amministratore;






