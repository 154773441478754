import React, { useEffect } from "react";
import Footer from './Footer'
import Header from './Header'
import 'animate.css';

//import { useSpring, animated } from 'react-spring'

function InformazioniLegali(){
    
    

  //const props = useSpring({ to: { opacity: 1 }, from: { opacity: 0 } })
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  

  return (
    <div >
        
        <Header />
        
        <div className='p-5 mx-5 my-20 md:w-1/2 md:mx-auto bg-white rounded-lg ObjectiveRegular'>
              <h1 className=' text-3xl mb-5 font-semibold'>Informativa privacy ai sensi degli artt. 13-14 del Regolamento Europeo “Privacy” (GDPR)</h1>
              <p>I dati personali da Lei inseriti, sono trattati da Ballon Marco - Via Augusto Serena, 37 - 31044 Montebelluna(TV) - 0423300507 in qualità di Titolare, per finalità connesse alla fornitura dei servizi da Lei richiesti (a titolo esemplificativo: richiedere informazioni, registrarsi al sito per ricevere vantaggi e offerte, ottenere preventivi, effettuare pagamenti, acquistare beni e riceverli presso un domicilio da Lei indicato, effettuare una prenotazione, scambiare documenti).

Inoltre, i dati da Lei forniti, potrebbero essere trattati, solo su Suo esplicito consenso, al fine di inviarLe comunicazioni di natura commerciale via email o sms relativamente ai servizi offerti dal Titolare.

Nel primo caso, la base giuridica del trattamento è l’esecuzione di un accordo; nel secondo caso è il consenso. I trattamenti avverranno con modalità elettroniche e verranno messi a disposizione delle terze parti (Responsabili Esterni del trattamento) nell’ambito dell’Unione Europea o in Paesi extra UE, regolarmente contrattualizzate e che offrono adeguate garanzie di sicurezza, necessarie per la fornitura di servizi essenziali al soddisfacimento delle Sue esigenze. Tali Responsabili Esterni, il cui elenco è disponibile dietro richiesta, possono appartenere a categorie di soggetti che supportano il Titolare nell’erogazione dei servizi offerti tramite il presente sito (quali, a titolo esemplificativo: sviluppatori software e gestori di siti web, spedizionieri).

Inoltre saranno trattati per finalità di analisi statistiche e di marketing diretto, in maniera anonima, come meglio specificato dalla cookie policy. Trattasi pertanto di trattamenti legittimi e necessari ad assicurarLe un servizio che risponda alle Sue aspettative. I dati saranno conservati per tutta la durata dei servizi da Lei richiesti o per periodi superiori solo se previsti dalle norme. In ogni momento Lei potrà esercitare i diritti previsti agli articoli 15-22 del GDPR contattando il titolare del presente sito.

Per modificare o cancellare i soli dati che sono archiviati sui sistemi che erogano il presente sito, invii un'email a segreteria@studioballon.it. Le informazioni da lei inserite nel form (compreso il suo indirizzo email) potranno essere comunicate a una società terza che agirà in qualità di Responsabile esterno e che supporta il Titolare nella gestione del sito e nell’evasione della sua richiesta.

Nel caso di richiesta di cancellazione dei suoi dati, La informiamo che potrebbe non essere più possibile continuare a fornirLe il servizio richiestoci.

La informiamo, infine, che potrà proporre reclamo all’Autorità Garante per la Protezione dei Dati, qualora ritenesse siano stati violati i suoi diritti.</p>
            <h1 className=' text-3xl my-5 font-semibold'>Informativa estesa sui Cookie</h1>
            <p>Questo sito utilizza cookie, anche di terze parti, per migliorarne l’esperienza di navigazione e consentire a chi naviga di usufruire dei nostri servizi online e di visualizzare pubblicità in linea con le proprie preferenze. I cookies utilizzati in questo sito rientrano nelle categorie descritte di seguito.</p>
            <h2 className=' text-2xl my-5 font-semibold'>Cosa sono i cookies</h2>
            <p>I cookies sono piccoli file di testo che vengono automaticamente posizionati sul PC del navigatore all'interno del browser. Essi contengono informazioni di base sulla navigazione in Internet e grazie al browser vengono riconosciuti ogni volta che l'utente visita il sito.
Di seguito vengono forniti dettagli sui cookie installati da questo sito e indicazioni su come gestire le preferenze in merito ad essi.</p>
            <h2 className=' text-2xl my-5 font-semibold'>Gestione dei cookie</h2>
            <h3 className=' text-xl my-5 font-semibold'>1 - Cookie tecnici</h3>
            <p>I cookie tecnici descritti qui di seguito non richiedono consenso perciò vengono installati automaticamente a seguito dell’accesso al sito.</p>
            <p className='my-3'><span className='font-semibold'>- Cookie necessari al funzionamento:</span> cookies che permettono al sito di funzionare correttamente anche consentendo all’utente di avere un’esperienza di navigazione funzionale. Ad esempio, mantengono l'utente collegato durante la navigazione evitando che il sito richieda di collegarsi più volte per accedere alle pagine successive.</p>
            <p className='my-3'><span className='font-semibold'>- Cookie per il salvataggio delle preferenze:</span> cookie che permettono di ricordare le preferenze selezionate dall’utente durante la navigazione, ad esempio, consentono di impostare la lingua.</p>
            <p className='my-3'><span className='font-semibold'>- Cookie statistici e di misurazione dell'audience:</span> cookie che aiutano a capire, attraverso dati raccolti in forma anonima e aggregata, come gli utenti interagiscono con il sito internet fornendo informazioni relative alle sezioni visitate, il tempo trascorso sul sito, eventuali malfunzionamenti.</p>
            <h3 className=' text-xl my-5 font-semibold'>2 - Cookie di terze parti</h3>
            <p>Attraverso questo sito vengono installati anche cookie gestiti da terze parti.
Per informazioni relative a questi cookie di terze parti e la gestione del consenso sono disponibili gli appositi link delle tabelle riportate più sotto. Inoltre accedendo alla pagina http://www.youronlinechoices.com/it/le-tue-scelte è possibile informarsi sulla pubblicità comportamentale oltre che disattivare o attivare i cookie delle società elencate che lavorano con i gestori dei siti web per raccogliere e utilizzare informazioni utili alla fruizione della pubblicità.</p>
            <h3 className=' text-xl my-5 font-semibold'>Cookie Statistici e di Misurazione dell'audience di terze parti</h3>
            <p>Questi cookie forniscono informazioni anonime/aggregate sul modo in cui i visitatori navigano sul sito. Di seguito i link alle rispettive pagine di cookies policy per gestire il consenso.</p>
            <p className='font-semibold mt-3'>Google</p>
            <p>Google Analytics: sistema di statistiche</p>
            <p>Cookies analitici</p>
            <a className='underline' href='https://policies.google.com/technologies/partner-sites'>privacy policy</a>
            <h3 className=' text-xl my-5 font-semibold'>Cookie di remarketing</h3>
            <p>Questi cookie di terza parte, quando presenti, consentono di mostrare annunci pubblicitari basati sugli interessi manifestati dagli utenti durante la navigazione online sui siti e le APP appartenenti al circuito della terza parte che ha rilasciato questi cookie. Per esempio, i cookie di remarketing utilizzati da questo sito possono essere quelli della Rete Display di Google e della piattaforma Audience Network di Facebook. Qui sotto vengono forniti i link per disattivare l'utilizzo dei cookie da parte di Google e di Facebook.</p>
            <p className='font-semibold mt-3'>Google</p>
            <p>Remarketing</p>
            <a className='underline' href='https://adssettings.google.com/authenticated'>privacy policy</a>
            <p className='font-semibold mt-3'>Facebook</p>
            <p>Remarketing</p>
            <a className='underline' href='https://www.facebook.com/ads/settings'>privacy policy</a>
            <h3 className=' text-xl my-5 font-semibold'>Cookie analitici di terze parti per il servizio “Business Contact”</h3>
            <p>Questi cookie di terze parti, quando presenti, consentono di tracciare le visite sul presente sito effettuate da aziende che abbiano registrato un IP fisso. Le informazioni pubbliche relative all’IP fisso posso essere utilizzate, insieme ad altri dati pubblici, per contattare le aziende che hanno dimostrato interesse a prodotti e servizi offerti dal presente sito.

Il servizio è identificato con il nome “Business Contact” e prevede l’installazione dei seguenti cookie:</p>
            <p>__bctc<br/>__bcvc<br/>__bcsc<br/>__ddtest</p>
            <h3 className=' text-xl my-5 font-semibold'>3 - Ricordati che puoi gestire le tue preferenze sui cookie anche attraverso il browser</h3>
            <p>Se si utilizza Internet Explorer<br/>
In Internet Explorer, fare clic su "Strumenti" e selazionare "Opzioni Internet". Nella scheda Privacy, spostare il cursore verso l'alto per bloccare tutti i cookie o verso il basso per consentire tutti i cookie, e quindi fare clic su OK.</p>
            <br/>
            <p>Se si utilizza il browser Firefox<br/>
Scegliere il menù "Strumenti" del browser e selezionare il menu "Opzioni". Fare clic sulla scheda "Privacy". Nell’elenco a discesa “Regole di conservazione” selezionare il livello desiderato. Spuntare la casella “Accettare i cookie” per attivare i cookie, o togliere la spunta per disattivarli. Scegliere per quanto tempo i cookie possono essere conservati.</p>
            <br/>
            <p>Se si utilizza il browser Safari<br/>
Cliccare nel Menù Safari, selezionare il menu "Modifica" e selezionare "Preferences". Cliccare su "Privacy". Posizionare l'impostazione "cookies Block" e fare clic su OK.</p>
            <br/>
            <p>Se si utilizza il browser Google Chrome<br/>
Cliccare sul menu Chrome nella barra degli strumenti del browser. Selezionare "Impostazioni". Cliccare su "Mostra impostazioni avanzate". Nella sezione "Privacy", cliccare su "Impostazioni contenuti". Nella sezione "Cookies", selezionare "Impedisci a tutti i siti di salvare i dati" e quindi fare clic su OK.</p>
            <br/>
            <p>Se usi un qualsiasi altro browser o non conosci il tipo e versione di browser che stai utilizzando, clicca “Aiuto” nella finestra del browser in alto, da cui puoi accedere a tutte le informazioni necessarie.</p>
            <br/>
            <p>Ai sensi degli artt. 15-22 del GDPR, l’utente ha il diritto di richiedere, scrivendo all’indirizzo del titolare del presente sito, l'accesso ai suoi dati personali, la rettifica o la cancellazione degli stessi o anche semplicemente la limitazione del loro trattamento (anonimizzazione) o proporre reclamo all’Autorità Garante per la Protezione dei Dati, qualora ritenesse siano stati violati i suoi diritti. Per modificare o cancellare i soli dati che sono archiviati sui sistemi che erogano il presente sito, invii un'email a segreteria@studioballon.it.</p>


            
            
            <div className='mt-20 font-semibold'>Studio Ballon</div>
            <div className='font-semibold'>P.I. 01152820260</div>
        </div>
        
        
        <Footer />
        
    </div>
  );
};

export default InformazioniLegali;