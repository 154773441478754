import React from "react";
import Header from './Header'
import logo from './logo_piccolo.png'
import { Link } from "react-router-dom";


function NotFound() {
  //const firebase = getFirebase();
  //const history = useHistory();

  const ombra = {
    boxShadow: '0px -4px 12px 5px rgba(127,127,127,0.3)',
  }

  return (
    <div>
      <Header />
      <div className="max-w-xl p-5 mx-auto mt-10 shadow-2xl bg-white rounded-xl">
        <div>
          <div className='text-indigo-700 font-extrabold text-9xl text-center font-mono'>404</div>
          <div className='text-indigo-900 font-extrabold text-5xl text-center font-mono'>NotFound</div>
          <div className='grid place-items-center mt-10'>
            <Link to='/' className='text-gray-800 font-bold text-xl underline'>Torna alla Home Page</Link>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
      <div id='footer' className='w-full absolute bottom-0'>
        <footer style={ombra} className='mt-20 grid justify-items-center py-4 bg-white'>
            <div>
                <div className='inline-block align-middle'>
                    <img className='w-10 h-10' src={logo} alt='logo' />
                </div>
                <div className='inline-block ml-2 font-semibold font-mono text-xs align-middle'>
                    <div>make by no-dev(eloper)</div>
                    <div className='text-center underline'><a href='https://no-dev.it'>no-dev.it</a></div>
                </div>
            </div>
        </footer>
      </div>
    </div>
  );
}

export default NotFound;






