import React from "react";
import { Helmet } from 'react-helmet-async';
import useInput from "./useInput";
import Header from './Header'
import Footer from './Footer'
import { useHistory } from "react-router-dom";
//import getFirebase from "./firebase";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
//import { getDocs, query, collection, where, limit } from "firebase/firestore";
//import { getFunctions, httpsCallable } from "firebase/functions";


function Reset() {
  const email = useInput("");
  //const firebase = getFirebase();
  const history = useHistory();
  //const firebase = getFirebase();
  //const functions = getFunctions();

  const ombra = {
    boxShadow: '0px -4px 12px 5px rgba(127,127,127,0.3)',
  }

  const sendEmail = async (event) => {
    event.preventDefault();


    /* const getClienti = collection(firebase.db, "clienti");
    const q = query(getClienti, where('email', '==', email.value), limit(1));
    let querySnapshot = await getDocs(q);
    if(!querySnapshot.empty) { */
      const actionCodeSettings = {
        // After password reset, the user will be give the ability to go back
        // to this page.
        url: 'https://studioballon.it/'
      };
      const auth = getAuth();
      sendPasswordResetEmail(auth, email.value, actionCodeSettings)
      .then((userCredential) => {
          alert("L'email per il cambio password è stata inviata \n Controlla la tua casella di posta \n Nel caso non la trovassi, controlla anche la casella spam");
          history.replace("/");
      })
      .catch((error) => {
        switch (error.code) {
          case 'auth/user-not-found':
            /* querySnapshot.forEach((doc) => {
              history.replace('/clienti/register?email='+email.value+'&id='+doc.id)
            }) */
            alert("Attenzione! L'account non esiste")
            history.replace('')
            break;
          default:
            alert("Attenzione! L'autenticazione non è riuscita")
        }
      });
    /* } else {
      alert("Attenzione! L'email inserita non è valida");
    } */

    /* const auth = getAuth();
    sendPasswordResetEmail(auth, email.value)
    .then((userCredential) => {
        alert("L'email per il cambio password è stata inviata \n Controlla la tua casella di posta \n Nel caso non la trovassi, controlla anche la casella spam");
        history.replace("/");
    })
    .catch((error) => {
      switch (error.code) {
        case 'auth/user-not-found':
          alert("Attenzione! L'email inserita non è valida");
          break;
        default:
          alert("Attenzione! L'autenticazione non è riuscita")
      }
    }); */
  }


  

  return (
    <div>
      <Helmet>
        <html lang="it" />
        <title>Studio Ballon - Reset Password</title>
        <meta name="description" content="Studio Ballon - Reset Password per l'Area Riservata" />
        <meta name="theme-color" content="#E6E6FA" />
      </Helmet>
      <Header />
      <div className="max-w-xl p-5 mx-auto mt-20 md:mb-44 mb-20 shadow-2xl bg-white rounded-xl">
        <h3 className='font-semibold mb-5 text-xl'>
          Cambia la tua Password
        </h3>
        <form  className='p-2' id='sendEmail'  onSubmit={sendEmail}>
          <div className="">
            
            <div className="flex flex-wrap ">
              <div className="w-full px-2">
                <label className="block mb-1">Email</label>
                <input className="w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline" type="email" {...email}/>
              </div>
            </div>
            <button type="submit" className='h-10 px-5 mt-4 ml-2 text-indigo-100 transition-colors duration-150 bg-indigo-700 rounded-lg focus:shadow-outline hover:bg-indigo-800'>Cambio password</button>
          </div>
        </form>
      </div>
      {/* <Footer /> */}
      <Footer/>
    </div>
  );
}

export default Reset;






