import React, {useState} from "react";
import { Transition } from "@headlessui/react";
import { Link } from "react-router-dom";
//import logo from './logo_piccolo.png'
//import { useHistory } from "react-router-dom";

const Header = () => {

  /* const ombra = {
    boxShadow: '0px 4px 12px 5px rgba(127,127,127,0.3)',
  } */

  const [isOpen, setIsOpen] = useState(false);
  

  return (
          <div>
            <nav className=" bg-blueGray-800">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex items-center justify-between h-20">
                        <div className="flex items-center">
                            <Link to='/' className="flex-shrink-0">
                                <img
                                className="h-8 w-8"
                                src="/logo_ballon42.png"
                                alt="logo"
                                />
                            </Link>
                            <Link to='/' className='text-white ml-5 ObjectiveMedium text-xl md:hidden'>Studio Ballon</Link>
                            <div className="hidden md:block">
                                <div className="ml-10 flex items-baseline space-x-4">
                                    <a
                                        href="/"
                                        className=" hover:bg-gray-700 text-white px-3 py-2 rounded-md text-sm font-medium"
                                    >
                                        Home
                                    </a>

                                    <a
                                        href="/chisiamo"
                                        className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                                    >
                                        Chi siamo
                                    </a>

                                    <a
                                        href="/servizi"
                                        className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                                    >
                                        Servizi
                                    </a>

                                    <a
                                        href="/preventivi"
                                        className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                                    >
                                        Preventivi
                                    </a>
                                    <a
                                        href="/contatti"
                                        className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                                    >
                                        Contatti
                                    </a>
                                </div>
                            </div>
                        </div>
                        <a href='/login'  className="text-white cursor-pointer bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-5 mb-5 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900 my-auto hidden md:block">Area Riservata</a>
                        <div className="-mr-2 flex md:hidden">
                            <button
                                onClick={() => setIsOpen(!isOpen)}
                                type="button"
                                className="bg-gray-900 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                                aria-controls="mobile-menu"
                                aria-expanded="false"
                            >
                                <span className="sr-only">Open main menu</span>
                                {!isOpen ? (
                                <svg
                                    className="block h-6 w-6"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M4 6h16M4 12h16M4 18h16"
                                    />
                                </svg>
                                ) : (
                                <svg
                                    className="block h-6 w-6"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                                )}
                            </button>
                        </div>
                    </div>
                </div>

                <Transition
                show={isOpen}
                enter="transition ease-out duration-100 transform"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="transition ease-in duration-75 transform"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
                >
                {(ref) => (
                    <div className="md:hidden" id="mobile-menu">
                        <div ref={ref} className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                            <a
                            href="/"
                            className="hover:bg-gray-700 text-white block px-3 py-2 rounded-md text-base font-medium"
                            >
                            Home
                            </a>

                            <a
                            href="/chisiamo"
                            className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                            >
                            Chi siamo
                            </a>

                            <a
                            href="/servizi"
                            className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                            >
                            Servizi
                            </a>

                            <a
                            href="/preventivi"
                            className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                            >
                            Preventivi
                            </a>

                            <a
                            href="/contatti"
                            className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                            >
                            Contatti
                            </a>

                            <a
                            href="/login"
                            className="text-red-500 hover:bg-gray-700 hover:text-red-300 block px-3 py-2 rounded-md text-base font-medium"
                            >
                            Area Riservata
                            </a>
                            
                        </div>
                    </div>
                )}
                </Transition>
            </nav>
          </div>
  );
};

export default Header;