import React, {useState} from "react";
//import getFirebase from "../firebase";
//import { getAuth,  } from "firebase/auth";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { useHistory } from "react-router-dom";
import Geocode from "react-geocode";




const AddCondominioForm = (props) => {
  //const firebase = getFirebase();
  let history = useHistory();
  const [redNome, setRedNome] = useState('')
  const [redVia, setRedVia] = useState('')
  const [redIndirizzo, setRedIndirizzo] = useState('')
  const [redCivico, setRedCivico] = useState('')
  const [redCap, setRedCap] = useState('')
  const [redCitta, setRedCitta] = useState('')

  const [redLabelNome, setRedLabelNome] = useState('')
  const [redLabelVia, setRedLabelVia] = useState('')
  const [redLabelIndirizzo, setRedLabelIndirizzo] = useState('')
  const [redLabelCivico, setRedLabelCivico] = useState('')
  const [redLabelCap, setRedLabelCap] = useState('')
  const [redLabelCitta, setRedLabelCitta] = useState('')


  /* const [lat, setLat] = useState(null)
  const [lng, setLng] = useState(null) */

  Geocode.setApiKey("AIzaSyBjTG9p_f1DZ2lKsPFxo_iZ8FMztXgxuGw");
  Geocode.setLanguage("it");
  Geocode.setRegion("it");
  Geocode.setLocationType("ROOFTOP");
  //Geocode.enableDebug();

  const inserimentoCondominio = async (nome, via, indirizzo, civico, cap, citta, lat, lng) => {
    const docRef =  await addDoc(collection(props.firebase.db, "condomini"), {
      nome: nome,
      via: via,
      indirizzo: indirizzo,
      civico: civico,
      cap: cap,
      citta: citta,
      lat: lat,
      lng: lng,
      timestamp: serverTimestamp()
    });
    document.getElementById("formNuovo_Condominio").reset();
    history.push({
      pathname: '/amministratori/condominio',
      search: '?id='+docRef.id+'&anno='+new Date().getFullYear()
    });
  }



  const addCondominio = async (event) => {
    event.preventDefault();
    setRedCitta('')
    setRedCivico('')
    setRedIndirizzo('')
    setRedNome('')
    setRedVia('')
    setRedCap('')

    setRedLabelCitta('')
    setRedLabelCivico('')
    setRedLabelIndirizzo('')
    setRedLabelNome('')
    setRedLabelVia('')
    setRedLabelCap('')
    //const auth = getAuth();

    const Capitalize = (prop) => {
      let str = prop.toLowerCase()
      let arr = str.split(" ");
      for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }
      return arr.join(" ");
    }

    const nome = Capitalize(event.target[0].value);
    const via = Capitalize(event.target[1].value);
    const indirizzo = Capitalize(event.target[2].value);
    const civico = Capitalize(event.target[3].value);
    const cap = Capitalize(event.target[4].value);
    const citta = Capitalize(event.target[5].value);
    /* const [lat, setLat] = useState(null)
    const [lng, setLng] = useState(null) */
    Geocode.fromAddress(via+' '+indirizzo+' '+civico+' '+cap+' '+citta).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
      

      if(nome && via && indirizzo && civico && cap && citta){
        inserimentoCondominio(nome, via, indirizzo, civico, cap, citta, lat, lng)
      } else {
        if(!nome) {setRedNome('bg-red-50 border border-red-500')}
        if(!via) {setRedVia('bg-red-50 border border-red-500')}
        if(!indirizzo) {setRedIndirizzo('bg-red-50 border border-red-500')}
        if(!civico) {setRedCivico('bg-red-50 border border-red-500')}
        if(!cap) {setRedCap('bg-red-50 border border-red-500')}
        if(!citta) {setRedCitta('bg-red-50 border border-red-500')}

        if(!nome) {setRedLabelNome('text-red-500')}
        if(!via) {setRedLabelVia('text-red-500')}
        if(!indirizzo) {setRedLabelIndirizzo('text-red-500')}
        if(!civico) {setRedLabelCivico('text-red-500')}
        if(!cap) {setRedLabelCap('text-red-500')}
        if(!citta) {setRedLabelCitta('text-red-500')}
      }
    },
    (error) => {
      if(error.code === undefined && via && indirizzo && civico && cap && citta) {
        alert('Indirizzo inesistente!')
        setRedVia('bg-red-50 border border-red-500')
        setRedIndirizzo('bg-red-50 border border-red-500')
        setRedCivico('bg-red-50 border border-red-500')
        setRedCap('bg-red-50 border border-red-500')
        setRedCitta('bg-red-50 border border-red-500')

        setRedLabelVia('text-red-500')
        setRedLabelIndirizzo('text-red-500')
        setRedLabelCivico('text-red-500')
        setRedLabelCap('text-red-500')
        setRedLabelCitta('text-red-500')
      } else {
        if(!nome) {setRedNome('bg-red-50 border border-red-500')}
        if(!via) {setRedVia('bg-red-50 border border-red-500')}
        if(!indirizzo) {setRedIndirizzo('bg-red-50 border border-red-500')}
        if(!civico) {setRedCivico('bg-red-50 border border-red-500')}
        if(!cap) {setRedCap('bg-red-50 border border-red-500')}
        if(!citta) {setRedCitta('bg-red-50 border border-red-500')}

        if(!nome) {setRedLabelNome('text-red-500')}
        if(!via) {setRedLabelVia('text-red-500')}
        if(!indirizzo) {setRedLabelIndirizzo('text-red-500')}
        if(!civico) {setRedLabelCivico('text-red-500')}
        if(!cap) {setRedLabelCap('text-red-500')}
        if(!citta) {setRedLabelCitta('text-red-500')}
      }
    }
  );

    
    
    
 

  };

  return (
    <form className='p-10' id='formNuovo_Condominio' onSubmit={addCondominio}>
      <div className='text-sm text-red-500 mb-2'>* Tutti i campi sono obbligatori</div>
      <div className="flex flex-wrap">
        <div className="w-full">
          <label className={redLabelNome+" block mb-1"} htmlFor="formGridNome_Condominio">Nome Condominio</label>
          <input className={redNome+" w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline"} type="text" id="formGridNome_Condominio"/>
        </div>
      </div>
      <div className="flex flex-wrap -mx-2 my-4 space-y-4 md:space-y-0">
      <div className="w-full px-2 md:w-1/5">
          <label className={redLabelVia+" block mb-1"} htmlFor="formGridVia">Via/Piazza</label>
          <input className={redVia+" w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline"} type="text" id="formGridVia"/>
        </div>
        <div className="w-full px-2 md:w-3/5">
          <label className={redLabelIndirizzo+" block mb-1"} htmlFor="formGridIndirizzo">Indirizzo</label>
          <input className={redIndirizzo+" w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline"} type="text" id="formGridIndirizzo"/>
        </div>
        <div className="w-full px-2 md:w-1/5">
          <label className={redLabelCivico+" block mb-1"} htmlFor="formGridCivico">Civico </label>
          <input className={redCivico+" w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline"} type="number" id="formGridCivico"/>
        </div>
      </div>
      <div className="flex flex-wrap -mx-2 space-y-4 md:space-y-0">
        <div className="w-full px-2 md:w-1/4">
          <label className={redLabelCap+" block mb-1"} htmlFor="formGridCap">CAP</label>
          <input className={redCap+" w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline"} type="number" id="formGridCap"/>
        </div>
        <div className="w-full px-2 md:w-3/4">
          <label className={redLabelCitta+" block mb-1"} htmlFor="formGridCitta">Città</label>
          <input className={redCitta+" w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline"} type="text" id="formGridCitta"/>
        </div>
      </div>
      <button type="submit" className='h-10 px-5 mt-4 text-indigo-100 transition-colors duration-150 bg-indigo-700 rounded-lg focus:shadow-outline hover:bg-indigo-800'>Aggiungi</button>
    </form>
  );
};

export default AddCondominioForm;


