//import { useState } from "react";
import getFirebase from "../firebase";
import { doc, getDocs, query, collection, where, deleteDoc } from "firebase/firestore";
import { getStorage, ref, deleteObject  } from "firebase/storage";
import { getFunctions, httpsCallable } from "firebase/functions";

const EliminaCliente = async (email) => {
    const firebase = getFirebase();
    const storage = getStorage();
    const functions = getFunctions();
    //const [risultato, setRisultato] = useState(false)

    /* const tipoI = I.charAt(0).toUpperCase()+I.slice(1)
    const tipoP = P.charAt(0).toUpperCase()+P.slice(1) */
    

    const getCliente = async (email) => {
        try {
            const getCliente = collection(firebase.db, "clienti");
            const q = query(getCliente, where('email', '==', email));
            let querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                deleteCliente(doc.id)
            });
        } catch (e) {
            /* addDoc(collection(firebase.db, "errori"), {
                nome: 'Ricerca Cliente',
                timestamp: serverTimestamp(),
                dati: e
              }); */
        } 
    }

    const deleteCliente= async (id) => {
        try {
            await deleteDoc(doc(firebase.db, "clienti", id));
            //console.log('Cliente eliminato')
            return  true 
        } catch (e) {
            /* addDoc(collection(firebase.db, "errori"), {
                nome: 'Elimina Cliente',
                timestamp: serverTimestamp(),
                dati: e
              }); */
            return false
        } 
    }

    const getClienteParticella = async (email) => {
        try {
            const getCliente = collection(firebase.db, "clientiParticella");
            const q = query(getCliente, where('email', '==', email));
            let querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                deleteClienteParticella(doc.id)
            });
        } catch (e) {
            /* addDoc(collection(firebase.db, "errori"), {
                nome: 'Ricerca Cliente Particella',
                timestamp: serverTimestamp(),
                dati: e
              }); */
        } 
    }

    const deleteClienteParticella= async (id) => {
        try {
            await deleteDoc(doc(firebase.db, "clientiParticella", id));
            //console.log('Cliente eliminato')
            return  true 
        } catch (e) {
            /* addDoc(collection(firebase.db, "errori"), {
                nome: 'Elimina Cliente Particella',
                timestamp: serverTimestamp(),
                dati: e
              }); */
            return false
        } 
    }

    const deleteUser = (email) => {
        const deleteUser = httpsCallable(functions, 'deleteUser');
    
        const user = {
          email: email
        };
    
        deleteUser(user)
        .then((result) => {
          })
        .catch((e) => {
            /* addDoc(collection(firebase.db, "errori"), {
                nome: 'Elimina Utente',
                timestamp: serverTimestamp(),
                dati: email
              }); */
          });
    }

    const deleteFile = async (idCartella, id, nome) => {
        try {
            const deleteFile = ref(storage, 'clienti/'+idCartella+'/'+nome);
            await deleteDoc(doc(firebase.db, "filesClienti", id));
            deleteObject(deleteFile).then(() => {
              // File deleted successfully
            }).catch((e) => {
                /* addDoc(collection(firebase.db, "errori"), {
                    nome: 'Elimina File',
                    timestamp: serverTimestamp(),
                    dati: e
                  }); */
            });
        } catch (e) {
            /* addDoc(collection(firebase.db, "errori"), {
                nome: 'Elimina File',
                timestamp: serverTimestamp(),
                dati: e
              }); */
        } 
    }

    const getFiles = async (email) => {
        try {
            //let idCartella = encodeURIComponent(condominio+particella)
            const getFiles = collection(firebase.db, "filesClienti");
            const q = query(getFiles, where('email', '==', email));
            let querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                deleteFile(doc.data().idCartella, doc.id, doc.data().file)
            });
        } catch (e) {
            /* addDoc(collection(firebase.db, "errori"), {
                nome: 'Lista Files',
                timestamp: serverTimestamp(),
                dati: e
              }); */
        }
    }


    /* try {
        const getClienti = collection(firebase.db, "clienti");
        if(tipo==='Entrambi'){
            const qp = query(getClienti, where('condominio', '==', condominio), where('particella', '==', particella), where('email', '!=', email), where('tipo', 'in', ['Proprietario', 'Entrambi']));
            let querySnapshotP = await getDocs(qp); 
            if(querySnapshotP.empty) {
                getFiles(condominio, particella, 'proprietario')
            }
            const qi = query(getClienti, where('condominio', '==', condominio), where('particella', '==', particella), where('email', '!=', email), where('tipo', 'in', ['Inquilino', 'Entrambi']));
            let querySnapshotI = await getDocs(qi); 
            if(querySnapshotI.empty) {
                getFiles(condominio, particella, 'inquilino')
            }
        } else {
            const q = query(getClienti, where('condominio', '==', condominio), where('particella', '==', particella), where('email', '!=', email), where('tipo', 'in', ['Entrambi', tipo]));
            let querySnapshot = await getDocs(q); 
            if(querySnapshot.empty) {
                getFiles(condominio, particella, tipo)
            }
        }
    } catch (e) {
        console.log(e)
    } */

    getFiles(email)
    getCliente(email)
    getClienteParticella(email)
    deleteUser(email)

  return {
    
  };
};

export default EliminaCliente;
