import React, { useState, useEffect } from "react";
import { Helmet } from 'react-helmet-async';
import getFirebase from "../firebase";
import { getDocs, query, orderBy, collection, where, updateDoc, doc } from "firebase/firestore";
import { getStorage, ref, getDownloadURL  } from "firebase/storage";
import { useHistory } from "react-router-dom";
import SignOutButton from "../SignOutButton";
import Footer from '../Footer'
import Header from '../Header'
//import GetFilesCon from './GetFilesCon';
import CambiaEmail from '../CambiaEmail'
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

function Cliente() {
    let url = new URL(window.location.href);
    let urlId = url.searchParams.get("id");
    const [emailDisplay, setEmailDisplay] = useState(urlId)

    const history = useHistory();
    const firebase = getFirebase();
    const storage = getStorage();
    const firebaseInstance = getFirebase();
    const utente = firebase.auth.currentUser;
    //const [currentUser, setCurrentUser] = useState(null);
    const [preListaCon, setPreListaCon] = useState([])
    const [listaCon, setListaCon] = useState([]);
    //const [tipoFinale, setTipoFinale] = useState([])
    //const [listaPart, setListaPart] = useState([])
    const [filesCliente, setFilesCliente] = useState([]);
    const [preFilesCliente, setpreFilesCliente] = useState([])
    const [idCliente, setIdCliente] = useState('')
    const [nomeCliente, setNomeCliente] = useState('');
    const [cognomeCliente, setCognomeCliente] = useState('');
    const [pec, setPec] = useState('')
    const [tel, setTel] = useState('')
    //const [email, setEmail] = useState('')

    const [filesCon, setFilesCon] = useState([]);
    const [urlFileCon, setUrlFileCon] = useState('')

    const [urlFile, setUrlFile] = useState('')

    const [uno, setUno] = useState('')
    const [due, setDue] = useState('')
    const [tre, setTre] = useState('')
    const [quattro, setQuattro] = useState('')

    if(!utente) {
      //history.replace("/");
    }
    /* if (firebase.instance) {
      firebase.auth.onAuthStateChanged((authUser) => {
        if (authUser) {
          setCurrentUser(authUser.email);
          if(authUser.email !== urlId) {
            history.replace("/");
          }
        }
      });
    } */

    const getCliente = async () => {
      try {
        const getClienti = collection(firebase.db, "clienti");
        const q = query(getClienti, where('email', '==', urlId));
        let querySnapshot = await getDocs(q);
        //if(querySnapshot.empty) {history.replace("/");}
        querySnapshot.forEach((doc) => {
          setIdCliente(doc.id)
          setNomeCliente(doc.data().nome)
          setCognomeCliente(doc.data().cognome)
          setPec(doc.data().pec)
          setTel(doc.data().tel)
        })
        firebase.auth.onAuthStateChanged((authUser) => {
          if (authUser) {
            //setCurrentUser(authUser.email);
            if(authUser.email !== urlId) {
              history.replace("/");
            }
          }
        });
        
      } catch (e) {
        //alert("Errore del database ", e);
      }
      //listaFiles(urlAnno);
      listaFilesCon()
      window.scrollTo(0, 0)
  }
    

    const listaFilesCon = async () => {
      //setListaPart([]);
      setPreListaCon([]);
      //setFilesCliente([]);
      setpreFilesCliente([])
      
      const getCondomini = collection(firebase.db, "clientiParticella");
      const q = query(getCondomini, where('email', '==', urlId), orderBy('nomeCon'));
      let querySnapshot = await getDocs(q);
      let passaggio =[]
      let passaggioCon =[]
      let passaggioConFinale = []
      querySnapshot.forEach((doc) => {
        let nomeCon = doc.data().nomeCon;
        let idCon = doc.data().condominio;
        let nomePart = doc.data().nomePart;
        let idPart = doc.data().particella;
        let tipo = doc.data().tipo;
        /* setNomeCliente(doc.data().nome);
        setCognomeCliente(doc.data().cognome) */
        //let files = getFilesCon(idCon);
        
        setPreListaCon(oldArray => [...oldArray, {nomeCon: nomeCon, idCon: idCon, tipo: tipo}])
        //setPreListaCon(oldArray => [...oldArray, {nomeCon: nomeCon, idCon: idCon}])
        //setListaCon(oldArray => [...oldArray, {nomeCon: nomeCon, idCon: idCon}])
        passaggio.push({nomeCon: nomeCon, idCon: idCon, nomePart: nomePart, idPart: idPart, tipo: tipo})
        //setListaPart(oldArray => [...oldArray, {nomeCon: nomeCon, idCon: idCon, nomePart: nomePart, idPart: idPart, tipo: tipo}])
        listaFilesCliente(idCon, idPart, tipo, urlId);
        //console.log('1: '+listaCon)
        //console.log(getFilesCon(idCon))        
      })
      passaggio.forEach((item) => {
        if(!passaggioCon.includes(item.idCon)) {
          passaggioCon.push(item.idCon)
        }
      })
      passaggioCon.forEach((item) => {
        let tipoEntrambi = false
        let tipoProprietario = false
        let tipoInquilino = false
        passaggio.forEach((item2) => {
          if(item2.tipo === 'Entrambi' && item2.idCon === item) tipoEntrambi = true
          if(item2.tipo === 'Proprietario' && item2.idCon === item) tipoProprietario = true
          if(item2.tipo === 'Inquilino' && item2.idCon === item) tipoInquilino = true
          passaggioConFinale.push([item, [tipoEntrambi, tipoProprietario, tipoInquilino]])
        })
      })
      //setTipoFinale(passaggioConFinale)
      displayFiles(passaggioConFinale)
      //console.log(passaggioConFinale)

      listaFilesRiservati(urlId)
      //console.log(listaCon)
      //listaFilesCliente();
    }

    const listaFilesRiservati = async (email) => {
      try {
        const getFiles = collection(firebase.db, 'filesClienti');
        let q = query(getFiles,  where('email', '==', email), orderBy("data", "desc"));
        let querySnapshot =  getDocs(q);
        (await querySnapshot).forEach((doc) => {
          let dat = new Date(doc.data().data);
          const options = { year: 'numeric', month: 'long', day: 'numeric' };
    
          getDownloadURL(ref(storage, 'clienti/'+doc.data().idCartella+'/'+doc.data().file))
              .then((url) => {
                setUrlFile(url)
                setUrlFile((state) => {
                  //console.log(state)
                  setpreFilesCliente(oldArray => [...oldArray, {url: state, idCartella: doc.data().idCartella, id: doc.id, nome: doc.data().file, id: doc.id, tipo: doc.data().tipo, data: dat.toLocaleDateString('it-IT', options)}])
                  //return state;
                });
              })
    
            //setFilesCliente(oldArray => [...oldArray, {idCartella: doc.data().idCartella, id: doc.id, nome: doc.data().file, data: dat.toLocaleDateString('it-IT', options)}])
        })
        /* let passaggio =[]
        filesCliente.forEach((item) => {
          passaggio.push(item)
        }) */
        //console.log(passaggio)
        //filesCliente.sort((a,b) => parseInt(b.data) - parseInt(a.data))
        //console.log(filesCliente);
      } catch(e) {
        //console.log(e)
      }
    }

    const listaFilesCliente = async (idCon, idPart, tipo, email) => {
      //setFilesCliente([]);
      //listaPart.forEach((item) => {
        try {
          const getFiles = collection(firebase.db, 'filesClienti');
          let q = null
          switch (tipo) {
            case 'Entrambi':
              q = query(getFiles, where('idCondominio', '==', idCon), where('idParticella', '==', idPart), where('tipo', 'in', ['inquilino', 'proprietario', 'entrambi']), orderBy("data", "desc"));
              break;
            case 'Inquilino':
              q = query(getFiles, where('idCondominio', '==', idCon), where('idParticella', '==', idPart), where('tipo', 'in', ['inquilino', 'entrambi']), orderBy("data", "desc"));
              break;
            case 'Proprietario':
              q = query(getFiles, where('idCondominio', '==', idCon), where('idParticella', '==', idPart), where('tipo', 'in', ['proprietario', 'entrambi']), orderBy("data", "desc"));
              break;
            /* default:
              q = query(getFiles,  where('email', '==', email), orderBy("data", "desc")); */
          }
          //const q = query(getFiles, where('idCondominio', '==', idCon), where('idParticella', '==', idPart), where('tipo', 'in', [tipo, 'entrambi']), orderBy("data", "desc"));
          let querySnapshot =  getDocs(q);
          (await querySnapshot).forEach((doc) => {
            let dat = new Date(doc.data().data);
            const options = { year: 'numeric', month: 'long', day: 'numeric' };
    
            getDownloadURL(ref(storage, 'clienti/'+doc.data().idCartella+'/'+doc.data().file))
                .then((url) => {
                  setUrlFile(url)
                  setUrlFile((state) => {
                    //console.log(state)
                    setpreFilesCliente(oldArray => [...oldArray, {url: state, idCartella: doc.data().idCartella, id: doc.id, nome: doc.data().file, id: doc.id, tipo: doc.data().tipo, data: dat.toLocaleDateString('it-IT', options)}])
                    //return state;
                  });
                })
    
            //setFilesCliente(oldArray => [...oldArray, {idCartella: doc.data().idCartella, id: doc.id, nome: doc.data().file, data: dat.toLocaleDateString('it-IT', options)}])
          })
          //console.log(preFilesCliente)
        } catch (e) {
          //console.log(e)
        }
      //})
      //console.log(filesCliente)
      /* const getFiles = collection(firebase.db, 'filesClienti');
      const q = query(getFiles, where('email', '==', urlId), orderBy("data", "desc"));
      let querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        let dat = new Date(doc.data().data);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };

        getDownloadURL(ref(storage, 'clienti/'+doc.data().idCartella+'/'+doc.data().file))
            .then((url) => {
              setUrlFile(url)
              setUrlFile((state) => {
                //console.log(state)
                setFilesCliente(oldArray => [...oldArray, {url: state, idCartella: doc.data().idCartella, id: doc.id, nome: doc.data().file, data: dat.toLocaleDateString('it-IT', options)}])
                //return state;
              });
            })

        //setFilesCliente(oldArray => [...oldArray, {idCartella: doc.data().idCartella, id: doc.id, nome: doc.data().file, data: dat.toLocaleDateString('it-IT', options)}])
      }) */
    }

    const cambioSelect = (e) => {
      switch (e.target.value) {
        case '1':
          setUno('bg-indigo-500 text-white')
          setDue('bg-gray-50 text-black')
          setTre('bg-gray-50 text-black')
          setQuattro('bg-gray-50 text-black')
          document.getElementById('base').style.display='none'
          document.getElementById('cambiaEmail').style.display='flex'
          document.getElementById('cambiaPec').style.display='none'
          document.getElementById('cambiaTel').style.display='none'
          document.getElementById('cambiaPassword').style.display='none'
          break;
        case '2':
          setUno('bg-gray-50 text-black')
          setDue('bg-indigo-500 text-white')
          setTre('bg-gray-50 text-black')
          setQuattro('bg-gray-50 text-black')
          document.getElementById('base').style.display='none'
          document.getElementById('cambiaEmail').style.display='none'
          document.getElementById('cambiaPec').style.display='none'
          document.getElementById('cambiaTel').style.display='none'
          document.getElementById('cambiaPassword').style.display='flex'
          break;
        case '3':
          setDue('bg-gray-50 text-black')
          setUno('bg-gray-50 text-black')
          setTre('bg-indigo-500 text-white')
          setQuattro('bg-gray-50 text-black')
          document.getElementById('base').style.display='none'
          document.getElementById('cambiaEmail').style.display='none'
          document.getElementById('cambiaPec').style.display='flex'
          document.getElementById('cambiaTel').style.display='none'
          document.getElementById('cambiaPassword').style.display='none'
          break;
        case '4':
          setTre('bg-gray-50 text-black')
          setDue('bg-gray-50 text-black')
          setUno('bg-gray-50 text-black')
          setQuattro('bg-indigo-500 text-white')
          document.getElementById('base').style.display='none'
          document.getElementById('cambiaEmail').style.display='none'
          document.getElementById('cambiaPec').style.display='none'
          document.getElementById('cambiaTel').style.display='flex'
          document.getElementById('cambiaPassword').style.display='none'
          break;
      }
    }

    const cambiaPec = async (e) => {
      e.preventDefault()
      const docRef = doc(firebase.db, "clienti", idCliente);
      await updateDoc(docRef, {
        pec: e.target[0].value
      });
      setPec(e.target[0].value)
      document.getElementById("cambiaPec").reset();
    }

    const cambiaTel = async (e) => {
      e.preventDefault()
      const docRef = doc(firebase.db, "clienti", idCliente);
      await updateDoc(docRef, {
        tel: e.target[0].value
      });
      setTel(e.target[0].value)
      document.getElementById("cambiaTel").reset();
    }

    const cambiaEmail = (e) => {
      e.preventDefault()
      if(e.target[0].value && e.target[0].value !== '') {
        CambiaEmail(urlId, e.target[0].value, idCliente)
        setEmailDisplay(e.target[0].value)
        urlId = e.target[0].value
        /* document.getElementById("cambiaEmail").reset();
        firebaseInstance.auth.signOut(); */
      }
    }

    const cambiaPassword = (e) => {
      e.preventDefault()
      const actionCodeSettings = {
        // After password reset, the user will be give the ability to go back
        // to this page.
        url: 'https://studioballon.it/'
      };
      const auth = getAuth();
      sendPasswordResetEmail(auth, emailDisplay, actionCodeSettings)
      .then((userCredential) => {
          alert("L'email per il cambio password è stata inviata \n Controlla la tua casella di posta \n Nel caso non la trovassi, controlla anche la casella spam");
          firebaseInstance.auth.signOut();
          history.replace("/");
      })
      .catch((error) => {
        switch (error.code) {
          case 'auth/user-not-found':
            /* querySnapshot.forEach((doc) => {
              history.replace('/clienti/register?email='+email.value+'&id='+doc.id)
            }) */
            alert("Attenzione! L'account non esiste")
            history.replace('/')
            break;
          default:
            alert("Attenzione! L'autenticazione non è riuscita")
        }
      });
    }

    const GetFilesCon = async (q, item) => {
      //let listaFiles = []
      let querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          let dat = new Date(doc.data().data);
          const options = { year: 'numeric', month: 'long', day: 'numeric' };
  
          getDownloadURL(ref(storage, item[0]+'/'+doc.data().file))
            .then((url) => {
              //console.log('url: '+url)
              setUrlFileCon(url)
              setUrlFileCon((state) => {
                //console.log({url: state, idCartella: item[0], id: doc.id, nome: doc.data().file, data: dat.toLocaleDateString('it-IT', options)})
                setFilesCon(oldArray => [...oldArray, {url: state, idCartella: item[0], id: doc.id, nome: doc.data().file, data2: doc.data().data, data: dat.toLocaleDateString('it-IT', options)}])
                //listaFiles.push({url: state, idCartella: item[0], id: doc.id, nome: doc.data().file, data: dat.toLocaleDateString('it-IT', options)})
              });
            })
          
        })
    }

    const displayFiles = async (lista) => {
      setFilesCon([])
      let passaggio = []
      //let listaFiles = []
      lista.map((item, i) => {
        if(i === 0) {
          passaggio.push(item)
        }
        if(i > 0) {
          let ultimoEl = passaggio.slice(-1)
          if(ultimoEl[0][0] === item[0]) {
            passaggio.pop()
            passaggio.push(item)
          } else {
            passaggio.push(item)
          }
        }      
      })
      passaggio.forEach((item) => {
        let q = null
        const getFiles = collection(firebase.db, "files");
        if(item[1][0] === true || (item[1][1] === true && item[1][2] === true)) {
          q = query(getFiles, where('idCartella', '==', item[0]));
        }
        if(item[1][0] === false && item[1][1] === true && item[1][2] === false) {
          q = query(getFiles, where('idCartella', '==', item[0]), where('tipo', 'in', ['proprietario', 'entrambi']));
        }
        if(item[1][0] === false && item[1][1] === false && item[1][2] === true) {
          q = query(getFiles, where('idCartella', '==', item[0]), where('tipo', 'in', ['inquilino', 'entrambi']));
        }
        GetFilesCon(q, item)
        /* let querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          let dat = new Date(doc.data().data);
          const options = { year: 'numeric', month: 'long', day: 'numeric' };
  
          getDownloadURL(ref(storage, id+'/'+doc.data().file))
            .then((url) => {
              //console.log('url: '+url)
              setUrlFileCon(url)
              setUrlFileCon((state) => {
                console.log({url: state, idCartella: id, id: doc.id, nome: doc.data().file, data: dat.toLocaleDateString('it-IT', options)})
                setFilesCon(oldArray => [...oldArray, {url: state, idCartella: id, id: doc.id, nome: doc.data().file, data: dat.toLocaleDateString('it-IT', options)}])
                listaFiles.push({url: state, idCartella: id, id: doc.id, nome: doc.data().file, data: dat.toLocaleDateString('it-IT', options)})
              });
            })          
        }) */ 
      })
      let passaggioFiles = []
      filesCon.forEach((item) => {
        passaggioFiles.push(item)
      })
      passaggioFiles.sort((a, b) => (a.data2 < b.data2) ? 1 : -1)
      //passaggioFiles.sort((a,b) => (a.data > b.data) ? 1 : ((b.data > a.data) ? -1 : 0))
      passaggioFiles.forEach((item) => {
        setFilesCon(oldArray => [...oldArray, item])
      })
      //console.log(filesCon)
      
      /* setFilesCon([])
      const getFiles = collection(firebase.db, "files");
      let q = query(getFiles, where('idCartella', '==', id), orderBy("data", "desc"));
      
      let querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        let dat = new Date(doc.data().data);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };

        getDownloadURL(ref(storage, id+'/'+doc.data().file))
          .then((url) => {
            //console.log('url: '+url)
            setUrlFileCon(url)
            setUrlFileCon((state) => {
              console.log({url: state, idCartella: id, id: doc.id, nome: doc.data().file, data: dat.toLocaleDateString('it-IT', options)})
              setFilesCon(oldArray => [...oldArray, {url: state, idCartella: id, id: doc.id, nome: doc.data().file, data: dat.toLocaleDateString('it-IT', options)}])
              //return state;
            });
          })
        
      }) */
      return (
        <div>Ciao</div>
      )     
    }
    

    

    useEffect(() => {
      //listaFilesCon();
      getCliente()
      //listaFilesCliente();
    }, []) 
    useEffect(() => {
      let passaggio = []
      setListaCon([])
      preListaCon.forEach((item) => {
        if(!passaggio.includes(item.nomeCon)) {
          passaggio.push(item.nomeCon)
          setListaCon(oldArray => [...oldArray, {nomeCon: item.nomeCon, idCon: item.idCon}])
             
        }
      })
    }, [preListaCon]) 
    useEffect(() => {
      //console.log(preFilesCliente)
      let passaggio = []
      setFilesCliente([])
      preFilesCliente.forEach((item) => {
        passaggio.push(item)
        //console.log(passaggio)
      })
      passaggio.sort((a,b) => parseInt(b.data) - parseInt(a.data))
      passaggio.forEach((item) => {
        setFilesCliente(oldArray => [...oldArray, item])
      })
    }, [preFilesCliente]) 
    
    

    return (
      <div>
        <Helmet>
          <title>Studio Ballon - Area riservata di {nomeCliente} {cognomeCliente}</title>
        </Helmet>
        <Header />
        <div className=" p-5 w-full md:w-2/3 mx-auto mt-5 mb-14 shadow-2xl bg-white rounded-xl">
          <h2 className='font-bold mb-5 text-2xl'>Bentornato {nomeCliente} {cognomeCliente}</h2>


          <div className="tab w-full overflow-hidden mt-2">
            <input  className="absolute opacity-0" id="tab-single-one" type="checkbox" name="tabs2" />
            <label className="tabModifica block p-5 leading-normal cursor-pointer " htmlFor="tab-single-one">Modifica i tuoi dati</label>
            <div className="tab-content overflow-hidden border-l-2 bg-gray-100 border-indigo-500 leading-normal">
                  
                  
              <div className=' p-2 '>
                <div className='md:grid md:grid-cols-2  font-semibold ' onChange={(e) => {cambioSelect(e); }}>
                  <label className={uno+"  align-middle items-center block px-5 py-3 border border-indigo-500"}>
                    <input type="radio" className="form-radio" name="radio" value="1"  />
                    <span className="ml-2">Email</span>
                  </label>
                  <label className={due+" align-middle items-center block px-5 py-3 border-l border-r md:border-l-0 md:border-b-0 border-b border-t-0 md:border-t border-indigo-500"}>
                    <input type="radio" className="form-radio" name="radio" value="2"  />
                    <span className="ml-2">Password</span>
                  </label>
                  <label className={tre+"  align-middle items-center block px-5  py-3 border-l border-r md:border-r-0 md:border-b border-indigo-500"}>
                    <input type="radio" className="form-radio" name="radio" value="3"  />
                    <span className="ml-2">PEC</span>
                  </label>
                  <label className={quattro+"align-middle items-center block px-5 py-3 border border-indigo-500"}>
                    <input type="radio" className="form-radio" name="radio" value="4"  />
                    <span className="ml-2">Telefono</span>
                  </label>
                </div>
                <div className=' border-r border-l border-b border-indigo-500   '>
                  <div id='base' className='py-6  text-center h-full font-semibold text-xl'><span>Seleziona un'opzione</span></div>
                  <form id='cambiaEmail' className='flex hidden py-6 ' onSubmit={(e) => {cambiaEmail(e); }}>
                    <div className='mx-auto'>
                      <input className='mx-auto block md:inline-block h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline' type='email' defaultValue={emailDisplay} />
                      <button className='block md:inline-block h-10 px-5 mx-auto md:ml-5 mt-4 text-indigo-100 transition-colors duration-150 bg-indigo-700 rounded-lg focus:shadow-outline hover:bg-indigo-800'>Aggiorna Email</button>
                      {/* <div className='block mt-2  text-red-500'>Cambiando email dovrai effettuare il login con la nuova email</div> */}
                    </div>
                  </form>
                  <form id='cambiaPassword' className='flex hidden py-6 ' onSubmit={(e) => {cambiaPassword(e); }}>
                    <div className='mx-auto'>
                      <button className='h-10 px-5 mx-auto  text-indigo-100 transition-colors duration-150 bg-indigo-700 rounded-lg focus:shadow-outline hover:bg-indigo-800'>Inviami l'email per il cambio password</button>
                      <div className='block mt-2 text-red-500'>Cambiando password dovrai effettuare <br />il login con la nuova password</div>
                    </div>
                  </form>
                  <form id='cambiaPec' className='flex hidden py-6 ' onSubmit={(e) => {cambiaPec(e); }}>
                    <div className='mx-auto'>
                      <input className='block md:inline-block h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline' type='email' defaultValue={pec} />
                      <button className='block md:inline-block h-10 px-5 mx-auto md:ml-5 mt-4 text-indigo-100 transition-colors duration-150 bg-indigo-700 rounded-lg focus:shadow-outline hover:bg-indigo-800'>Aggiorna PEC</button>
                    </div>
                  </form>
                  <form id='cambiaTel' className='flex hidden py-6 ' onSubmit={(e) => {cambiaTel(e); }}>
                    <div className='mx-auto'>
                      <input className='block md:inline-block h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline' type='tel' defaultValue={tel} />
                      <button className='block md:inline-block h-10 px-5 mx-auto md:ml-5 mt-4 text-indigo-100 transition-colors duration-150 bg-indigo-700 rounded-lg focus:shadow-outline hover:bg-indigo-800'>Aggiorna Telefono</button>
                    </div>
                  </form>
                </div>
              </div>
                  
            </div>
          </div>


          <div className='p-5 border-indigo-500 border mt-5'>
            <h1 className='text-lg font-semibold '>Files condominiali</h1>
            {listaCon.map((item, i) => {
              return (
                <div className='border-l-2 border-indigo-500 mt-7' key={i}>
                  <h1 className='p-3 text-xl text-indigo-500 bg-gray-100'>{item.nomeCon} {item.idCon}</h1>
                  {/* <GetFilesCon  id={item.idCon} email={urlId}/> */}
                  {/* {displayFiles(item.idCon, tipoFinale)} */}
                  {filesCon.map((item2, i) => {
                    if(item.idCon === item2.idCartella) {
                      return (
                        <div className='md:flex md:justify-between border border-indigo-500 m-2 py-2 px-5 bg-gray-50 rounded-lg' key={i}>
                          <a className='cursor-pointer font-semibold mb-3 md:mb-0' href={item2.url}>{item2.nome.slice(13)}  <span className='text-sm block'>{item2.data}</span></a>
                        </div>
                      )
                    }
                  })}
                </div>
              )
            })}
          </div>
          <div className='p-5 border-indigo-500 border mt-5'>
            <h1 className='text-lg font-semibold '>Files personali</h1>
            <div className='grid  p-2'>
              {filesCliente.map((item, i) => {
                
                return(
                  <div className='md:flex md:justify-between border border-indigo-500 m-2 py-2 px-5 bg-gray-50 rounded-lg' key={i}>
                    <a className='cursor-pointer font-semibold mb-3 md:mb-0' href={item.url}>{item.nome.slice(13)}  <span className='text-sm block'>{item.data}</span></a>
                    {/* <div className='cursor-pointer font-semibold mb-3 md:mb-0' onClick={(e) => {openFile(item.nome)}}>{item.nome.slice(13)}    <span className='text-sm block'>{item.data}</span> <span className='text-sm block'>File riservato a: {item.tipo}</span> </div> */}
                    {/* <div className='cursor-pointer font-bold text-red-600' onClick={(e) => {eliminaFile(item.nome, item.id)}}>Elimina</div> */}
                  </div>
                )
              })}
            </div>
          </div>
          <div>
            <SignOutButton />    
          </div>
        </div>
        <Footer />
      </div>
    );
}

export default Cliente;
