//import { useState } from "react";
import getFirebase from "../firebase";
import { doc, getDocs, query, collection, where, deleteDoc } from "firebase/firestore";
import { getStorage, ref, deleteObject  } from "firebase/storage";
//import { getFunctions } from "firebase/functions";
import EliminaCliente from './EliminaCliente'

const EliminaParticella = async (urlIdCondominio,  urlIdParticella, nomeParticella) => {
    const firebase = getFirebase();
    const storage = getStorage();
    //const functions = getFunctions();
    //const [risultato, setRisultato] = useState(false)

    /* const tipoI = I.charAt(0).toUpperCase()+I.slice(1)
    const tipoP = P.charAt(0).toUpperCase()+P.slice(1) */
    

    const deleteParticella = async (id) => {
        try {
            await deleteDoc(doc(firebase.db, "particelle", id));
            //alert('Cliente eliminato')
            return  true 
        } catch (e) {
            /* addDoc(collection(firebase.db, "errori"), {
                nome: 'Elimina  Particella',
                timestamp: serverTimestamp(),
                dati: e
              }); */
            //alert("Errore del database 2: "+e);
            return false
        } 
    }

    const getCliente = async (email) => {
        try {
            const getCliente = collection(firebase.db, "clientiParticella");
            const q = query(getCliente, where('email', '==', email));
            let querySnapshot = await getDocs(q);
            if(querySnapshot.empty) {
                EliminaCliente(email)
            }
        } catch (e) {
            /* addDoc(collection(firebase.db, "errori"), {
                nome: 'Ricerca Cliente  Particella',
                timestamp: serverTimestamp(),
                dati: e
              }); */
        }
    }

    const deleteClientiParticella = async (id, email) => {
        try {
            await deleteDoc(doc(firebase.db, "clientiParticella", id));
        } catch (e) {
            /* addDoc(collection(firebase.db, "errori"), {
                nome: 'Elimina Cliente  Particella',
                timestamp: serverTimestamp(),
                dati: e
              }); */
        } 
        getCliente(email)
    }

    const getClientiParticella = async (urlIdCondominio, urlIdParticella) => {
        try {
            //let idCartella = encodeURIComponent(condominio+particella)
            const getRef = collection(firebase.db, "clientiParticella");
            const q = query(getRef, where('condominio', '==', urlIdCondominio), where('particella', '==', urlIdParticella));
            let querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                deleteClientiParticella(doc.id, doc.data().email)
            });
        } catch (e) {
            /* addDoc(collection(firebase.db, "errori"), {
                nome: 'Ricerca Cliente  Particella',
                timestamp: serverTimestamp(),
                dati: e
              }); */
        }
    }

    const deleteFile = async (idCartella, id, nome) => {
        try {
            const deleteFile = ref(storage, 'clienti/'+idCartella+'/'+nome);
            await deleteDoc(doc(firebase.db, "filesClienti", id));
            deleteObject(deleteFile).then(() => {
              // File deleted successfully
            }).catch((e) => {
              //alert("Errore del database: "+e);
            });
        } catch (e) {
            /* addDoc(collection(firebase.db, "errori"), {
                nome: 'Elimina  File',
                timestamp: serverTimestamp(),
                dati: e
              }); */
        } 
    }

    const getFiles = async (condominio, particella) => {
        try {
            //let idCartella = encodeURIComponent(condominio+particella)
            const getFiles = collection(firebase.db, "filesClienti");
            const q = query(getFiles, where('idCondominio', '==', condominio), where('idParticella', '==', particella));
            let querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                deleteFile(doc.data().idCartella, doc.id, doc.data().file)
            });
        } catch (e) {
            /* addDoc(collection(firebase.db, "errori"), {
                nome: 'Ricerca  Files',
                timestamp: serverTimestamp(),
                dati: e
              }); */
        }
    }


    /* try {
        const getClienti = collection(firebase.db, "clienti");
        if(tipo==='Entrambi'){
            const qp = query(getClienti, where('condominio', '==', condominio), where('particella', '==', particella), where('email', '!=', email), where('tipo', 'in', ['Proprietario', 'Entrambi']));
            let querySnapshotP = await getDocs(qp); 
            if(querySnapshotP.empty) {
                getFiles(condominio, particella, 'proprietario')
            }
            const qi = query(getClienti, where('condominio', '==', condominio), where('particella', '==', particella), where('email', '!=', email), where('tipo', 'in', ['Inquilino', 'Entrambi']));
            let querySnapshotI = await getDocs(qi); 
            if(querySnapshotI.empty) {
                getFiles(condominio, particella, 'inquilino')
            }
        } else {
            const q = query(getClienti, where('condominio', '==', condominio), where('particella', '==', particella), where('email', '!=', email), where('tipo', 'in', ['Entrambi', tipo]));
            let querySnapshot = await getDocs(q); 
            if(querySnapshot.empty) {
                getFiles(condominio, particella, tipo)
            }
        }
    } catch (e) {
        console.log(e)
    } */

    getFiles(urlIdCondominio, urlIdParticella)
    deleteParticella(urlIdParticella)
    getClientiParticella(urlIdCondominio, urlIdParticella)

  return {
    
  };
};

export default EliminaParticella;
